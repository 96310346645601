import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { motion } from "framer-motion";
import "./BetaTestimonialSliderItem.scss";

const BetaTestimonialSliderItem = ({ review }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(review.stars);
    const hasHalfStar = review.stars % 1 !== 0;

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FaStar key={i} className="BetaTestimonialSliderItem__star BetaTestimonialSliderItem__star--filled" />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<FaStarHalfAlt key={i} className="BetaTestimonialSliderItem__star BetaTestimonialSliderItem__star--half" />);
      } else {
        stars.push(<FaRegStar key={i} className="BetaTestimonialSliderItem__star" />);
      }
    }
    return stars;
  };

  return (
    <motion.div 
      className="BetaTestimonialSliderItem"
      initial={isMobile ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
      whileInView={isMobile ? { opacity: 1, scale: 1 } : { opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: isMobile ? 0 : 0.4 }}
      whileHover={isMobile ? {} : { 
        scale: 1.02,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        transition: { duration: 0.2 }
      }}
    >
      <div className="BetaTestimonialSliderItem__stars">
        {renderStars()}
      </div>
      <p className="BetaTestimonialSliderItem__text">{review.text}</p>
      <p className="BetaTestimonialSliderItem__name">- {review.name}</p>
    </motion.div>
  );
};

BetaTestimonialSliderItem.propTypes = {
  review: PropTypes.shape({
    stars: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default BetaTestimonialSliderItem;