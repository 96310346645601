import { useState, useCallback } from 'react'

const useLazyLoadingImage = () => {
  const [loaded, setLoaded] = useState(false)

  // check image is loaded
  const onLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  return { loaded, onLoad }
}

export default useLazyLoadingImage
