import React from "react";
import AccordionItem from "../../../landing/faq/accordion/accordionItem/accordionItem";
import "./pricingAccordion.scss";
import { motion } from "framer-motion";
import { 
  FiGift, 
  FiCreditCard, 
  FiPower,
  FiLayers,
  FiUsers,
  FiPercent,
  FiSun,
  FiRefreshCw,
  FiDollarSign,
  FiDatabase
} from "react-icons/fi";

const PricingAccordion = () => {
  const faqItems = [
    {
      question: <span className="question-title"><FiGift className="question-icon" /> Prova gratuita senza impegno</span>,
      answer: "La prova gratuita ti permette di testare StudierAI senza alcun costo. Durante questo periodo potrai caricare e analizzare fino a 2 documenti e creare riassunti, domande di ripetizioni, flashcards e quiz a risposta multipla e simulare fino a 2 interrogazioni o ripetizioni con l'AI. Non è richiesta alcuna carta di credito per iniziare la prova gratuita."
    },
    {
      question: <span className="question-title"><FiCreditCard className="question-icon" /> Metodi di pagamento disponibili</span>,
      answer: "Accettiamo pagamenti tramite le principali carte di credito e debito (incluse Visa, Mastercard, American Express). I pagamenti vengono processati in modo sicuro attraverso Stripe, uno dei leader mondiali nei sistemi di pagamento. Supportiamo anche l'uso di codici promozionali per sconti speciali. La fatturazione è automatica e riceverai sempre una notifica prima di ogni rinnovo."
    },
    {
      question: <span className="question-title"><FiPower className="question-icon" /> Disdetta semplice e flessibile</span>,
      answer: "Sì, puoi disdire il tuo abbonamento in qualsiasi momento. Non ci sono vincoli o penali per la cancellazione. Se decidi di disdire, manterrai l'accesso al servizio fino alla fine del periodo di fatturazione corrente."
    },
    {
      question: <span className="question-title"><FiLayers className="question-icon" /> Gestione dei limiti del piano</span>,
      answer: "Nel piano gratuito, una volta raggiunto il limite di 2 documenti, dovrai passare a un piano a pagamento per poter caricare ulteriori documenti. Nei piani a pagamento, invece, non c'è un limite al numero di documenti che puoi caricare e analizzare. Puoi utilizzare il servizio senza preoccuparti di raggiungere un limite massimo."
    },
    {
      question: <span className="question-title"><FiUsers className="question-icon" /> Account personale non condivisibile</span>,
      answer: "No, gli abbonamenti sono strettamente personali e non possono essere condivisi. Ogni account è associato a un singolo utente per garantire la sicurezza e la privacy dei dati. Se hai bisogno di accessi multipli, ti consigliamo di contattare il nostro team commerciale per soluzioni personalizzate."
    },
    {
      question: <span className="question-title"><FiPercent className="question-icon" /> Sconti speciali per studenti</span>,
      answer: "Sì, offriamo tariffe speciali per studenti e gruppi. Gli studenti possono beneficiare di sconti significativi presentando un documento di identità studentesca valido. Per i gruppi e le istituzioni educative, offriamo piani personalizzati con prezzi vantaggiosi. Contatta il nostro team per maggiori informazioni."
    },
    {
      question: <span className="question-title"><FiSun className="question-icon" /> Piano accademico con pausa estiva</span>,
      answer: "Il piano accademico è progettato specificamente per l'anno scolastico. L'abbonamento viene automaticamente sospeso durante i mesi estivi (luglio e agosto), permettendoti di risparmiare quando non studi. La sospensione è automatica e il servizio riprenderà automaticamente a settembre."
    },
    {
      question: <span className="question-title"><FiRefreshCw className="question-icon" /> Cambio piano in qualsiasi momento</span>,
      answer: "Sì, puoi passare a un piano superiore in qualsiasi momento. Il costo verrà adeguato pro-rata in base al tempo rimanente del tuo abbonamento attuale. Se desideri passare a un piano inferiore, la modifica sarà effettiva al prossimo rinnovo."
    },
    {
      question: <span className="question-title"><FiDollarSign className="question-icon" /> Sistema di fatturazione chiaro</span>,
      answer: "La fatturazione avviene automaticamente all'inizio di ogni periodo di abbonamento. Per i piani mensili, l'addebito è mensile, mentre per i piani annuali l'addebito è annuale. Riceverai sempre una fattura via email e potrai accedere allo storico delle fatture dal tuo account."
    },
    {
      question: <span className="question-title"><FiDatabase className="question-icon" /> Gestione dei dati post-disdetta</span>,
      answer: "Dopo la cancellazione dell'abbonamento, avrai accesso ai tuoi dati fino alla fine del periodo di fatturazione. Successivamente, i tuoi dati rimarranno archiviati in modo sicuro per 30 giorni, durante i quali potrai riattivar il tuo account mantenendo tutti i tuoi contenuti. Dopo 30 giorni, i dati verranno eliminati definitivamente."
    }
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  return (
    <motion.div 
      className="PricingAccordion"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      {faqItems.map((item, index) => (
        <AccordionItem 
          key={index} 
          question={item.question} 
          answer={item.answer}
        />
      ))}
    </motion.div>
  );
};

export default PricingAccordion; 