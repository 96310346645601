import React, { useEffect, useState } from "react";
import usePayment from "./hook/usePayment";
import { useGetPlans } from "./hookPlans";
import { useSelector } from "react-redux";
import { getUserProfile } from "../../lib/firestoreQueries";

import Heading from "@ui/heading/heading";
import Loader from "./loader/loader"; 
import { SubscriptionModes } from "../../containers/priceCard/SubscriptionModes";
import { AnnualPlans } from "./AnnualPlans/AnnualPlans";
import PricingAccordion from "../pricing/pricingFaq/pricingAccordion/pricingAccordion";

import "./index.scss";

const Plans = () => {
  const { isLoading, upgradePlan, manageSubscription } = usePayment();
  const { renderSelectedPlan, renderUnselectedPlan } = useGetPlans();
  const { user } = useSelector((state) => state.auth);
  const [isAnnual, setIsAnnual] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);

  const isPremium = user.plan === 'Premium';
  
  const handlePremiumSubscription = (priceId, currentPlan) => {
    if (isPremium && currentPlan === selectedPlan) {
      console.log(currentPlan, selectedPlan);
      manageSubscription();
    }else {
      upgradePlan(priceId);
    }
  };
  
  const suggestedPlan = renderUnselectedPlan(selectedPlan, isLoading, isAnnual, setIsAnnual, handlePremiumSubscription);
  
  const getUserData = async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const user = await getUserProfile(userId);
    setSelectedPlan(user.selectedPlan);
    return selectedPlan;
  };

  // Get user selected sub plan to render the right priceCard
  useEffect(() => {
    if(user.selectedPlan) {
      getUserData();
    }
  },[]);

  return (
    <div className="Plans">
      <div className="container">
        <div className="justify-center row">
          <div className="Plans__info col-xs-12 col-md-10 col-lg-6">
            <Heading heading="h4" title={isPremium ? "Gestisci Abbonamento" : "Acquista Abbonamento"} />
            <p className="Plans__text">Scegli il piano che meglio si adatta alle tue esigenze e inizia a migliorare il tuo metodo di studio oggi stesso.</p>
          </div>
          {(suggestedPlan && isPremium) && <SubscriptionModes isUpgrade={isUpgrade} setIsUpgrade={setIsUpgrade} />}
          <div className="col-xs-12">
            {isLoading && <Loader />}
            {(!isLoading && !isPremium) && <SubscriptionModes isAnnual={isAnnual} setIsAnnual={setIsAnnual} />}
            {!isLoading 
              ? 
                isAnnual 
                ? 
                  <AnnualPlans 
                    isPremium={isPremium}
                    isLoading={isLoading}
                    isAnnual={isAnnual}
                    setIsAnnual={setIsAnnual}
                    handlePremiumSubscription={handlePremiumSubscription} 
                  /> 
                  
                : 
                  <>
                    {!isUpgrade || !suggestedPlan || !isPremium
                      ?
                        renderSelectedPlan(
                          selectedPlan,
                          isPremium,
                          isLoading,
                          isAnnual,
                          setIsAnnual,
                          handlePremiumSubscription
                        )
                        
                      : isPremium && suggestedPlan
                    }
                
                  </>
              : null 
            }
          </div>
          
          {/* Sezione FAQ sui piani */}
          {!isPremium && (
            <div className="col-xs-12 mt-5">
              <div className="Plans__faq">
                <div className="Plans__faq-header">
                  <Heading heading="h4" title="Domande Frequenti sui Piani" className="Plans__faq-title" />
                  <p className="Plans__faq-text">Trova risposte alle domande più comuni sui nostri piani e abbonamenti</p>
                </div>
                <div className="Plans__faq-content">
                  <PricingAccordion />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plans;
