import React, { useState } from "react";
import "./index.scss";
import useFilteredDocuments from "./hooks/useSearchReview";
import useSaveDocument from "./hooks/useSaveDocument";
import useGetExamsInProgress from "./examsInProgress/hook/useGetExamsInProgress";
import useGetDocuments from "@hooks/useGetDocuments";
import useSearchByTitle from "@hooks/useSearchByTitle";
import { useNavigate } from "react-router-dom";

import ExamsInProgress from "./examsInProgress/examsInProgress";
import ReviewSearchbar from "./reviewSearchbar/reviewSearchbar";
import ReviewDocuments from "./reviewDocuments/reviewDocuments";
import ReviewLoading from "./reviewLoading/reviewLoading";
import Button from "@ui/button/button";
/* import { MenuExamMode } from "./menuExamMode/MenuExamMode"; */

const Review = () => {
  const { query, changeQuery } = useFilteredDocuments();
  const { document, saveDocument } = useSaveDocument();
  const [selectedId, setSelectedId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  /* const [showModal, setShowModal] = useState(false); */
  const [reviewMode] = useState(true);
  const {
    isLoading: isExamsLoading,
    examsInProgress = [],
    deleteExam,
    refreshExams,
    error: examsError,
  } = useGetExamsInProgress();

  const {
    isLoading: isDocumentsLoading,
    documents = [],
  } = useGetDocuments("all");

  const filteredExamsInProgress = useSearchByTitle(examsInProgress, query);
  const filteredDocuments = useSearchByTitle(documents, query);

  const isLoading = isExamsLoading || isDocumentsLoading; // Combine loading states
  const navigate = useNavigate();

  return (
    <div className="Review">
      <div className="container">
        <div className="justify-center row">
          <div className="col-xs-12 col-md-8 col-lg-6">
            <ReviewSearchbar onChange={changeQuery} value={query} />

            {isLoading ? (
              <ReviewLoading text="Caricamento Esami..." />
            ) : (
              <>
               {/*  {showModal && (
                  <div className="Review__exam-mode-background">
                    <MenuExamMode 
                    setShowModal={setShowModal} 
                    reviewMode={reviewMode}
                    setReviewMode={setReviewMode}
                    onStartPreparation={() => {
                      navigate(`/chat/${document.id}`, {
                        state: {
                          title: document.title,
                          status: document.status,
                          examID: document.examID,
                          reviewMode: reviewMode,
                        },
                      })
                    }}
                  />
                  </div>
                )} */}
                <ExamsInProgress
                  query={query}
                  onSaveDocument={saveDocument}
                  deleteExam={deleteExam}
                  refreshExams={refreshExams}
                  error={examsError}
                  filteredExamsInProgress={filteredExamsInProgress}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                />
                <ReviewDocuments
                  query={query}
                  onSaveDocument={saveDocument}
                  documents={filteredDocuments} 
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                />
              </>
            )}

            <Button
              disabled={!document}
              className="Review__button"
              size="md"
              variant="primary"
              onClick={() =>
                navigate(`/chat/${document.id}`, {
                  state: {
                    title: document.title,
                    status: document.status,
                    examID: document.examID,
                    reviewMode: reviewMode,
                  },
                })
              }
            >
              Avvia Preparazione
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
