import React from 'react';
import PropTypes from 'prop-types';
import useGetQuestions from './hook/useGetQuestions';
import Question from './question/question';
import MaterialsFlashcardsNotFound from '../materialsFlashcards/materialsFlashcardsNotFound/materialsFlashcardsNotFound';
import Spinner from '@ui/spinner/spinner';
import './materialsQuestions.scss';
import { HiDownload } from 'react-icons/hi';

const MaterialsQuestions = ({ idSelectedDocument, isLegacyFormat = true }) => {
  const { isLoading, questions } = useGetQuestions(idSelectedDocument, isLegacyFormat);

  // Filter out discarded questions from user
  const updatedQuestions = questions.filter(qa => qa && qa.feedback?.isHelpful !== false);

  const handleDownload = () => {
    // Prepara i dati delle domande in formato CSV
    const csvContent = updatedQuestions.map(qa => {
      // Escape delle virgole e newline per il CSV
      const escapedQuestion = `"${qa.question.replace(/"/g, '""')}"`;
      const escapedAnswer = `"${qa.answer.replace(/"/g, '""')}"`;
      return `${escapedQuestion},${escapedAnswer}`;
    }).join('\n');

    const header = 'Domanda,Risposta\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'domande_ripetizione.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // show spinner if is loading
  if (isLoading) {
    return (
      <div className="MaterialsQuestions">
        <div className="MaterialsQuestions__loading">
          <Spinner size="sm" />
          <p>Caricamento domande...</p>
        </div>
      </div>
    );
  }

  // return select document if id selected document is null
  if (!idSelectedDocument) {
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />;
  }

  // return empty message if no questions found
  if (!isLoading && updatedQuestions.length === 0) {
    return (
      <MaterialsFlashcardsNotFound 
        message='Nessuna domanda di ripetizione trovata nel documento selezionato'
      />
    );
  }

  return (
    <div className='MaterialsQuestions'>
      {/* Stats Section */}
      <div className="MaterialsQuestions__stats">
        <div className="MaterialsQuestions__stats-header">
          <div 
            className="MaterialsQuestions__stats-icon"
            onClick={handleDownload}
            title="Scarica domande"
          >
            <HiDownload size={24} />
          </div>
          <div className="MaterialsQuestions__stats-info">
            <div className="MaterialsQuestions__stats-details">
              <div className="MaterialsQuestions__stats-item">
                {updatedQuestions.length} domande disponibili
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='MaterialsQuestions__container row gy-xs-1 g-md-1 g-lg-3'>
        {updatedQuestions.map((question, index) => (
          <Question 
            key={index} 
            question={question.question} 
            answer={question.answer}
          />
        ))}
      </div>
    </div>
  );
};

MaterialsQuestions.propTypes = {
  idSelectedDocument: PropTypes.string,
  isLegacyFormat: PropTypes.bool
};

export default MaterialsQuestions; 