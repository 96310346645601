import PriceCard from "../../../containers/priceCard/priceCard";
import "./monthlyPlan.scss";

export const MonthlyPlan = ({ isAnnual, setIsAnnual, isPremium, handlePremiumSubscription, isLanding, onClick }) => {

  return (
    <div className={isPremium ? 'MonthlyPlan__premium' : null}>
      <PriceCard
      title="Premium"
      originalCost={"14.99"}
      cost={"7.99"}
      method={"mese"}
      isAnnual={isAnnual}
      setIsAnnual={setIsAnnual}
      options={[
        "Documenti, flashcards, riassunti, domande di ripetizione e a risposta multipla illimitati",
        "Simula tutti gli esami che vuoi",
        "Accesso a tutte le funzionalità future",
        "Assistenza e-mail e chat prioritaria"
      ]}
      buttonText={isPremium ? "Gestisci abbonamento" : "Acquista Ora"}
      onClick={isLanding ? onClick : () => handlePremiumSubscription("price_1P5qivHFdskyUb7jbQQfqoir", 'Monthly')}
      advised={!isPremium}
      showCountdown={!isPremium}
      />
    </div>
  )
};