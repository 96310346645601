import { SET_INTERROGATION_QA, SET_CURRENT_EXAM_ID } from '../actions/types';

const initialState = {
  interrogationQA: [],
  currentExamID: null,
};

const examReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERROGATION_QA:
      return { 
        ...state, 
        interrogationQA: [...state.interrogationQA, action.payload], 
      };
    case SET_CURRENT_EXAM_ID:
      return { 
        ...state, 
        currentExamID: action.payload, 
      };
    default:
      return state;
  }
};

export default examReducer;
