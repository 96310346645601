import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useSelectDocument = () => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const documents = useSelector(state => state.document.documents);

  useEffect(() => {
    // If the selected document is deleted, clear the selection
    if (selectedDocument && !documents.find(doc => doc.id === selectedDocument.id)) {
      setSelectedDocument(null);
    }
    // If no document is selected and there are documents, select the first one
    else if (!selectedDocument && documents.length > 0) {
      setSelectedDocument(documents[0]);
    }
  }, [documents, selectedDocument]);

  const changeDocumentId = (documentId) => {
    const document = documents.find(doc => doc.id === documentId);
    setSelectedDocument(document || null);
  };

  return { selectedDocument, changeDocumentId };
};

export default useSelectDocument;
