import PdfIcon from "@assets/icons/pdf.png";
import DocxIcon from "@assets/icons/docx.png";
import PptxIcon from "@assets/icons/pptx.png";
import PagesIcon from "@assets/icons/pages.png";
import KeyIcon from "@assets/icons/keynote.png";
import { processDocument } from "@services/openaiApi";

export const getTypeFile = fileType => {
  if (fileType && fileType.split("/").length > 1) return fileType.split("/")[1];
  return fileType;
};

export const getFileSizeString = (bytes) => {
  if (bytes < 1024) return `${bytes} B`;
  if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(1)} KB`;
  return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
};

export const getFileIcon = fileType => {
  switch (getTypeFile(fileType)) {
    case "pdf":
      return PdfIcon;
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return DocxIcon;
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return PptxIcon;
    case "vnd.apple.pages":
      return PagesIcon;
    case "vnd.apple.keynote":
      return KeyIcon;
    default:
      return null;
  }
};

const getRawExtractedQA = async extractedText => {
  try {
    // Pulisci la cache precedente
    localStorage.removeItem('lastProcessedDocument');
    
    const rawExtractedQA = await processDocument(extractedText);
    console.log('Raw server response:', rawExtractedQA);

    // Parse delle risposte dal server
    let flashcardsResponse = rawExtractedQA.qa_response;
    let examResponse = rawExtractedQA.interrogation_questions;
    let summaryResponse = rawExtractedQA.summary;
    let quizResponse = rawExtractedQA.quiz_questions;

    // Se sono stringhe, parsifichiamo
    if (typeof flashcardsResponse === 'string') {
      flashcardsResponse = JSON.parse(flashcardsResponse);
    }
    if (typeof examResponse === 'string') {
      examResponse = JSON.parse(examResponse);
    }
    if (typeof summaryResponse === 'string') {
      summaryResponse = JSON.parse(summaryResponse);
    }
    if (typeof quizResponse === 'string') {
      quizResponse = JSON.parse(quizResponse);
    }

    // Normalizza il formato delle domande quiz
    const normalizedQuizQuestions = Array.isArray(quizResponse) ? quizResponse : [];
    
    // Estrai i tags e le flashcard dalla risposta
    const flashCardsTags = flashcardsResponse?.tags || [];
    const flashcardsArray = flashcardsResponse?.flashcards || [];

    // Converti le flashcard nel formato qa_pairs
    const qa_pairs = flashcardsArray.map(card => ({
      question: card.card_question,
      answer: card.card_answer,
      type: 'flashcard',
      metadata: {
        isFlashcard: true,
        tags: flashCardsTags
      }
    }));

    // Create unified objects with correct structure
    const unifiedFlashCardsQA = {
      qa_pairs: qa_pairs,
      tags: flashCardsTags,
      summary: summaryResponse
    };

    const unifiedExamQA = {
      qa_pairs: examResponse?.qa_pairs || [],
      tags: flashCardsTags,
      summary: summaryResponse
    };

    console.log('Processed flashcards:', {
      qa_pairs,
      flashcardsCount: qa_pairs.length,
      tags: flashCardsTags
    });

    // Store the current document data
    localStorage.setItem('lastProcessedDocument', JSON.stringify({
      timestamp: Date.now(),
      flashcards: unifiedFlashCardsQA,
      exam: unifiedExamQA,
      summary: summaryResponse,
      quiz_questions: normalizedQuizQuestions
    }));

    return {
      flashcards: unifiedFlashCardsQA,
      exam: unifiedExamQA,
      summary: summaryResponse,
      quiz_questions: normalizedQuizQuestions,
      qa_response: flashcardsResponse // Manteniamo anche la risposta originale
    };
  } catch (error) {
    console.error('Error processing data:', error);
    throw error;
  }
};

const formatExtractedQA = (rawExtractedQA, document) => {
  try {
    const extractedQA = [];
    const examExtractedQA = [];
    
    // Estrai i tags e le flashcard
    const tags = rawExtractedQA.flashcards?.tags || [];
    const qa_pairs = rawExtractedQA.flashcards?.qa_pairs || [];
    
    console.log('Formatting flashcards:', {
      qa_pairs_length: qa_pairs.length,
      tags
    });

    extractedQA.push({
      tag: tags,
      qa_pairs,
      tags,
      summary: rawExtractedQA.summary
    });

    // Formatta le domande di ripetizione
    examExtractedQA.push({
      qa_pairs: rawExtractedQA.exam?.qa_pairs || [],
      tags,
      summary: rawExtractedQA.summary
    });

    // Manteniamo i dati raw per debug
    const rawFlashcards = {
      qa_pairs,
      tags,
      summary: rawExtractedQA.summary
    };

    const rawExamData = {
      qa_pairs: rawExtractedQA.exam?.qa_pairs || [],
      tags,
      rawFlashcards,
      summary: rawExtractedQA.summary
    };

    const result = {
      id: Date.now().toString(),
      title: document.name.split(".")[0],
      type: document.type,
      text: document.extractedText,
      extractedQA,
      examExtractedQA,
      summary: rawExtractedQA.summary,
      quiz_questions: rawExtractedQA.quiz_questions,
      tags,
      rawFlashcards,
      rawExamData,
      qa_response: rawExtractedQA.qa_response,
      metadata: {
        flashcardsCount: qa_pairs.length,
        questionsCount: rawExamData.qa_pairs.length,
        quizCount: rawExtractedQA.quiz_questions?.length || 0,
        sectionsCount: rawExtractedQA.summary?.sections?.length || 0,
        hasSummary: !!rawExtractedQA.summary?.abstract,
        tags
      }
    };

    console.log('Final formatted result:', result);
    return result;
  } catch (error) {
    console.error('Error formatting data:', error);
    throw error;
  }
};

export const getExtractedQA = async documents => {
  if(documents.length > 1) {
    try {
      const combinedText = documents
        .filter(doc => !doc.errorMessage) 
        .map(doc => doc.extractedText)   
        .join(' ');                      
  
      if (!combinedText) return [];
  
      const rawExtractedQA = await getRawExtractedQA(combinedText);
  
      const documentQA = formatExtractedQA(rawExtractedQA, {
        name: 'Documento',   
        type: 'multiple',            
        extractedText: combinedText  
      });
  
      return [documentQA]; 
    } catch (error) {
      throw error;
    }
  } else {
    try {
      let documentsQA = [];

      for (const document of documents) {
        if (document.errorMessage) continue;
  
        const rawExtractedQA = await getRawExtractedQA(document.extractedText);
        const documentQA = formatExtractedQA(rawExtractedQA, document);
        documentsQA = [...documentsQA, documentQA];
      }
  
      return documentsQA;
    } catch (error) {
      throw error;
    }
  }
};