import PriceCard from "../../../containers/priceCard/priceCard";
import "./schoolPlan.scss";

export const SchoolPlan = ({ isAnnual, setIsAnnual, isLoading, isPremium, handlePremiumSubscription }) => {
  const monthlyEquivalent = "7.22";
  
  return (
    <div className={isPremium ? 'SchoolPlan__premium' : null}>
      <PriceCard
        isDisabled={isLoading}
        title="Piano Accademico"
        originalCost={"14.99"}
        cost={monthlyEquivalent}
        totalCost={"64.99"}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        accessInfo={'≈ 9 mesi (pausa estiva esclusa)'}
        options={[
          `Risparmia ${(14.99 - parseFloat(monthlyEquivalent)).toFixed(2)}€ al mese (-52% sul prezzo originale)`,
          "Documenti, flashcards, riassunti, domande di ripetizione e a risposta multipla illimitati",
          "Simulazioni AI per ogni materia scolastica",
          "Supporto dedicato per le materie scolastiche",
          "Accesso a tutte le funzionalità future",
          "Assistenza prioritaria 24/7"
        ]}
        buttonText={isPremium ? "Gestisci abbonamento" : "Inizia Anno Accademico"}
        onClick={handlePremiumSubscription}
        advised={!isPremium}
        finalText={'Sospensione automatica mesi estivi'}
      />
    </div>
  )
};