import { useState, useCallback, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getQuestions, isExamInProgress, getQAExamSessions } from "@lib/firestoreQueries";

const useGetQA = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [qa, setQA] = useState([]);
  const [currentQA, setCurrentQA] = useState(null);
  const { documentID } = useParams();
  const { state: { status, examID } } = useLocation();
  const didInit = useRef(false);

  const fetchQuestions = useCallback(async () => {
    const questions = await getQuestions(documentID);
    const formattedQuestions = questions.docs.map(doc => {
      const data = doc.data();
      if (!data.id) {
        data.id = doc.id;
      }
      return { id: doc.id, ...data };
    });

    // Prima filtriamo per tipo di domanda
    const filteredQuestions = formattedQuestions.filter(qa => {
      // Se non ci sono metadati o è un documento vecchio, mostra tutte le domande
      if (!qa?.metadata) {
        return true;
      }
      // Per i nuovi documenti, mostra solo le domande di ripetizione
      return qa.metadata.isRepetitionQuestion === true || qa.metadata.isExamQuestion === true;
    });

    // Rimuovi duplicati e valida le domande
    const seenQuestions = new Set();
    const uniqueQuestions = filteredQuestions.filter(qa => {
      const question = qa.question || qa.domanda || '';
      if (!question || typeof question !== 'string') return false;
      
      const normalizedQuestion = question.trim().toLowerCase();
      if (!seenQuestions.has(normalizedQuestion)) {
        seenQuestions.add(normalizedQuestion);
        return true;
      }
      return false;
    });

    // Gestione sicura del sorting
    const sortedQuestions = uniqueQuestions.sort((a, b) => {
      const questionA = a.question || a.domanda || '';
      const questionB = b.question || b.domanda || '';
      
      try {
        return questionA.localeCompare(questionB);
      } catch (error) {
        console.warn('Warning: Unable to sort questions properly', error);
        return 0;
      }
    });

    console.log("Fetched and sorted questions:", sortedQuestions);
    return sortedQuestions;
  }, [documentID]);

  const fetchCurrentQA = useCallback(async (examInProgressID) => {
    const currentQAArray = await getQAExamSessions(examInProgressID, documentID);
    setCurrentQA([...currentQAArray]);
    console.log("Fetched current QA:", currentQAArray);
  }, [documentID]);

  const getQA = useCallback(async () => {
    try {
      setIsLoading(true);
      const questions = await fetchQuestions();

      if (status === "not-started") {
        setQA(questions);
        setIsLoading(false);
        return;
      }

      const examInProgressID = await isExamInProgress(examID);
      if (examInProgressID) await fetchCurrentQA(examInProgressID);

      setQA(questions);
      setIsLoading(false);
    } catch (error) {
      toast.error("Errore nel caricamento delle domande e risposte");
      console.error("Errore nel caricamento delle domande e risposte:", error);
      setIsLoading(false);
    }
  }, [fetchQuestions, fetchCurrentQA, status, examID]);

  useEffect(() => {
    if (!didInit.current) {
      didInit.current = true;
      getQA();
    }
  }, [getQA]);

  return { isLoading, qa, currentQA, documentID };
};

export default useGetQA;
