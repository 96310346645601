import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { truncate } from "@utility/truncate";
import { getFileIcon } from "@lib/file";
import { getTagColor } from "../../uploadNotes/documentsQA/hooks/useGetTagColor";
import Heading from "@ui/heading/heading";
import Field from "@ui/field/field";
import "./reviewDocument.scss";
import { Image, Music, Video } from "lucide-react";
import { useDispatch } from "react-redux";
import { setCurrentExamID } from "../../../redux/actions/examActions";

/* // Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";
import useGetOnboardingState from "../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState";
import "intro.js/introjs.css"; */

const useDocumentSelection = (id, examID, title, status, onChange) => {
  const { register } = useForm();
  
  return register("document", {
    onChange: (event) => onChange({ id: event.target.value, examID, title, status })
  });
};

const ReviewDocument = ({ 
  id = '', 
  examID = '', 
  title = '', 
  type = '', 
  tags = [], 
  text = '', 
  status = '', 
  onChange = () => {}, 
  selectedId = '', 
  setSelectedId = () => {}, 
  selectedStatus = '', 
  setSelectedStatus = () => {} 
}) => {
  const documentRegister = useDocumentSelection(id, examID, title, status, onChange);
  const dispatch = useDispatch();
  const tagsRef = useRef(null);
  
  // Gestione dello scrolling per dispositivi touch
  useEffect(() => {
    if (!tagsRef.current) return;
    
    let scrollTimeout;
    const tagsElement = tagsRef.current;
    
    const handleScroll = () => {
      tagsElement.classList.add('scrolling');
      
      // Rimuovi la classe dopo che lo scroll è terminato
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        tagsElement.classList.remove('scrolling');
      }, 1000);
    };
    
    tagsElement.addEventListener('scroll', handleScroll);
    
    return () => {
      tagsElement.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const getUpdatedFileIcon = fileType => {
    if(fileType === 'png' || fileType === 'jpeg' ) {
      return <Image className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp3' || fileType === 'vaw') {
      return <Music className="FilteredDocument__left-icon" />;
    } else if (fileType === 'mp4') {
      return <Video className="FilteredDocument__left-icon" />;
    } 
  };

  const handleClick = () => {
    if(status === 'in-progress') {  
      setSelectedId(examID);
    } else {
      setSelectedId(id);
    }
    setSelectedStatus(status);
    
    // Save the examID in the redux store
    dispatch(setCurrentExamID(examID));
  };

  return (
    <li className={`FilteredDocument__wrapper ${selectedStatus === status && (selectedId === examID || selectedId === id) ? 'FilteredDocument__wrapper--selected' : 'FilteredDocument__wrapper--unselected'}`}>
      {/* <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      />  */}
      <div className="FilteredDocument">
        <div className="FilteredDocument__inner">
          <div className="FilteredDocument__left">
            {getUpdatedFileIcon(type) || <img className="FilteredDocument__left-image" src={getFileIcon(type)} alt={`file ${title}`} />}
            <div className="FilteredDocument__box">
              <Heading title={title || 'Esame in corso'} heading="h5" />
              {text && <p className="FilteredDocument__box-text">{truncate(text, 80)}</p>}
            </div>
          </div>
          <div className="FilteredDocument__actions">
            <Field>
              <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
              <input
                className="FilteredDocument__input"
                id={`document-check-${id}`}
                value={id}
                type="radio"
                onClick={handleClick}
                {...documentRegister}
                />
            </Field>
          </div>
        </div>
        
        <div className="FilteredDocument__mobile-wrapper">
          <Field className="FilteredDocument__mobile-checkbox">
            <Field.Label className="FilteredDocument__label" htmlFor={`document-check-${id}`} />
            <input
              className="FilteredDocument__input"
              id={`document-check-${id}`}
              value={id}
              type="radio"
              onClick={handleClick}
              {...documentRegister}
              />
          </Field>
          { tags !== null && (Array.isArray(tags) || (typeof tags === 'object' && tags.tags)) ? 
            <div className="FilteredDocument__tags" ref={tagsRef}>
                {(Array.isArray(tags) ? tags : tags.tags).map((tag, idx) => {
                  return (
                    <div key={`${tag}-${idx}`}>
                    <span
                      className="FilteredDocument__tag"
                      style={{ 
                        backgroundColor: getTagColor(idx, tag).backgroundColor, 
                        color: getTagColor(idx, tag).color,
                        borderColor: getTagColor(idx, tag).borderColor,
                      }}
                      >
                      {tag}
                    </span>
                  </div> 
                )})}
            </div>
            
            : null
          }
        </div>
      </div>
    </li>
  );
};

ReviewDocument.propTypes = {
  id: PropTypes.string.isRequired,
  examID: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string)
    })
  ]),
  text: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func,
  selectedId: PropTypes.string,
  setSelectedId: PropTypes.func,
  selectedStatus: PropTypes.string,
  setSelectedStatus: PropTypes.func
};

export default ReviewDocument;
