import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./upgradePlan.scss";

import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUpgradeClicked } from "../../../../redux/actions/loadScoreActions";
import { doc, increment, updateDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";

const UpgradePlan = ({ className, ...props }) => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  
  const handleUpgradePlanClick = async () => {
    dispatch(setUpgradeClicked());

    // trigger event for how many times popup was viewed
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'upgradePlanClicked', {
        'event_category': 'Account',
        'event_label': 'upgradePlanClicked',
        'email': user.email,
      });        

      try { 
        const userId = localStorage.getItem('userId');
        let userStats = doc(firestore, 'students', JSON.parse(userId));
  
        await updateDoc(userStats, {
          upgradePlanClicked: increment(1),
        }, { merge: true });
        
      } catch(error) {
        console.error('Error fetching user stats', error);
      };
    } else {
        console.error("gtag is not defined");
      }
  };
  
  return (
    <HashLink onClick={handleUpgradePlanClick} className={clsx("UpgradePlan", className)} to="/home/piani" {...props}>
      <p className="UpgradePlan__text">Aggiorna il tuo piano</p>
    </HashLink>
  );
};

// UpgradePlan props types
UpgradePlan.propTypes = {
  className: PropTypes.string,
};

export default UpgradePlan;
