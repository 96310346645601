import { useCallback, useEffect, useState } from "react";
import { getExamsInProgress, deleteExamFromFirestore } from "@lib/firestoreQueries";
import { toast } from "react-toastify";

const useGetExamsInProgress = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [examsInProgress, setExamsInProgress] = useState([]);
  const [error, setError] = useState(null);

  const fetchExamsInProgress = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const exams = await getExamsInProgress();
      const sortedExams = exams.sort((a, b) => b.examEnd - a.examEnd);
      setExamsInProgress(sortedExams);
    } catch (error) {
      setError(error.message);
      toast.error("Errore nel recupero degli esami in corso");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchExamsInProgress();
  }, [fetchExamsInProgress]);

  const deleteExam = useCallback(async (examID) => {
    try {
      setIsLoading(true);
      await deleteExamFromFirestore(examID);
      setExamsInProgress(prevExams => prevExams.filter(exam => exam.examID !== examID));
      toast.success("Esame eliminato con successo");
    } catch (error) {
      toast.error("Errore nell'eliminazione dell'esame");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, examsInProgress, deleteExam, refreshExams: fetchExamsInProgress, error };
};

export default useGetExamsInProgress;