import React from "react";
import AccordionItem from "./accordionItem/accordionItem";
import "./accordion.scss";
import { motion } from "framer-motion";
import { 
  FiBook, 
  FiZap, 
  FiTarget, 
  FiFileText, 
  FiLock, 
  FiMessageCircle, 
  FiBookOpen,
  FiAward 
} from "react-icons/fi";

const Accordion = () => {
  const faqItems = [
    {
      question: <span className="question-title"><FiZap className="question-icon" /> Trasforma il tuo studio con l'AI</span>,
      answer: "StudierAI trasforma completamente il tuo approccio allo studio attraverso l'Intelligenza Artificiale. Il sistema analizza i tuoi documenti e crea: simulazioni ed orali per prepararsi a ripetizioni e interrogazioni che si adattano al tuo livello, riassunti intelligenti che evidenziano i concetti chiave, flashcard interattive, domande di ripetizione e domande a risposta multipla generate automaticamente dai tuoi materiali. Questo approccio integrato ti permette di ottimizzare il tempo di ripasso e ripetizione aumentando significativamente la tua comprensione e memorizzazione."
    },
    {
      question: <span className="question-title"><FiBook className="question-icon" /> Le tre funzionalità essenziali</span>,
      answer: "StudierAI offre tre strumenti principali per ottimizzare il tuo studio: 1) Simulatore di Ripetizione e Interrogazioni: utilizza il voice recognition per simulare vere interrogazioni orali, permettendoti di esercitarti come se fossi in classe; 2) Riassunti Intelligenti: l'AI analizza i tuoi documenti ed estrae automaticamente i concetti più importanti, creando riassunti chiari e concisi; 3) Flashcard Interattive, Domande di Ripetizione e Domande a Risposta Multipla: vengono generate automaticamente dai tuoi materiali per un ripasso efficace e completo."
    },
    {
      question: <span className="question-title"><FiTarget className="question-icon" /> Simulazione intelligente delle interrogazioni</span>,
      answer: "Il nostro simulatore di interrogazioni è progettato per replicare fedelmente un'interrogazione reale. Il sistema: 1) Analizza il materiale che hai caricato; 2) Formula domande pertinenti basate sul contenuto; 3) Ascolta e analizza le tue risposte vocali in tempo reale; 4) Fornisce feedback dettagliati sulla completezza e accuratezza delle tue risposte; 5) Si adatta al tuo livello di preparazione; 6) Identifica gli argomenti su cui hai più difficoltà per aiutarti a migliorare. Puoi anche leggere le tue risposte trascritte per analizzare e migliorare la tua preparazione."
    },
    {
      question: <span className="question-title"><FiFileText className="question-icon" /> Compatibilità con tutti i formati</span>,
      answer: "StudierAI supporta i principali formati di documenti di studio: 1) Documenti Testuali: PDF, DOCX, TXT per appunti e materiali di studio; 2) Contenuti Multimediali: MP4 per video-lezioni, MP3 per audio-lezioni; 3) Immagini: appunti scritti a mano, diagrammi, grafici in formato JPG e PNG. L'AI analizza ogni tipo di contenuto mantenendo l'integrità delle informazioni e trasformandole in materiale di studio efficace."
    },
    {
      question: <span className="question-title"><FiLock className="question-icon" /> Privacy e sicurezza garantite</span>,
      answer: "La sicurezza dei tuoi dati è la nostra massima prioritità. Implementiamo: 1) Crittografia end-to-end per tutti i file caricati; 2) Server sicuri con backup automatici; 3) Conformità totale al GDPR e altre normative sulla privacy; 4) Controllo totale sui tuoi dati con possibilità di cancellazione in qualsiasi momento; 5) I tuoi documenti vengono processati in modo sicuro e non vengono mai condivisi con terze parti."
    },
    {
      question: <span className="question-title"><FiMessageCircle className="question-icon" /> Assistenza sempre al tuo fianco</span>,
      answer: "Offriamo un sistema di supporto completo: 1) Team di Supporto Dedicato: esperti disponibili via chat ed email per aiutarti con qualsiasi domanda; 2) Tutorial Interattivi: guide passo-passo per ogni funzionalità; 3) Centro Risorse: raccolta di guide e FAQ per aiutarti a sfruttare al meglio la piattaforma; 4) Assistenza Tecnica: supporto rapido per qualsiasi problema tecnico."
    },
    {
      question: <span className="question-title"><FiBookOpen className="question-icon" /> Personalizzato per ogni studente</span>,
      answer: "StudierAI si adatta al tuo livello specifico di istruzione: 1) Scuola Media: focus sulla comprensione base e metodo di studio; 2) Scuola Superiore: approfondimento delle materie specifiche dell'indirizzo scelto; 3) Università: preparazione specifica per esami universitari. Il sistema personalizza l'esperienza in base al tuo livello di comprensione e alle tue esigenze di studio."
    },
    {
      question: <span className="question-title"><FiAward className="question-icon" /> Inizia il tuo percorso di successo</span>,
      answer: "Iniziare a ripassare con StudierAI è semplice: 1) Registrazione: crea un account in pochi minuti; 2) Carica i Materiali: carica gli appunti o i documenti che vuoi ripassare; 3) Scegli la Modalità di Ripasso: puoi usare le flashcard per un ripasso veloce, consultare i riassunti AI per rinfrescare i concetti chiave, o metterti alla prova con una simulazione d'interrogazione; 4) Preparati all'Esame: esercitati con le simulazioni di interrogazioni per arrivare preparato e sicuro al momento dell'esame. Il nostro team di supporto è sempre disponibile per aiutarti."
    }
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  return (
    <motion.div 
      className="Accordion"
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      {faqItems.map((item, index) => (
        <AccordionItem 
          key={index} 
          question={item.question} 
          answer={item.answer}
        />
      ))}
    </motion.div>
  );
};

export default Accordion;