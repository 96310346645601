import React, { useEffect, useState, useMemo } from "react";
import "./countdown.scss";

// Memorizza la data target come costante fuori dal componente
const TARGET_DATE = new Date(2025, 0, 30, 23, 59, 59);

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({ 
    days: '0', 
    hours: '0', 
    minutes: '0', 
    seconds: '0' 
  });

  // Memorizza la funzione di calcolo con useMemo
  const calculateTimeLeft = useMemo(() => () => {
    const now = new Date();
    const difference = TARGET_DATE - now;

    // Se la differenza è negativa, il countdown è finito
    if (difference <= 0) {
      return {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00'
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0')
    };
  }, []); // La funzione non dipende da nessuna prop o stato

  useEffect(() => {
    // Calcola il tempo iniziale
    setTimeLeft(calculateTimeLeft());
    
    // Aggiorna ogni secondo
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        const newTime = calculateTimeLeft();
        // Aggiorna solo se c'è una differenza effettiva
        if (JSON.stringify(prevTime) !== JSON.stringify(newTime)) {
          return newTime;
        }
        return prevTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  return (
    <div className="Countdown-container">
      <div>
        <div className="Countdown-container__card">
          <span className="Countdown-container__time">{timeLeft.days}</span>
        </div>
        <div className="Countdown-container__label">Giorni</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.hours}</div>
        </div>
        <div className="Countdown-container__label">Ore</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.minutes}</div>
        </div>
        <div className="Countdown-container__label">Minuti</div>
      </div>

      <div>
        <div className="Countdown-container__card">
          <div className="Countdown-container__time">{timeLeft.seconds}</div>
        </div>
        <div className="Countdown-container__label">Secondi</div>
      </div>
    </div>
  );
};

export default Countdown;

