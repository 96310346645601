import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./VideoSection.scss";
import VideoContainer from "./VideoContainer/VideoContainer";

const VideoSection = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement.src += "?autoplay=1";
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, []);

  return (
    <section id="video-section" className="VideoSection">
      <motion.div 
        className="VideoSection__content"
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6 }}
      >
        <motion.h2 
          className="VideoSection__header"
          initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.2 }}
        >
          VIDEO
        </motion.h2>
        <motion.h3 
          className="VideoSection__title"
          initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.3 }}
        >
          Guarda il tutorial e scopri StudierAI
        </motion.h3>
        <motion.p 
          className="VideoSection__text"
          initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.4 }}
        >
          Carica i tuoi documenti e trasformali in interrogazioni interattive per una preparazione efficace. Esercitati con simulazioni orali e ricevi feedback immediati per perfezionare le tue risposte.
        </motion.p>
        <motion.div 
          className="VideoSection__buttons"
          initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.5 }}
        >
          <button className="VideoSection__button simulate" onClick={() => navigate("/signUp")}>
            Simulazione Orale
          </button>
          <Link to="https://www.instagram.com/studierai_app/" target="_blank" className="VideoSection__button instagram">
            Instagram
          </Link>
        </motion.div>
      </motion.div>
      <motion.div 
        className="VideoSection__video"
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.3 }}
      >
        <VideoContainer videoRef={videoRef} />
      </motion.div>
    </section>
  );
};

export default VideoSection;
