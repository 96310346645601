import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";

const useGetMultipleChoice = (idSelectedDocument, isLegacyFormat = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const getMultipleChoiceQuestions = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      const questionsData = await getQuestions(idSelectedDocument);
      
      // Filter for multiple choice questions
      const filteredQuestions = questionsData.docs
        .map(doc => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id
          };
        })
        .filter(qa => {
          // Basic validation
          if (!qa) return false;

          // Check if it's a quiz question from metadata
          const isQuizQuestion = qa?.metadata?.isQuizQuestion === true;
          if (!isQuizQuestion) return false;

          // Check if it has quiz_questions data
          if (!qa.quiz_questions) return false;

          // Check if it has the required structure
          return qa.quiz_questions.query_text && 
                 typeof qa.quiz_questions.answer_choices === 'object' && 
                 Object.keys(qa.quiz_questions.answer_choices).length === 4 && 
                 qa.quiz_questions.correct_choice;
        })
        .map(qa => ({
          id: qa.id,
          documentID: qa.documentID,
          feedback: qa.feedback,
          metadata: qa.metadata,
          // Spread the quiz_questions data to the top level
          ...qa.quiz_questions,
          // Ensure these fields are present
          type: qa.quiz_questions.type || 'quiz',
          source: qa.quiz_questions.source || 'quiz',
          uploadDate: qa.quiz_questions.uploadDate || qa.metadata?.createdAt
        }));

      console.log('Filtered multiple choice questions:', filteredQuestions);

      // Sort questions by difficulty and then alphabetically
      const sortedQuestions = filteredQuestions.sort((a, b) => {
        // First sort by difficulty
        const difficultyOrder = { easy: 1, medium: 2, hard: 3 };
        const diffA = difficultyOrder[a.difficulty_level] || 2;
        const diffB = difficultyOrder[b.difficulty_level] || 2;
        if (diffA !== diffB) return diffA - diffB;
        
        // Then sort alphabetically
        return (a.query_text || '').localeCompare(b.query_text || '');
      });

      // Ensure all questions have the required fields and set defaults if needed
      const processedQuestions = sortedQuestions.map(question => ({
        ...question,
        difficulty_level: question.difficulty_level || 'medium',
        justification: question.justification || 'La risposta corretta è: ' + question.correct_choice,
        metadata: {
          ...question.metadata,
          isMultipleChoice: true,
          isQuizQuestion: true
        }
      }));

      console.log('Final processed questions:', processedQuestions);
      setQuestions(processedQuestions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error in getMultipleChoiceQuestions:', error);
      toast.error("Errore nel recupero delle domande a risposta multipla");
      setIsLoading(false);
    }
  }, [idSelectedDocument]);

  useEffect(() => {
    getMultipleChoiceQuestions();
  }, [getMultipleChoiceQuestions]);

  return { isLoading, questions };
};

export default useGetMultipleChoice; 