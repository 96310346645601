import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDocuments } from '@lib/firestoreQueries';
import { toast } from 'react-toastify';

const useGetDocuments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const documents = useSelector(state => state.document.documents);

  useEffect(() => {
    const getDocuments = async () => {
      try {
        setIsLoading(true);
        const documentsResponse = await getUserDocuments();
        
        if (documentsResponse.empty) {
          dispatch({ type: 'SET_DOCUMENT', payload: [] });
        } else {
          dispatch({ type: 'SET_DOCUMENT', payload: documentsResponse.docs });
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
        toast.error('Errore nel recupero dei documenti');
        dispatch({ type: 'SET_DOCUMENT', payload: [] });
      } finally {
        setIsLoading(false);
      }
    };

    getDocuments();
  }, [dispatch]);

  return { isLoading, documents };
};

export default useGetDocuments;
