import {
  SET_DOCUMENT,
  SET_EXTRACTED_QA,
  SET_DOCUMENT_TITLE,
  SET_SUBMITTING_DOC,
  SET_DOCUMENTS_TAGS,
  SET_SUMMARY,
  SET_PROCESSED_NOTES,
  SET_FLASHCARDS_QA,
  SET_QUIZ_QA,
  DELETE_DOCUMENT,
} from './types';
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
  writeBatch,
  increment,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import { toast } from 'react-toastify';

const BATCH_SIZE = 500;

// Azioni sincrone
export const setExtractedQA = (documentId, extractedQA) => ({
  type: SET_EXTRACTED_QA,
  payload: { documentId, extractedQA },
});

export const setFlashcardsQA = (flashcardsQA) => ({
  type: SET_FLASHCARDS_QA,
  payload: flashcardsQA,
});

export const setDocumentTitle = (documentId, newTitle) => ({
  type: SET_DOCUMENT_TITLE,
  payload: { documentId, newTitle },
});

export const setDocumentTags = (documentId, tags) => ({
  type: SET_DOCUMENTS_TAGS,
  payload: { documentId, tags },
});

export const setProcessedNotes = (file) => ({
  type: SET_PROCESSED_NOTES,
  payload: file,
});

export const setDocumentSummary = (summary) => ({
  type: SET_SUMMARY,
  payload: summary,
});

export const setSubmitDocState = (submitState) => ({
  type: SET_SUBMITTING_DOC,
  payload: submitState,
});

export const setQuizQA = (quizQA) => ({
  type: SET_QUIZ_QA,
  payload: quizQA,
});

// Utility per controllo feedback valido
const isValidFeedback = (feedback) => {
  if (!feedback) return false;
  if (typeof feedback.isHelpful !== 'boolean') return false;
  return true;
};

// Normalizza il formato del feedback
const normalizeFeedback = (feedback) => {
  if (!feedback) return null;
  
  return {
    isHelpful: feedback.isHelpful,
    timestamp: feedback.timestamp || Date.now(),
    userId: feedback.userId || auth.currentUser?.uid || null,
    index: feedback.index
  };
};

// Salva domande e feedback in batch
const saveQABatch = async (examQuestions, feedback = [], documentId, userEmail, flashcards = [], quizQuestions = []) => {
  try {
    const batches = [];
    let currentBatch = writeBatch(firestore);
    let operationsCount = 0;
    let userStatsUpdated = false;
    let totalFeedbackCount = 0;

    // Verifica e pulisci i dati in ingresso
    const validExamQuestions = examQuestions.filter(q => q && q.question && q.answer);
    const validFlashcards = flashcards.filter(f => f && f.question && f.answer);
    const validQuizQuestions = quizQuestions.filter(q => q && q.query_text && q.answer_choices && q.correct_choice);

    console.log('🔍 Validazione dati:', {
      examQuestions: validExamQuestions.length,
      flashcards: validFlashcards.length,
      quizQuestions: validQuizQuestions.length
    });

    // Crea una mappa dei feedback per indice
    const feedbackMap = new Map(
      feedback
        .filter(f => isValidFeedback(f))
        .map(f => [f.index, normalizeFeedback(f)])
    );

    // Separa chiaramente le domande d'esame dalle flashcard e dai quiz
    const allQuestions = [
      ...validExamQuestions.map((q, index) => ({
        question: q.question,
        answer: q.answer,
        type: 'exam',
        feedback: feedbackMap.get(index),
        source: 'examExtractedQA',
        metadata: {
          isFlashcard: false,
          isExamQuestion: true,
          isQuizQuestion: false,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          tags: q.metadata?.tags || []
        }
      })),
      ...validFlashcards.map(f => ({
        question: f.question,
        answer: f.answer,
        type: 'flashcard',
        feedback: null,
        source: 'flashcards',
        metadata: {
          ...f.metadata,
          isFlashcard: true,
          isExamQuestion: false,
          isQuizQuestion: false,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          tags: f.metadata?.tags || []
        }
      })),
      ...validQuizQuestions.map(q => ({
        type: 'quiz',
        feedback: null,
        source: 'quiz',
        quiz_questions: {
          query_text: q.query_text || q.question,
          answer_choices: q.answer_choices || [],
          correct_choice: q.correct_choice || '',
          difficulty_level: q.difficulty_level || 'medium',
          justification: q.justification || ''
        },
        metadata: {
          isFlashcard: false,
          isExamQuestion: false,
          isQuizQuestion: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          tags: q.metadata?.tags || []
        }
      }))
    ];

    console.log('📝 Domande da salvare:', {
      totale: allQuestions.length,
      exam: allQuestions.filter(q => q.type === 'exam').length,
      flashcards: allQuestions.filter(q => q.type === 'flashcard').length,
      quiz: allQuestions.filter(q => q.type === 'quiz').length,
      tags: allQuestions.map(q => q.metadata?.tags?.length || 0)
    });

    // Prepare user stats update data once
    const userStatsRef = doc(firestore, 'userStats', auth.currentUser.uid);
    const userStatsData = {
      email: userEmail,
      totalFeedbackGiven: 0,
      feedbackPositive: 0,
      feedbackNegative: 0,
      lastFeedbackDate: serverTimestamp(),
    };

    // Process questions in chunks for better performance
    for (let i = 0; i < allQuestions.length; i++) {
      if (operationsCount >= BATCH_SIZE) {
        batches.push(currentBatch);
        currentBatch = writeBatch(firestore);
        operationsCount = 0;
      }

      const qa = allQuestions[i];
      const questionRef = doc(collection(firestore, 'questions'));

      // Prepare question data efficiently
      const questionData = {
        ...qa,
        documentID: documentId,
        uploadDate: serverTimestamp(),
        lastModified: serverTimestamp(),
      };

      // Add feedback only if it exists and is valid
      if (isValidFeedback(qa.feedback)) {
        const normalizedFeedback = normalizeFeedback(qa.feedback);
        questionData.feedback = normalizedFeedback;
        questionData.flaggedWrong = !normalizedFeedback.isHelpful;
        
        // Update user stats
        userStatsData.totalFeedbackGiven++;
        if (normalizedFeedback.isHelpful) {
          userStatsData.feedbackPositive++;
        } else {
          userStatsData.feedbackNegative++;
        }
        userStatsUpdated = true;
        totalFeedbackCount++;

        console.log(`📝 Feedback salvato per ${qa.type}:`, {
          feedback: normalizedFeedback,
          index: i
        });
      } else {
        // Initialize feedback fields if no feedback
        questionData.feedback = {
          isHelpful: null,
          timestamp: null,
          userId: null
        };
        questionData.flaggedWrong = false;
      }

      currentBatch.set(questionRef, questionData);
      operationsCount++;
    }

    // Add accumulated user stats update in the last batch only if we have valid feedback
    if (userStatsUpdated && userStatsData.totalFeedbackGiven > 0) {
      if (operationsCount >= BATCH_SIZE) {
        batches.push(currentBatch);
        currentBatch = writeBatch(firestore);
        operationsCount = 0;
      }
      
      currentBatch.set(userStatsRef, {
        email: userStatsData.email,
        totalFeedbackGiven: increment(userStatsData.totalFeedbackGiven),
        feedbackPositive: increment(userStatsData.feedbackPositive),
        feedbackNegative: increment(userStatsData.feedbackNegative),
        lastFeedbackDate: userStatsData.lastFeedbackDate,
        totalDocumentsWithFeedback: increment(1)
      }, { merge: true });
      
      operationsCount++;
    }

    if (operationsCount > 0) {
      batches.push(currentBatch);
    }

    // Execute batches with progress tracking
    const totalBatches = batches.length;
    for (let i = 0; i < totalBatches; i++) {
      await batches[i].commit();
      const processedQuestions = Math.min((i + 1) * BATCH_SIZE, allQuestions.length);
      console.log(`✅ Batch ${i + 1}/${totalBatches} completato (${processedQuestions}/${allQuestions.length} domande)`, {
        examQuestions: allQuestions.filter(q => q.type === 'exam').length,
        flashcards: allQuestions.filter(q => q.type === 'flashcard').length,
        feedback: totalFeedbackCount
      });
    }

    // Log final feedback stats
    console.log('📊 Statistiche feedback finali:', {
      totale: totalFeedbackCount,
      positivi: userStatsData.feedbackPositive,
      negativi: userStatsData.feedbackNegative
    });

    return {
      success: true,
      totalSaved: allQuestions.length,
      examQuestions: allQuestions.filter(q => q.type === 'exam').length,
      flashcards: allQuestions.filter(q => q.type === 'flashcard').length,
      feedback: totalFeedbackCount
    };
  } catch (error) {
    console.error('❌ Errore nel salvataggio del batch:', error);
    throw error;
  }
};

// Azione principale per salvare i documenti
export const setDocument = (documents, feedbackStates = []) => {
  return async (dispatch) => {
    try {
      const userId = auth.currentUser?.uid;
      const userEmail = auth.currentUser?.email;
      const documentsArray = [];

      for (const document of documents) {
        // Estrai le domande d'esame
        const examQuestions = document.examExtractedQA?.[0]?.qa_pairs || [];

        // Estrai i tags dal documento
        const documentTags = document.tags || [];

        // Estrai le flashcard dal formato corretto
        let flashcards = [];
        if (document.qa_response?.flashcards) {
          // Nuovo formato
          flashcards = document.qa_response.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer,
            type: 'flashcard',
            metadata: {
              isFlashcard: true,
              tags: documentTags
            }
          }));
        } else if (document.rawFlashcards?.flashcards) {
          // Formato di backup
          flashcards = document.rawFlashcards.flashcards.map(card => ({
            question: card.card_question,
            answer: card.card_answer,
            type: 'flashcard',
            metadata: {
              isFlashcard: true,
              tags: documentTags
            }
          }));
        }

        console.log('📝 Raw flashcards:', flashcards);
        console.log('✅ Filtered flashcards:', flashcards);
        
        const quizQuestions = document.quiz_questions || [];

        // Log dei dati raw per debug
        console.log('🔍 Raw document data:', {
          examExtractedQA: document.examExtractedQA,
          rawFlashcards: document.rawFlashcards,
          qa_response: document.qa_response,
          rawExamData: document.rawExamData,
          quiz_questions: document.quiz_questions,
          tags: documentTags,
          counts: {
            questions: examQuestions.length,
            flashcards: flashcards.length,
            quizzes: quizQuestions.length
          }
        });

        if (!examQuestions.length && !flashcards.length && !quizQuestions.length) {
          throw new Error('Non ci sono domande, flashcard o quiz da salvare');
        }

        // Filtra solo i feedback validi
        const validFeedbacks = feedbackStates.filter(f => f?.isHelpful !== null);
        const validFeedbackCount = validFeedbacks.length;

        console.log('📊 Feedback validi:', {
          totale: feedbackStates.length,
          validi: validFeedbackCount,
          feedbackStates: validFeedbacks.map(f => ({
            isValid: true,
            value: f
          }))
        });

        // Define main document data
        const documentData = {
          title: document.title,
          type: document.type,
          text: document.text,
          uploadDate: serverTimestamp(),
          lastModified: serverTimestamp(),
          uploadedBy: userId,
          userEmail: userEmail,
          isRemoved: false,
          tags: document.tags || [],
          summary: {
            abstract: document.summary?.abstract || '',
            sections: document.summary?.sections || [],
            hasAbstract: !!document.summary?.abstract,
            sectionsCount: document.summary?.sections?.length || 0
          },
          metadata: {
            questionsCount: examQuestions.length,
            flashcardsCount: flashcards.length,
            quizCount: quizQuestions.length,
            feedbackCount: validFeedbackCount,
            sectionsCount: document.summary?.sections?.length || 0,
            hasAbstract: !!document.summary?.abstract
          }
        };

        // Save the document
        const docRef = await addDoc(collection(firestore, 'documents'), documentData);
        const documentId = docRef.id;

        console.log('💾 Salvataggio domande:', {
          examQuestions: examQuestions.length,
          flashcards: flashcards.length,
          total: examQuestions.length + flashcards.length,
          feedback: validFeedbackCount
        });

        // Save QA pairs with correct separation
        const saveResult = await saveQABatch(
          examQuestions, 
          validFeedbacks, 
          documentId, 
          userEmail, 
          flashcards, // Pass the correctly formatted flashcards
          quizQuestions
        );

        // Update document with actual feedback count from batch save
        if (saveResult.feedback > 0) {
          await updateDoc(docRef, {
            'metadata.feedbackCount': saveResult.feedback
          });
          console.log('📝 Aggiornamento feedback count:', {
            salvati: saveResult.feedback,
            validi: validFeedbackCount
          });
        }

        documentsArray.push({ ...documentData, id: documentId });
      }

      dispatch({ type: SET_DOCUMENT, payload: documentsArray });
      return documentsArray;
    } catch (error) {
      console.error('❌ Errore nel salvataggio:', error);
      throw error;
    }
  };
};

// Action to change document title
export const changeDocumentTitle = (documentId, newTitle) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          title: newTitle,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTitle(documentId, newTitle));
    } catch (error) {
      console.error('❌ Errore modifica titolo:', error);
      throw error;
    }
  };
};

// Action to change tags for a specific document
export const changeDocumentTags = (documentId, newTags) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          tags: newTags,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentTags(documentId, newTags));
    } catch (error) {
      console.error('❌ Error updating document tags:', error);
      throw error;
    }
  };
};

// Action to add summary for a specific document
export const addDocumentSummary = (documentId, summary) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      await setDoc(
        documentRef,
        {
          summary: summary,
          lastModified: serverTimestamp(),
        },
        { merge: true }
      );
      dispatch(setDocumentSummary(summary));
    } catch (error) {
      console.error("Error updating document summary:", error.message);
      throw error;
    }
  };
};

// Action to delete a document
export const deleteDocument = (documentId) => {
  return async (dispatch) => {
    try {
      const documentRef = doc(firestore, 'documents', documentId);
      
      // Update document with isRemoved flag instead of deleting
      await updateDoc(documentRef, {
        isRemoved: true,
        lastModified: serverTimestamp()
      });
      
      // Remove from Redux state
      dispatch({ type: DELETE_DOCUMENT, payload: documentId });
      
      toast.success('Documento eliminato con successo');
    } catch (error) {
      console.error('❌ Errore eliminazione documento:', error);
      toast.error('Errore durante l\'eliminazione del documento');
      throw error;
    }
  };
};