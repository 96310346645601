import React from 'react';
import './ExamProgress.scss';

const ExamProgress = ({ isExamStarted, qa, currentQA }) => {
  const totalQuestions = qa.length;
  const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
  const validCurrentIndex = isNaN(currentQA) ? 0 : currentQA; 
  const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 1) / validTotalQuestions) * 100 : 0;  
  
  return (
    <div className="Exam-status">
      <span
        className={`Exam-status__question-status ${isExamStarted ? 'exam-started' : 'exam-not-started'}`}
      >
        Domanda {validCurrentIndex + 1}/{qa.length}
      </span>
      <div className="Exam-status__progress-bar-container">
        <div
          className={`Exam-status__progress-bar-container__progress-bar ${isExamStarted ? 'exam-started' : 'exam-not-started'}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ExamProgress;