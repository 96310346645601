import PriceCard from "../../../containers/priceCard/priceCard";
import "./universityPlan.scss";

export const UniversityPlan = ({ isAnnual, setIsAnnual, isLoading, isPremium, handlePremiumSubscription }) => {
  const monthlyEquivalent = "6.67";
  
  return (
    <div className={isPremium ? 'UniversityPlan__premium' : null}>
      <PriceCard
        isDisabled={isLoading}
        title="Piano Annuale"
        originalCost={"14.99"}
        cost={monthlyEquivalent}
        totalCost={"79.99"}
        isAnnual={isAnnual}
        setIsAnnual={setIsAnnual}
        accessInfo={'Accesso per 12 mesi completi'}
        options={[
          `Risparmia ${(14.99 - parseFloat(monthlyEquivalent)).toFixed(2)}€ al mese (-56% sul prezzo originale)`,
          "Documenti, flashcards, riassunti, domande di ripetizione e a risposta multipla illimitati",
          "Simulazioni AI per ogni esame universitario",
          "Supporto dedicato per le materie universitarie",
          "Accesso a tutte le funzionalità future",
          "Assistenza prioritaria 24/7"
        ]}
        buttonText={isPremium ? "Gestisci abbonamento" : "Inizia Piano Annuale"}
        onClick={handlePremiumSubscription}
        advised={!isPremium}
        finalText={"Accesso ininterrotto tutto l'anno"}
      />
    </div>
  )
};
