import React from "react";
import "./hamburgerMenu.scss";
import PropTypes from "prop-types";
import useShowHamburgerMenu from "./hook/useShowHamburgerMenu";

import { RiMenu3Line } from "react-icons/ri";
import Portal from "@containers/portal/portal";
/* import useGetOnboardingState from "../../../../pages/uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState"; */

const HamburgerMenu = ({ children }) => {
  const { showHamburgerMenu, openHamburgerMenu, childrenWithProps } = useShowHamburgerMenu(children);
  /* const { updateCurrentStep } = useGetOnboardingState(); */
  
  const handleClick = () => { 
    /* updateCurrentStep(13); */
    openHamburgerMenu();
  };

  return (
    <div className="HamburgerMenu">
      <div className="HamburgerMenu__button" onClick={handleClick}>
        <RiMenu3Line className="HamburgerMenu__button-icon" />
      </div>
      {showHamburgerMenu && (
        <Portal portalKey="hamburger-1" target={document.getElementById("hamburgerMenu")}>
          {childrenWithProps}
        </Portal>
      )}
    </div>
  );
};

// HamburgerMenu propTypes
HamburgerMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HamburgerMenu;
