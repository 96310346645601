// Color definitions for mainSubject
export const subjectColors = {
  // Materie Scolastiche
  psicologia: { backgroundColor: '#fffbeb', color: '#854d0e', borderColor: '#854d0e' },
  storia: { backgroundColor: '#fef2f2', color: '#7f1d1d', borderColor: '#7f1d1d' },
  matematica: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  aritmetica: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  geometria: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  economia: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  algebra: { backgroundColor: '#eff6ff', color: '#1e3a8a', borderColor: '#1e3a8a' },
  filosofia: { backgroundColor: '#f5f3ff', color: '#4c1d95', borderColor:'#4c1d95' },
  fisica: { backgroundColor: '#ecfdf5', color: '#065f46', borderColor: '#065f46' },
  chimica: { backgroundColor: '#f0fdfa', color: '#134e4a', borderColor: '#134e4a' },
  arte: { backgroundColor: '#fff1f2', color: '#9f1239', borderColor: '#9f1239' },
  letteratura: { backgroundColor: '#fff7ed', color: '#9a3412', borderColor: '#9a3412' },
  biologia: { backgroundColor: '#f0fdf4', color: '#14532d', borderColor: '#14532d' },
  scienze: { backgroundColor: '#f0fdf4', color: '#14532d', borderColor: '#14532d' },
  programmazione: { backgroundColor: '#ecfeff', color: '#155e75', borderColor: '#155e75' },
  algoritmi: { backgroundColor: '#ecfeff', color: '#155e75', borderColor: '#155e75' },
  latino: { backgroundColor: '#fff7ed', color: '#7c2d12', borderColor: '#7c2d12' },
  greco: { backgroundColor: '#fdf4ff', color: '#86198f', borderColor: '#86198f' },
  inglese: { backgroundColor: '#f0f9ff', color: '#075985', borderColor: '#075985' },
  francese: { backgroundColor: '#fef2f2', color: '#991b1b', borderColor: '#991b1b' },
  tedesco: { backgroundColor: '#fefce8', color: '#854d0e', borderColor: '#854d0e' },
  spagnolo: { backgroundColor: '#fff7ed', color: '#9a3412', borderColor: '#9a3412' },
  geografia: { backgroundColor: '#f0fdfa', color: '#115e59', borderColor: '#115e59' },
  informatica: { backgroundColor: '#f8fafc', color: '#0f172a', borderColor: '#0f172a' },
  diritto: { backgroundColor: '#f5f3ff', color: '#5b21b6', borderColor: '#5b21b6' },
  musica: { backgroundColor: '#fdf2f8', color: '#831843', borderColor: '#831843' },
  educazioneFisica: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  religione: { backgroundColor: '#fffbeb', color: '#92400e', borderColor: '#92400e' },

  // Facoltà Universitarie
  medicina: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  chirurgia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  farmacia: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  odontoiatria: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  veterinaria: { backgroundColor: '#ffedd5', color: '#9a3412', borderColor: '#9a3412' },
  ingegneria: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  architettura: { backgroundColor: '#fef3c7', color: '#92400e', borderColor: '#92400e' },
  giurisprudenza: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  economia_commercio: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  scienze_politiche: { backgroundColor: '#e0e7ff', color: '#3730a3', borderColor: '#3730a3' },
  lettere: { backgroundColor: '#fff1f2', color: '#9f1239', borderColor: '#9f1239' },
  lingue: { backgroundColor: '#f0f9ff', color: '#0c4a6e', borderColor: '#0c4a6e' },
  psicologia_uni: { backgroundColor: '#fdf4ff', color: '#86198f', borderColor: '#86198f' },
  scienze_formazione: { backgroundColor: '#ffe4e6', color: '#be123c', borderColor: '#be123c' },
  
  // Nuove Facoltà Universitarie
  ingegneria_informatica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  ingegneria_meccanica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  ingegneria_elettronica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  ingegneria_civile: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  ingegneria_gestionale: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  biotecnologie: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  scienze_biologiche: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  scienze_naturali: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  scienze_ambientali: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  fisica_uni: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  matematica_uni: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  chimica_uni: { backgroundColor: '#f0fdfa', color: '#134e4a', borderColor: '#134e4a' },
  geologia: { backgroundColor: '#fff7ed', color: '#9a3412', borderColor: '#9a3412' },
  astronomia: { backgroundColor: '#faf5ff', color: '#6b21a8', borderColor: '#6b21a8' },
  filosofia_uni: { backgroundColor: '#f5f3ff', color: '#4c1d95', borderColor: '#4c1d95' },
  storia_uni: { backgroundColor: '#fef2f2', color: '#7f1d1d', borderColor: '#7f1d1d' },
  archeologia: { backgroundColor: '#fff7ed', color: '#9a3412', borderColor: '#9a3412' },
  beni_culturali: { backgroundColor: '#fff1f2', color: '#9f1239', borderColor: '#9f1239' },
  
  // Materie Specialistiche Universitarie
  anatomia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  fisiologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  patologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  biochimica: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  microbiologia: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  farmacologia: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  diritto_privato: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  diritto_pubblico: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  diritto_penale: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  analisi_matematica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  fisica_tecnica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  scienza_costruzioni: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  economia_aziendale: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  marketing: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  statistica: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  pedagogia: { backgroundColor: '#ffe4e6', color: '#be123c', borderColor: '#be123c' },
  sociologia: { backgroundColor: '#e0e7ff', color: '#3730a3', borderColor: '#3730a3' },
  antropologia: { backgroundColor: '#e0e7ff', color: '#3730a3', borderColor: '#3730a3' },

  // Nuove Materie Specialistiche
  neurologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  cardiologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  pediatria: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  psichiatria: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  radiologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  endocrinologia: { backgroundColor: '#fee2e2', color: '#991b1b', borderColor: '#991b1b' },
  immunologia: { backgroundColor: '#fae8ff', color: '#86198f', borderColor: '#86198f' },
  genetica: { backgroundColor: '#f0fdf4', color: '#166534', borderColor: '#166534' },
  bioinformatica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  robotica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  telecomunicazioni: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  automazione: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  termodinamica: { backgroundColor: '#dbeafe', color: '#1e40af', borderColor: '#1e40af' },
  urbanistica: { backgroundColor: '#fef3c7', color: '#92400e', borderColor: '#92400e' },
  restauro: { backgroundColor: '#fef3c7', color: '#92400e', borderColor: '#92400e' },
  diritto_commerciale: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  diritto_internazionale: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  diritto_costituzionale: { backgroundColor: '#f3e8ff', color: '#6b21a8', borderColor: '#6b21a8' },
  finanza: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  contabilita: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  management: { backgroundColor: '#dcfce7', color: '#166534', borderColor: '#166534' },
  linguistica: { backgroundColor: '#f0f9ff', color: '#0c4a6e', borderColor: '#0c4a6e' },
  glottologia: { backgroundColor: '#f0f9ff', color: '#0c4a6e', borderColor: '#0c4a6e' },
  filologia: { backgroundColor: '#fff1f2', color: '#9f1239', borderColor: '#9f1239' },

  default: { backgroundColor: '#F0F9F2', color: '#1D5F39', borderColor: '#54976c' } 
};

// Function to get color for a tag
export const getTagColor = (tagIndex, tag) => {
  switch (tagIndex) {
    case 0: 
    return subjectColors[tag] || subjectColors.default; 
    case 1:
      return { backgroundColor: '#f8fafc', color: '#475569', transition: 'background-color 0.2s' }; // slate
    case 2:
      return { backgroundColor: '#e0e0e0', color: 'black', borderColor: '#a7a2a2', transition: 'background-color 0.2s' }; // green
    default:
      return { backgroundColor: '#f3f4f6', color: '#374151', transition: 'background-color 0.2s' }; // default gray
  }
};