import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";

const useGetQuestions = (idSelectedDocument, isLegacyFormat = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const getQuizQuestions = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      const questionsData = await getQuestions(idSelectedDocument);
      
      // Prima filtriamo per tipo di domanda
      const filteredQuestions = questionsData.docs
        .map(doc => doc.data())
        .filter(qa => {
          // Se è un documento vecchio (legacy), mostra tutte le domande
          if (isLegacyFormat) {
            if (!qa?.metadata) return true;
            return qa.metadata.isExamQuestion === true;
          }
          
          // Per i nuovi documenti, mostra solo le domande di quiz
          return qa?.metadata?.isQuizQuestion === true;
        });

      // Poi rimuoviamo i duplicati usando un Set per tenere traccia delle domande già viste
      const seenQuestions = new Set();
      const uniqueQuestions = filteredQuestions.filter(qa => {
        // Verifichiamo che la domanda esista e sia una stringa
        if (!qa?.question || typeof qa.question !== 'string') return false;
        
        // Normalizziamo la domanda (rimuoviamo spazi extra e rendiamo lowercase)
        const normalizedQuestion = qa.question.trim().toLowerCase();
        
        // Se la domanda non è stata vista, la aggiungiamo al set e la manteniamo
        if (!seenQuestions.has(normalizedQuestion)) {
          seenQuestions.add(normalizedQuestion);
          return true;
        }
        return false;
      });
      
      // Ordiniamo le domande alfabeticamente
      const sortedQuestions = uniqueQuestions.sort((a, b) => 
        a.question.localeCompare(b.question)
      );

      setQuestions(sortedQuestions);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Errore nel recupero delle domande");
      setIsLoading(false);
    }
  }, [idSelectedDocument, isLegacyFormat]);

  useEffect(() => {
    getQuizQuestions();
  }, [getQuizQuestions]);

  return { isLoading, questions };
};

export default useGetQuestions; 