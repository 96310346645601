import { getDocument } from 'pdfjs-dist';
import mammoth from 'mammoth/mammoth.browser';
import JSZip from 'jszip';
import { processImage } from "@services/openaiApi";
import { toast } from 'react-toastify';
import { transcribeAudio } from '../../services/openaiApi';

// convert ArrayBuffer to Base64 string
export const arrayBufferToBase64 = (arrayBuffer) => {
  const bytes = new Uint8Array(arrayBuffer);
  let binaryString = '';
  bytes.forEach((byte) => binaryString += String.fromCharCode(byte));
  return btoa(binaryString);
};

// Extract text from image
export const extractTextFromImage = async image => {
  try {
    const extractedText = await processImage(image);

    if (!extractedText) {
      throw new Error('Nessun testo estratto dall\'immagine, riprova');
    }

    return extractedText;
  } catch (error) {
    console.error('Error processing image:', error);
    return { message: error.message || 'Errore durante l\'elaborazione dell\'immagine' };
  }
};

// Extract text from PDF
export const extractTextFromPdf = async (base64Data) => {
  try {
    const pdfData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    const pdf = await getDocument({ data: pdfData }).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const strings = content.items.map(item => item.str);
      text += strings.join(' ');
    }

    if (!text) throw new Error('Nessun testo estratto dal pdf, riprova');
    
    return text;
  } catch (error) {
    console.error('Error processing pdf:', error);
    console.log('Attempting to extract text from PDF images...');
    
    // Fallback to extracting text from images in the PDF
    return await extractTextFromPdfImages(base64Data);
  }
};

// Extract text from PDF images
export const extractTextFromPdfImages = async (base64Data) => {
  try {
    const pdfData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
    const pdf = await getDocument({ data: pdfData }).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 2.0 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({ canvasContext: context, viewport: viewport }).promise;

      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      if (!blob) {
        throw new Error('Failed to create blob from canvas');
      }
      const extractedText = await extractTextFromImage(blob);
      text += extractedText + ' ';
    }

    return text.trim();
  } catch (error) {
    console.error('Error extracting text from PDF images:', error);
    return { message: error.message || 'Errore durante l\'elaborazione delle immagini del PDF' };
  }
};

// Extract text from Word
export const extractTextFromWord = async (base64Data) => {
  try {
    const binaryString = atob(base64Data);
    const arrayBuffer = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      arrayBuffer[i] = binaryString.charCodeAt(i);
    }
    const { value } = await mammoth.extractRawText({ arrayBuffer });

    if (!value) throw new Error('Nessun testo estratto dal documento word, riprova');

    return value;
  } catch (error) {
    console.error('Error processing word:', error);
    return { message: error.message };
  }
};

// Extract text from PPTX
export const extractTextFromPpt = async (base64Data) => {
  try {
    const cleanBase64 = base64Data.replace(/[^A-Za-z0-9+/=]/g, '');
    const binaryString = atob(cleanBase64);
    const zip = await JSZip.loadAsync(binaryString);

    const slidePromises = [];
    zip.folder('ppt/slides').forEach((relativePath, file) => {
      slidePromises.push(file.async('string'));
    });

    const slides = await Promise.all(slidePromises);
    let text = '';

    slides.forEach(slide => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(slide, 'application/xml');
      const textElements = xmlDoc.getElementsByTagName('a:t');

      for (let i = 0; i < textElements.length; i++) {
        text += textElements[i].textContent + ' ';
      }
    });

    if (!text) throw new Error('Nessun testo estratto dal documento ppt, riprova');

    return text;
  } catch (error) {
    console.error('Error processing ppt:', error);
    return { message: error.message };
  }
};

// Convert a base64 string to a Blob
export const base64ToBlob = (base64, mimeType = 'audio/mp4') => {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = Array.from(slice, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

// Get extracted text
export const getExtractedText = async (typeFile, base64Data) => {
  switch (typeFile.toLowerCase()) {
    case 'audio/mpeg': 
    case 'video/wav': 
    case 'audio/wav': 
    case 'audio/mp3': 
    case 'video/mp3': 
    case 'audio/mp4': 
    case 'video/mp4': {
      const audioBlob = base64ToBlob(base64Data, typeFile);
      const generatedText = await transcribeAudio(audioBlob);
      return generatedText.text;
    } 
    case 'application/pdf': {
      return await extractTextFromPdf(base64Data);
    }
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return await extractTextFromWord(base64Data);
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return await extractTextFromPpt(base64Data);
    }
    case 'text/plain': {
      return atob(base64Data);  // Handle manual text input as plain text
    }
    default:
      return null;
  }
};

// Process file and return extracted text or error
export const processFile = async file => {
  if (file.type === 'text/plain') {
    const fileContent = await file.text();
    if (!fileContent || fileContent.length < 300) {
      const errorMessage = `Inserire un documento maggiore di 300 caratteri. Lunghezza documento attuale ${fileContent.length}.`;
      toast.error(errorMessage);
    }
    return {
      name: file.name,
      type: file.type,
      extractedText: fileContent,
    };
  }

  // Rilevamento specifico per iPad
  const isIPad = /iPad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if ((isIPad || /iPhone|iPod/.test(navigator.userAgent)) && isSafari) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = async e => {
        try {
          let extractedText;
          
          if (file.type.startsWith('image/')) {
            extractedText = await extractTextFromImage(file);
          } else if (file.type === 'application/pdf') {
            try {
              // Creiamo un buffer più piccolo per gestire meglio la memoria
              const buffer = e.target.result;
              const blob = new Blob([buffer], { type: 'application/pdf' });
              
              // Creiamo un URL temporaneo
              const url = URL.createObjectURL(blob);
              
              try {
                // Configurazione specifica per iPad
                const loadingTask = getDocument({
                  url: url,
                  cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/cmaps/',
                  cMapPacked: true,
                  standardFontDataUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/standard_fonts/',
                  disableAutoFetch: true,
                  disableStream: true,
                  disableRange: true
                });

                const pdf = await loadingTask.promise;
                let text = '';
                
                // Processiamo una pagina alla volta per gestire meglio la memoria
                for (let i = 1; i <= pdf.numPages; i++) {
                  const page = await pdf.getPage(i);
                  const content = await page.getTextContent();
                  text += content.items.map(item => item.str).join(' ') + ' ';
                  
                  // Liberiamo la memoria della pagina
                  await page.cleanup();
                }
                
                extractedText = text.trim();
              } finally {
                URL.revokeObjectURL(url);
              }
            } catch (pdfError) {
              console.error('Error processing PDF:', pdfError);
              // Se fallisce l'estrazione diretta, proviamo con le immagini
              extractedText = await extractTextFromPdfImages(arrayBufferToBase64(e.target.result));
            }
          } else {
            const base64String = arrayBufferToBase64(e.target.result);
            extractedText = await getExtractedText(file.type, base64String);
          }

          if (!extractedText || typeof extractedText === 'object') {
            return reject({
              name: file.name,
              type: file.type,
              extractedText: '',
              errorMessage: extractedText?.message ?? 'Nessun testo estratto',
            });
          }

          if (extractedText.length < 300) {
            toast.error('Caricare file con più testo');
            return reject({
              name: file.name,
              type: file.type,
              extractedText: '',
              errorMessage: `Inserire un documento maggiore di 300 caratteri. Lunghezza documento attuale ${extractedText.length}.`,
            });
          }

          resolve({
            name: file.name,
            type: file.type,
            extractedText: extractedText,
          });
        } catch (error) {
          console.error('Error processing file:', error);
          reject({
            name: file.name,
            type: file.type,
            extractedText: '',
            errorMessage: error.message || 'Errore durante l\'elaborazione del file',
          });
        }
      };

      reader.onerror = error => {
        console.error('Error reading file:', error);
        reject({
          name: file.name,
          type: file.type,
          extractedText: '',
          errorMessage: 'Errore durante la lettura del file',
        });
      };

      // Leggiamo il file come ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  // Per altri browser, usa il metodo originale
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async e => {
      try {
        const base64String = arrayBufferToBase64(e.target.result);
        let extractedText;
        
        if (file.type.startsWith('image/')) {
          extractedText = await extractTextFromImage(file);
        } else {
          extractedText = await getExtractedText(file.type, base64String);
        }

        if (!extractedText || typeof extractedText === 'object') {
          return reject({
            name: file.name,
            type: file.type,
            extractedText: '',
            errorMessage: extractedText?.message ?? 'Nessun testo estratto',
          });
        }

        if (extractedText.length < 300) {
          toast.error('Caricare file con più testo');
          return reject({
            name: file.name,
            type: file.type,
            extractedText: '',
            errorMessage: `Inserire un documento maggiore di 300 caratteri. Lunghezza documento attuale ${extractedText.length}.`,
          });
        }

        resolve({
          name: file.name,
          type: file.type,
          extractedText: extractedText,
        });
      } catch (error) {
        console.error('Error processing file:', error);
        reject({
          name: file.name,
          type: file.type,
          extractedText: '',
          errorMessage: error.message || 'Errore durante l\'elaborazione del file',
        });
      }
    };
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

export default processFile;