import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FiTrendingUp, FiClock, FiStar, FiCheck } from 'react-icons/fi';

const StatsContainer = styled(motion.section)`
  padding: 5rem 2rem;
  background-color: var(--grayscale-color-100);
  border-radius: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.01);
`;

const MainTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 0.8rem;
  color: #1f2937;
  font-weight: 800;
  letter-spacing: -0.02em;
  background: linear-gradient(135deg, #1f2937 0%, #4b5563 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #4b5563;
  margin-bottom: 3.2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  letter-spacing: -0.01em;
`;

const StatsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.6rem;
    padding: 0;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StatsCard = styled(motion.div)`
  background: linear-gradient(145deg, #ffffff 0%, #f8fafc 100%);
  border-radius: 16px;
  padding: 2.8rem 2.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.03);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

  @media (max-width: 768px) {
    padding: 2.4rem 1.8rem;
  }

  .card-icon {
    color: ${props => props.$iconColor};
    margin-bottom: 1.5rem;
    opacity: 0.9;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.05);

    .card-icon {
      transform: scale(1.1);
    }
  }
`;

const MainStat = styled.div`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  line-height: 1;
  background: ${props => `linear-gradient(135deg, ${props.$color} 0%, ${props.$colorLight} 100%)`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.p`
  font-size: 1.4rem;
  color: #4b5563;
  margin-bottom: 2rem;
  font-weight: 500;
`;

const StatsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const StatItem = styled(motion.li)`
  display: flex;
  align-items: flex-start;
  gap: 1.2rem;
  font-size: 1.4rem;
  color: #374151;
  padding: 1rem 0;
  transition: all 0.3s ease;
  line-height: 1.4;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  svg {
    color: ${props => props.color};
    flex-shrink: 0;
    opacity: 0.9;
    margin-top: 0.2rem;
  }
`;

const statsData = {
  risultatiAccademici: {
    mainStat: "85%",
    mainLabel: "Successo agli Esami",
    color: "var(--primary-color-500)",
    colorLight: "var(--primary-color-300)",
    icon: FiTrendingUp,
    stats: [
      "Media 27/30 negli esami orali veri",
      "Preparazione dai 3 ai 10 giorni",
      "Metodo testato su migliaia di esami"
    ]
  },
  funzionalitaCore: {
    mainStat: "AI",
    mainLabel: "Powered",
    color: "var(--primary-color-500)",
    colorLight: "var(--primary-color-300)",
    icon: FiClock,
    stats: [
      "Riassunti e flashcard istantanei",
      "Ripasso velocizzato sui materiali",
      "Ripetizioni con valutazione dettagliata"
    ]
  },
  comunitaStudenti: {
    mainStat: "6.5k+",
    mainLabel: "Studenti Attivi",
    color: "var(--primary-color-500)",
    colorLight: "var(--primary-color-300)",
    icon: FiStar,
    stats: [
      "Studenti universitari e liceali",
      "Tutte le materie e facoltà",
      "Feedback medio 4.8/5 stelle"
    ]
  }
};

const StudierAIStats = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StatsContainer 
      id="studierai-stats"
      initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: isMobile ? 0 : 0.6 }}
    >
      <MainTitle
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.2 }}
      >
        Risultati Verificati
      </MainTitle>
      <Subtitle
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.3 }}
      >
        Preparati al successo con metodi testati da migliaia di studenti
      </Subtitle>

      <StatsSection>
        {Object.entries(statsData).map(([key, data], index) => (
          <StatsCard
            key={key}
            initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: isMobile ? 0 : 0.5, delay: isMobile ? 0 : 0.2 + (index * 0.1) }}
            $iconColor={data.color}
          >
            <data.icon size={32} className="card-icon" />
            <MainStat $color={data.color} $colorLight={data.colorLight}>
              {data.mainStat}
            </MainStat>
            <StatLabel>{data.mainLabel}</StatLabel>
            <StatsList>
              {data.stats.map((stat, i) => (
                <StatItem
                  key={i}
                  color={data.color}
                  initial={isMobile ? { opacity: 1, x: 0 } : { opacity: 0, x: -10 }}
                  whileInView={isMobile ? { opacity: 1, x: 0 } : { opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: isMobile ? 0 : 0.3, delay: isMobile ? 0 : 0.4 + (i * 0.1) }}
                >
                  <FiCheck size={20} />
                  {stat}
                </StatItem>
              ))}
            </StatsList>
          </StatsCard>
        ))}
      </StatsSection>
    </StatsContainer>
  );
};

export default StudierAIStats;
