import React from "react";
import "./assistanceForm.scss";
import useAssistance from "./hook/useAssistance";

import Field from "@ui/field/field";
import Input from "@ui/input/input";
import Textarea from "@ui/textarea/textarea";
import Button from "@ui/button/button";

const AssistanceForm = () => {
  const { register, errors, isSubmitting, handleSubmit, sendEmailHandler } = useAssistance();

  return (
    <form data-testid="AssistanceForm" className="AssistanceForm" onSubmit={handleSubmit(data => sendEmailHandler(data))} noValidate>
      <Field className="AssistanceForm__field">
        <Field.Label label="Nome e Cognome " htmlFor="fullName" required />
        <Input id="fullName" name="fullName" type="text" placeholder="Mario Rossi" {...register("fullName")} />
        {errors?.fullName && <Field.Error error={errors.fullName.message} />}
      </Field>
      <Field className="AssistanceForm__field">
        <Field.Label label="Email " htmlFor="email" required />
        <Input id="email" name="email" type="email" placeholder="Indirizzo email" {...register("email")} />
        {errors?.email && <Field.Error error={errors.email.message} />}
      </Field>
      <Field className="AssistanceForm__field">
        <Field.Label label="Descrivi il problema " htmlFor="message" required />
        <Textarea
          id="message"
          name="message"
          type="text"
          placeholder="Ho riscontrato un problema con il mio account..."
          {...register("message")}
        />
        {errors?.message && <Field.Error error={errors.message.message} />}
      </Field>
      <Button className="AssistanceForm__btn" type="submit" disabled={isSubmitting} variant="primary" size="md">
        Invia Email
      </Button>
    </form>
  );
};

export default AssistanceForm;
