import React from "react";
import Heading from "@ui/heading/heading";
import "./pricingFaq.scss";
import PricingAccordion from "./pricingAccordion/pricingAccordion";
import { motion } from "framer-motion";

const PricingFaq = () => {
  return (
    <section id="pricing-faq" className="PricingFaq">
      <div className="container">
        <div className="row justify-center g-3 g-lg-4">
          <div className="col-lg-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <Heading title="Domande Frequenti sui Piani" heading="h3" className="PricingFaq__title" />
              <p className="PricingFaq__subtitle">Trova risposte alle domande più comuni sui nostri piani e abbonamenti</p>
            </motion.div>
          </div>
          <motion.div 
            className="col-lg-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <PricingAccordion />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default PricingFaq; 