import React, { useState, useEffect, useCallback } from 'react';
import useChatHeaderNavigation from './hook/useChatHeaderNavigation';
import { IoArrowBackSharp } from "react-icons/io5";
import { Clock } from 'lucide-react';
import { BsSave } from "react-icons/bs";
import { toast } from "react-toastify"; 
import './examCardHeader.scss';

const ExamCardHeader = ({ 
  isExamStarted, 
  handleConfirmFinishExam, 
  saveExam, 
  isSaving,
  currentQa,
  isInProgress,
  isRecording, 
  audioRef, 
  aiIconState, 
  examState, 
  feedback, 
  setAiIconState,
  reviewMode
}) => {
  const [timeRemaining, setTimeRemaining] = useState(2400); // 2400 sec
  const { goBack, title } = useChatHeaderNavigation();

  // Prevent useless page reload
  useEffect(()=>{
    const unloadCallback = (event) => {      
        const e = event || window.event;

        e.preventDefault();
        if (e) {
          e.returnValue = ''
        }
        return '';
          
    };
    
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      //cleanup function
      window.removeEventListener("beforeunload", unloadCallback);
    }
    
  },[])

  useEffect(() => {
    if (isExamStarted) {
      const timerInterval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerInterval);
            handleConfirmFinishExam();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [isExamStarted, handleConfirmFinishExam]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleSaveExam = useCallback(async () => {
    try {
      if(examState === 'processing' || (aiIconState !== 'speaking' && feedback && aiIconState !== 'stopped')) return;

      if(audioRef.current) {
        audioRef.current.suspend();
      }

      await saveExam("in-progress", currentQa);
      toast.success("Esame salvato con successo!");
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      toast.error("Errore durante il salvataggio dell'esame. Riprova più tardi.");
    }
  }, [saveExam, audioRef, examState, aiIconState, feedback, currentQa]);

  const handleGoBack = useCallback(() => {
    if(examState === 'processing' || (aiIconState !== 'speaking' && feedback && aiIconState !== 'stopped')) return;

    if(audioRef.current) {
      audioRef.current.suspend();
      setAiIconState('stopped');
    }
    goBack();
  },[audioRef, goBack, examState, aiIconState, feedback, setAiIconState]);

  return (
    <header className="examcard-header">
      <div className='row-container'>
        <span className={`examCardHeader__actions-button ${examState === 'processing' || aiIconState === 'processing' || isRecording ? 'btn-disabled' : ''}`} onClick={handleGoBack}>
          <IoArrowBackSharp className={`goBack-icon ${isRecording || examState === 'processing' || aiIconState === 'processing' ? 'icon-disabled' : ''}`} />
        </span>
        {!isInProgress && (
          <span 
            className={`examCardHeader__actions__save-icon ${isSaving ? 'is-saving' : ''} ${examState === 'processing' || aiIconState === 'processing' || isRecording ? 'btn-disabled' : ''}`} 
            onClick={handleSaveExam}
            title="Salva esame"
          >
            <BsSave className="ChatCardHeader__actions-icon" />
          </span>
        )}
        <h1 className={`title ${title.length > 30 ? 'truncate-text' : ''}`}>
          {title}
        </h1>
      </div>
      {!reviewMode && (
        <div className="time-container">
          <Clock className={`clock-icon ${isExamStarted ? 'exam-started' : 'exam-not-started'}`} />
          <span className="time">{formatTime(timeRemaining)}</span>
        </div>
      )}
    </header>
  );
};

export default ExamCardHeader;