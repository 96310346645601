import React from 'react';
import PropTypes from 'prop-types';
import { Info } from 'lucide-react';
import { TbCheckbox } from "react-icons/tb";
import './MultipleChoice.scss';

const QuestionSummary = ({ questions }) => {
  // Calcola il conteggio per difficoltà
  const difficultyCount = questions.reduce((acc, q) => {
    acc[q.difficulty_level] = (acc[q.difficulty_level] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className="MultipleChoice__summary">
      <div className="MultipleChoice__summary-content">
        <div className="MultipleChoice__summary-icon">
          <TbCheckbox size={20} />
        </div>
        <div className="MultipleChoice__summary-info">
          <div className="MultipleChoice__summary-total">
            {questions.length} domande disponibili
          </div>
          <div className="MultipleChoice__summary-badges">
            {difficultyCount.easy > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--easy">
                {difficultyCount.easy} facili
              </span>
            )}
            {difficultyCount.medium > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--medium">
                {difficultyCount.medium} medie
              </span>
            )}
            {difficultyCount.hard > 0 && (
              <span className="MultipleChoice__summary-badge MultipleChoice__summary-badge--hard">
                {difficultyCount.hard} difficili
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MultipleChoice = ({ questions }) => {
  const getDifficultyLabel = (level) => {
    const labels = {
      easy: 'Facile',
      medium: 'Media',
      hard: 'Difficile',
      difficult: 'Difficile'
    };
    return labels[level] || level;
  };

  return (
    <div className="MultipleChoice">
      <QuestionSummary questions={questions} />

      {questions.map((question, index) => (
        <div key={index} className="MultipleChoice__container">
          <div className="MultipleChoice__header">
            <div className="MultipleChoice__question">
              <h4 className="MultipleChoice__text">
                <span className="MultipleChoice__number">{index + 1}.</span>
                {question.query_text}
              </h4>
            </div>
            <span className={`MultipleChoice__difficulty MultipleChoice__difficulty--${question.difficulty_level}`}>
              {getDifficultyLabel(question.difficulty_level)}
            </span>
          </div>

          <div className="MultipleChoice__options">
            {/* Risposta numero 1 */}
            <div 
                className={`MultipleChoice__option ${
                    '1' === question.correct_choice
                      ? 'MultipleChoice__option--correct'
                      : ''
                }`}
            >
                {question.answer_choices[1]}
            </div>
            
            {/* Risposta numero 2 */}
            <div 
                className={`MultipleChoice__option ${
                    '2' === question.correct_choice
                      ? 'MultipleChoice__option--correct'
                      : ''
                }`}
            >
                {question.answer_choices[2]}
            </div>
            
            {/* Risposta numero 3 */}
            <div 
                className={`MultipleChoice__option ${
                    '3' === question.correct_choice
                      ? 'MultipleChoice__option--correct'
                      : ''
                }`}
            >
                {question.answer_choices[3]}
            </div>
            
            {/* Risposta numero 4 */}
            <div 
                className={`MultipleChoice__option ${
                    '4' === question.correct_choice
                      ? 'MultipleChoice__option--correct'
                      : ''
                }`}
            >
                {question.answer_choices[4]}
            </div>
          </div>

          {question.justification && (
            <div className="MultipleChoice__explanation">
              <div className="MultipleChoice__explanation-header">
                <Info className="MultipleChoice__explanation-icon" />
                <div className="MultipleChoice__explanation-content">
                  <div className="MultipleChoice__explanation-title">
                    Risposta corretta: {question.correct_choice}
                  </div>
                  <p className="MultipleChoice__explanation-text">
                    {question.justification}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

MultipleChoice.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      query_text: PropTypes.string.isRequired,
      answer_choices: PropTypes.object.isRequired,
      justification: PropTypes.string,
      difficulty_level: PropTypes.oneOf(['easy', 'medium', 'hard','difficult']).isRequired,
      correct_choice: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MultipleChoice;