import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGetSummary = (document) => {
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  // Estrai il summary dal documento
  const extractSummary = useCallback(() => {
    if (!document) return null;

    console.debug('Extracting summary from document:', {
      documentId: document.id,
      hasDirectSummary: Boolean(document.summary),
      summaryType: typeof document.summary,
      summaryContent: document.summary,
      hasSections: Boolean(document.sections),
      sectionsCount: document.sectionsCount,
      isLegacyFormat: document.isLegacyFormat
    });

    // Se il documento ha un summary come stringa diretta, usalo
    if (typeof document.summary === 'string' && document.summary.trim()) {
      console.debug('Found string summary');
      return {
        abstract: document.summary,
        sections: []
      };
    }

    // Controlla se il documento ha un summary nel formato strutturato
    if (document.summary?.abstract || document.summary?.sections) {
      console.debug('Found structured summary:', document.summary);
      return document.summary;
    }

    // Se il documento ha sections dirette, usale
    if (document.sections?.length > 0) {
      console.debug('Found direct sections:', document.sections);
      return {
        abstract: '',
        sections: document.sections
      };
    }

    // Se il documento ha sectionsCount ma non ha il summary nel formato corretto,
    // cerca nelle varie posizioni possibili
    if (document.sectionsCount) {
      // Cerca il summary nelle varie posizioni possibili
      const summaryData = document.summary || 
                         document.rawExamData?.summary || 
                         document.rawFlashcards?.summary || 
                         document.examExtractedQA?.[0]?.summary;

      console.debug('Searching alternative summary locations:', {
        summaryData,
        hasRawExamData: Boolean(document.rawExamData?.summary),
        hasRawFlashcards: Boolean(document.rawFlashcards?.summary),
        hasExamExtractedQA: Boolean(document.examExtractedQA?.[0]?.summary)
      });

      if (summaryData) {
        // Se è una stringa
        if (typeof summaryData === 'string' && summaryData.trim()) {
          console.debug('Found string summary in alternative location');
          return {
            abstract: summaryData,
            sections: []
          };
        }
        // Se è un oggetto con sections
        if (summaryData?.sections) {
          console.debug('Found structured summary in alternative location');
          return {
            abstract: summaryData.abstract || '',
            sections: summaryData.sections
          };
        }
      }
    }

    console.debug('No summary found for document:', document.id);
    return null;
  }, [document]);

  // Recupera il summary quando il documento cambia
  useEffect(() => {
    const getSummary = async () => {
      try {
        setIsLoading(true);
        const extractedSummary = extractSummary();
        
        // Aggiungo un ritardo minimo di 800ms per il loading state
        await new Promise(resolve => setTimeout(resolve, 800));
        
        setSummary(extractedSummary);
      } catch (error) {
        console.error("Errore nel recupero del summary:", error);
        toast.error("Errore nel recupero del riassunto");
      } finally {
        setIsLoading(false);
      }
    };

    if (document) {
      getSummary();
    } else {
      setSummary(null);
    }
  }, [document, extractSummary]);

  return {
    isLoading,
    summary,
    isGenerating: false // Il summary non deve essere generato
  };
};

export default useGetSummary; 