import React, { useEffect, useState } from "react";
import "./index.scss";
import useGetDocuments from "@hooks/useGetDocuments";
import useSelectDocument from "./hook/useSelectDocument";
import { TbBook, TbCards, TbBrain, TbCheckbox } from "react-icons/tb";
import { useSelector } from 'react-redux';

import Heading from "@ui/heading/heading";
import Spinner from "@ui/spinner/spinner";
import MaterialsDocuments from "./materialsDocuments/materialsDocuments";
import Flashcards from "./materialsFlashcards/materialFlashcards";
import MaterialsSummary from "./materialsSummary/materialsSummary";
import MaterialsQuestions from "./materialsQuestions/materialsQuestions";
import { Dot } from "lucide-react";
import MaterialsMultipleChoice from './materialsMultipleChoice/materialsMultipleChoice';

const MENU_ITEMS = [
  { id: 'summary', icon: TbBook, label: 'Riassunto', subtitle: 'Lettura e comprensione', color: 'rgb(37 99 235)' },
  { id: 'flashcards', icon: TbCards, label: 'Flashcard', subtitle: 'Pratica attiva', color: 'rgb(5 150 105)' },
  { id: 'questions', icon: TbBrain, label: 'Domande ripetizione', subtitle: 'Ripassa concetti chiave', color: 'rgb(234 88 12)' },
  { id: 'quiz', icon: TbCheckbox, label: 'Domande risposta multipla', subtitle: 'Autovalutazione guidata', color: 'rgb(147 51 234)' }
];

/* // Onboarding
import { Steps } from 'intro.js-react';
import { useSelector } from 'react-redux';
import useGetOnboardingState from "../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState"; */

const Materials = () => {
  const { isLoading } = useGetDocuments("all");
  const [currentLink, setCurrentLink] = useState('summary');
  const { selectedDocument, changeDocumentId } = useSelectDocument();

  // Determina se il documento usa il vecchio o il nuovo formato
  const isLegacyFormat = selectedDocument?.summary && typeof selectedDocument.summary === 'string';
  
  // Filtra i menu items in base al formato del documento
  const availableMenuItems = isLegacyFormat 
    ? MENU_ITEMS.filter(item => item.id === 'summary' || item.id === 'flashcards')
    : MENU_ITEMS;

  const getMenuItemStyles = (itemId) => {
    const isActive = currentLink === itemId;
    const item = MENU_ITEMS.find(i => i.id === itemId);
    
    return {
      link: `Materials__menu__${itemId}-link${isActive ? `__${itemId}-active` : ''}`,
      iconBg: `Materials__menu__${isActive ? `${itemId}-icon-bg` : 'icon-bg'}`,
      dotColor: `Materials__menu__${itemId}-circle-icon`
    };
  };

  const renderMenuItem = (item) => {
    const styles = getMenuItemStyles(item.id);
    const Icon = item.icon;
    
    return (
      <div 
        key={item.id}
        className={styles.link}
        onClick={() => setCurrentLink(item.id)}
      >
        <div className={styles.iconBg}>
          <Icon size={18} style={{ minWidth: '18px' }} />
        </div>
        
        <div>
          <h4 className="Materials__menu__title">
            {item.label}
          </h4>
          <span className="Materials__menu__subtitle">
            {item.subtitle}
          </span>
        </div>
        
        {currentLink === item.id && (
          <Dot className={styles.dotColor} size={50} />
        )}
      </div>
    );
  };

  const renderContent = () => {
    switch(currentLink) {
      case 'flashcards':
        return <Flashcards idSelectedDocument={selectedDocument?.id} />;
      case 'summary':
        return <MaterialsSummary document={selectedDocument} />;
      case 'questions':
        return <MaterialsQuestions idSelectedDocument={selectedDocument?.id} />;
      case 'quiz':
        return <MaterialsMultipleChoice idSelectedDocument={selectedDocument?.id} />;
      default:
        return null;
    }
  };

  /* const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep < 16);
  const { getCurrentStep, updateCurrentStep } = useGetOnboardingState();
  
  useEffect(() => {
    getCurrentStep();

    if (!isLoading && currentStep < 16) {
      setStepsEnabled(true);
    };
  }, [isLoading, currentStep, getCurrentStep]);

  const steps = [
    currentStep === 13 && {
      element: '.Document__card',
      intro: "Seleziona il documento che hai appena caricato",
    },
    currentStep === 14 && {
      element: '.MaterialsFlashcards__container',
      intro: "Queste sono le flashcards generate dal documento",
    },
    currentStep === 14 && {
      element: '.Materials__menu__summary-link',
      intro: "Cliccando qui apparirà il riassunto del documento",
    }
  ].filter(Boolean);
  
  const onExit = async () => {
    if(currentStep < 16) {
      updateCurrentStep(16);
    }
      setStepsEnabled(false);
  }; */

  const handleSelectSummary = () => {
    setCurrentLink('summary');
    /* if(currentStep < 15) {
      updateCurrentStep(15);
    }
    setStepsEnabled(false); */
  };

  return (
    <div className="Materials">
      {/* {stepsEnabled && (  
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
        />
      )} */}
      <div className="container">
        <div className="row gy-xs-1 gy-lg-3">
          <div className="col-xs-12">
            <div className={isLoading ? 'Materials__loading-docs' : ""}>
              <Heading className="Materials__title" heading="h4" title="Documenti" />
              <section className="Materials__loader-section">
                {isLoading && 
                  <div className="Materials__loader-section__inner-container">
                    <Spinner size="sm" />
                    <p className="Materials__loader-section__text">Caricamento Materiali...</p>
                  </div>
                }
              </section>
              {!isLoading && (
                <MaterialsDocuments
                  selectedDocument={selectedDocument}
                  onChange={changeDocumentId}
                  /* setStepsEnabled={setStepsEnabled} */
                />
              )}
            </div>
          </div>

          {!isLoading && (
            <div className="col-xs-12">
              <Heading className="Materials__title" heading="h4" title="Ripasso" />
              <div className="Materials__menu">
                {availableMenuItems.map(renderMenuItem)}
              </div>

              <Heading className="Materials__title" heading="h4" title={MENU_ITEMS.find(i => i.id === currentLink)?.label} />
              {renderContent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Materials;
