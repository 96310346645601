import { firestore } from "@redux/firebase";
import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, query, orderBy, where, getDocs, increment } from "firebase/firestore";
import { toast } from "react-toastify";

// get document's questions
export const getQuestions = async documentId => {
  try {
    const questionsQuery = query(
      collection(firestore, "questions"),
      where("documentID", "==", documentId),
      orderBy("uploadDate", "desc"),
    );

    return getDocs(questionsQuery);
  } catch (error) {
    throw error;
  }
};

// get question
export const getQuestion = async questionId => {
  try {
    const questionRef = doc(firestore, "questions", questionId);
    const questionSnap = await getDoc(questionRef);

    // check if question exists
    if (!questionSnap.exists()) return {};

    return questionSnap.data();
  } catch (error) {
    throw error;
  }
};

// get user's documents
export const getUserDocuments = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    // Semplifichiamo la query per evitare problemi con gli indici
    const documentsQuery = query(
      collection(firestore, "documents"),
      where("uploadedBy", "==", userId)
    );

    const querySnapshot = await getDocs(documentsQuery);
    
    // Filtriamo e ordiniamo i documenti in memoria
    const documents = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      // Filtriamo i documenti rimossi
      .filter(doc => !doc.isRemoved)
      // Ordiniamo per data di upload (dal più recente)
      .sort((a, b) => {
        const dateA = a.uploadDate?.seconds || 0;
        const dateB = b.uploadDate?.seconds || 0;
        return dateB - dateA;
      });

    // Restituiamo i documenti nel formato che il componente si aspetta
    return {
      docs: documents,
      empty: documents.length === 0,
      size: documents.length
    };
  } catch (error) {
    console.error("Errore nel recupero dei documenti:", error);
    throw new Error("Errore nel recupero dei documenti dell'utente");
  }
};

// get user's documents not reviewed
export const getUserDocumentsNotReviewed = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const examQuery = query(collection(firestore, "exam"));
    const documentsQuery = query(collection(firestore, "documents"), where("uploadedBy", "==", userId));
    const examDocsPromise = getDocs(examQuery);
    const documentsDocsPromise = getDocs(documentsQuery);

    // Wait for both queries to complete
    const [examDocs, documentsDocs] = await Promise.all([examDocsPromise, documentsDocsPromise]);

    // Extract data from document snapshots
    const examData = examDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const documentsData = documentsDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return documentsData.filter(document => document.id !== examData.map(exam => exam.documentID));
  } catch (error) {
    throw error;
  }
};

// get user's profile
export const getUserProfile = async userId => {
  try {
    const userRef = doc(firestore, "students", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  } catch (error) {
    toast.error("Errore nel recupero del profilo dell'utente");
    throw error;
  }
};

// update resource
export const updateResource = async (collectionName, resourceId, updateData) => {
  try {
    const resourceRef = doc(firestore, collectionName, resourceId);
    await updateDoc(resourceRef, { ...updateData });
  } catch (error) {
    toast.error("Errore nell'aggiornamento della risorsa");
    throw error;
  }
};

// save exam
export const saveQAExam = async (examID, messages) => {
  try {
    const examSessionRef = doc(firestore, "examSessions", examID);
    const chatMessages = messages.reduce((acc, [key, message]) => {
      const [question, userAnswer, feedback] = message;
      const messageObj = {
        [key]: {
          question: question?.text ?? "",
          answer: userAnswer?.text ?? "",
          feedback: feedback?.text ?? "",
        },
      };

      acc = [...acc, messageObj];
      return acc;
    }, []);
    await setDoc(examSessionRef, { chatMessages });
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

// get user data
export const getUser = async userId => {
  try {
    const userRef = doc(firestore, "students", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.data();
  } catch (error) {
    throw error;
  }
};

// get documents by id
export const getDocumentById = async documentId => {
  try {
    const documentRef = doc(firestore, "documents", documentId);
    const documentSnap = await getDoc(documentRef);

    return { ...documentSnap.data(), documentID: documentSnap.id };
  } catch (error) {
    throw error;
  }
};

// get user's exams in progress
export const getExamsInProgress = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem("userId"));
    if (!userId) {
      throw new Error("User ID non trovato");
    }

    const examsQuery = query(
      collection(firestore, "exam"),
      where("status", "==", "in-progress")
    );

    const snapshot = await getDocs(examsQuery);
    const examDocs = snapshot.docs;

    const examsWithDocuments = await Promise.all(
      examDocs.map(async (examDoc) => {
        const examData = examDoc.data();
        const documentRef = doc(firestore, "documents", examData.documentID);
        const documentSnap = await getDoc(documentRef);

        if (documentSnap.exists() && documentSnap.data().uploadedBy === userId) {
          return {
            ...examData,
            ...documentSnap.data(),
            examID: examDoc.id,
            documentID: examData.documentID,
            timeRemaining: examData.timeRemaining,
          };
        }
        return null;
      })
    );

    const exams = examsWithDocuments.filter((exam) => exam !== null);
    return exams;

  } catch (error) {
    throw error;
  }
};


// exam logic
export const getQAExamSessions = async examID => {
  try {
    const examSessionRef = doc(firestore, "examSessions", examID);
    const examSessionSnap = await getDoc(examSessionRef);
    const messages = examSessionSnap.data().chatMessages;

    // transform messages into chat messages
    const messagesChat = messages.map(message => {
      const key = Object.keys(message)[0];
      const { question, answer, feedback } = message[key];
      let messages = [
        question && { id: key, text: question, sender: "bot" },
        answer && { id: key, text: answer, sender: "user" },
        feedback && { id: key, text: feedback, sender: "bot", isFeedback: true },
        feedback.score &&  { score: feedback.score },
      ].filter(Boolean);

      return [key, messages];
    });

    return messagesChat;
  } catch (error) {
    throw error;
  }
};

// check if exam is in progress
export const isExamInProgress = async examID => {
  try {
    const examsQuery = doc(firestore, "exam", examID);
    const examSessionSnap = await getDoc(examsQuery);

    // check if exam is in progress exists
    if (!examSessionSnap.exists()) return null;

    return examSessionSnap.id;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

// delete exam from Firestore
export const deleteExamFromFirestore = async (examID) => {
  try {
    const examRef = doc(firestore, "exam", examID);
    const examSnap = await getDoc(examRef);
    if (!examSnap.exists()) {
      throw new Error("L'esame non esiste");
    }
    const examData = examSnap.data();

    // Elimina l'esame
    await deleteDoc(examRef);

    // Elimina la sessione d'esame associata
    const examSessionRef = doc(firestore, "examSessions", examID);
    await deleteDoc(examSessionRef);

    // Aggiorna il contatore degli esami tentati per l'utente
    const userID = JSON.parse(localStorage.getItem("userId"));
    if (userID) {
      const userRef = doc(firestore, "students", userID);
      await updateDoc(userRef, {
        examsAttempted: increment(-1)
      });
    }

    // Se l'esame era associato a un documento, aggiorna il documento
    if (examData.documentID) {
      const documentRef = doc(firestore, "documents", examData.documentID);
      await updateDoc(documentRef, {
        hasExamInProgress: false
      });
    }

    console.log("Esame eliminato con successo:", examID);
    return true;
  } catch (error) {
    console.error("Errore durante l'eliminazione dell'esame:", error);
    throw error;
  }
};