import React from "react";
import "./assistanceInfo.scss";

import Heading from "@ui/heading/heading";
import GoBack from "@components/goBack/goBack";

const AssistanceInfo = () => {
  return (
    <div data-testid="AssistanceInfo" className="AssistanceInfo">
      <GoBack />
      <Heading className="AssistanceInfo__title" title="Hai bisogno di aiuto?" heading="h3" />
      <p>Se hai bisogno di aiuto, non esitare a contattarci. Siamo qui per te.</p>
    </div>
  );
};

export default AssistanceInfo;
