import React, { useEffect, useState } from 'react';
import './TypewriterEffect.scss';
import { motion } from 'framer-motion';

export const TypewriterEffect = ({
  words,
  className,
  cursorClassName,
  typingSpeed = 0.03,
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [words.length]);

  const colorizeText = (text) => {
    const phrases = {
      "Simula l'interrogazione": [
        { text: "Simula", class: "character--special-light" },
        { text: "l'interrogazione", class: "character--special-dark" }
      ],
      "Preparati all'esame": [
        { text: "Preparati", class: "character--special-dark", lineBreak: true },
        { text: "all'orale", class: "character--special-light" }
      ],
      "Migliora il tuo metodo": [
        { text: "Migliora", class: "character--special-light" },
        { text: "il", class: "character--special-dark" },
        { text: "tuo", class: "character--special-dark" },
        { text: "metodo", class: "character--special-dark" }
      ],
      "Ottieni voti migliori": [
        { text: "Ottieni", class: "character--special-light" },
        { text: "voti", class: "character--special-dark" },
        { text: "migliori", class: "character--special-light" }
      ],
      "Simula l'esame orale": [
        { text: "Simula", class: "character--special-light" },
        { text: "l'esame", class: "character--special-dark" },
        { text: "orale", class: "character--special-light" }
      ]
    };

    const phraseWords = phrases[text.trim()];
    if (!phraseWords) {
      return [{ text, class: '' }];
    }
    return phraseWords;
  };

  return (
    <div className={className} aria-live="polite">
      {words.map((word, index) => (
        <span 
          key={index} 
          style={{ display: index === currentWordIndex ? 'inline' : 'none' }}
        >
          {colorizeText(word.text).map((segment, idx) => (
            <React.Fragment key={idx}>
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: idx * typingSpeed }}
                className={`character ${segment.class}`}
              >
                {segment.text}{' '}
              </motion.span>
              {segment.lineBreak && <br className="mobile-break" />}
            </React.Fragment>
          ))}
          <motion.span 
            className={cursorClassName} 
            style={{ opacity: index === words.length - 1 ? 1 : 0 }}
          />
        </span>
      ))}
    </div>
  );
};
