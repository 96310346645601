import { useState, useCallback, useEffect, useRef } from 'react';

const LOG_THROTTLE_MS = 500;

export const useFeedback = (extractedQA = [], onFeedbackChange) => {
  const mountedRef = useRef(false);
  const lastLogTime = useRef(Date.now());
  const feedbackMapRef = useRef(new Map());
  const previousLengthRef = useRef(0);

  // Initialize feedback states
  const [feedbackStates, setFeedbackStates] = useState(() => {
    console.log(`📝 Inizializzazione feedback per ${extractedQA.length} domande`);
    const initialStates = Array(extractedQA.length).fill().map((_, index) => {
      const existingFeedback = feedbackMapRef.current.get(index);
      return existingFeedback || {
        isHelpful: null,
        timestamp: null,
        userId: null
      };
    });
    return initialStates;
  });

  // Log statistics with throttling
  const logStats = useCallback((states) => {
    const now = Date.now();
    if (now - lastLogTime.current < LOG_THROTTLE_MS) return;

    const stats = states.reduce((acc, f) => {
      if (f?.isHelpful === true) acc.positive++;
      else if (f?.isHelpful === false) acc.negative++;
      else acc.pending++;
      return acc;
    }, { positive: 0, negative: 0, pending: 0 });

    console.log(
      `📊 Feedback: ✅ ${stats.positive} positivi | ❌ ${stats.negative} negativi | ⏳ ${stats.pending} in attesa`
    );
    
    lastLogTime.current = now;
  }, []);

  // Handle feedback updates
  const handleFeedback = useCallback((index, isHelpful) => {
    if (!mountedRef.current) return;

    setFeedbackStates(prev => {
      // Se il feedback è uguale a quello esistente, non fare nulla
      if (prev[index]?.isHelpful === isHelpful) return prev;

      // Crea una copia dell'array esistente
      const newStates = [...prev];
      
      // Aggiorna solo lo stato del feedback specifico
      const newState = {
        isHelpful,
        timestamp: Date.now(),
        userId: JSON.parse(localStorage.getItem("userId"))
      };

      // Aggiorna l'array e la mappa
      newStates[index] = newState;
      feedbackMapRef.current.set(index, newState);

      // Calcola le statistiche
      const activeCount = newStates.filter(f => f?.isHelpful !== null).length;
      const positiveCount = newStates.filter(f => f?.isHelpful === true).length;
      const negativeCount = newStates.filter(f => f?.isHelpful === false).length;

      console.log(`${isHelpful ? '✅' : '❌'} Feedback domanda ${index + 1} [${activeCount}/${newStates.length}] (✅ ${positiveCount} | ❌ ${negativeCount})`);
      logStats(newStates);

      return newStates;
    });

    onFeedbackChange?.(index, isHelpful);
  }, [logStats, onFeedbackChange]);

  // Reset feedback
  const resetFeedback = useCallback(() => {
    if (!mountedRef.current) return;

    const activeCount = feedbackStates.filter(f => f?.isHelpful !== null).length;
    if (activeCount === 0) return;

    console.log('🔄 Reset feedback');
    feedbackMapRef.current.clear();
    
    const newStates = Array(feedbackStates.length).fill().map(() => ({
      isHelpful: null,
      timestamp: null,
      userId: null
    }));

    setFeedbackStates(newStates);
  }, [feedbackStates]);

  // Lifecycle management
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Handle changes in questions count
  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }

    // Aggiorna il feedback solo se è cambiato il numero di domande
    if (extractedQA.length !== previousLengthRef.current) {
      console.log(`📝 Aggiornamento feedback per ${extractedQA.length} domande`);
      previousLengthRef.current = extractedQA.length;
      
      setFeedbackStates(prevStates => {
        return Array(extractedQA.length).fill().map((_, index) => {
          return prevStates[index] || {
            isHelpful: null,
            timestamp: null,
            userId: null
          };
        });
      });
    }
  }, [extractedQA.length, feedbackStates]);

  return {
    feedbackStates,
    handleFeedback,
    resetFeedback,
    activeFeedbackCount: feedbackStates.filter(f => f?.isHelpful !== null).length
  };
};

export default useFeedback;