import React from 'react';
import PropTypes from 'prop-types';
import Feedback from './feedback/feedback';
import clsx from 'clsx';
import './itemQA.scss';

const QuestionSummary = ({ icon: Icon, count, type, color, additionalInfo }) => {
  const getLabel = () => {
    switch (type) {
      case 'flashcard':
        return `${count} flashcard disponibili`;
      case 'question':
        return `${count} domande disponibili`;
      case 'summary':
        return `~${count} min di lettura`;
      default:
        return `${count} elementi`;
    }
  };

  return (
    <div className="ItemQA__summary">
      <div className="ItemQA__summary-icon" style={{ color }}>
        <Icon size={20} />
      </div>
      <div className="ItemQA__summary-content">
        <span className="ItemQA__summary-total">
          {getLabel()}
          {type === 'summary' && additionalInfo && (
            <span className="ItemQA__summary-details">
              {' '}| {additionalInfo.words.toLocaleString()} caratteri | {additionalInfo.sections} sezioni
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

const ItemQA = ({ questionNum, question, answer, feedback, onFeedback, type }) => {
  return (
    <li className="ItemQA">
      <div className="ItemQA__content">
        <h3 className="ItemQA__question">
          <span className={clsx("ItemQA__question-number", `ItemQA__question-number--${type}`)}>
            {questionNum}.
          </span>
          {question}
        </h3>
        <p className="ItemQA__answer">{answer}</p>
      </div>

      <Feedback 
      feedback={feedback}
      onFeedback={onFeedback}
      question={question}
    />
    </li>
  );
};

ItemQA.propTypes = {
  questionNum: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  feedback: PropTypes.shape({
    isHelpful: PropTypes.bool,
    timestamp: PropTypes.number,
    userId: PropTypes.string
  }),
  onFeedback: PropTypes.func,
  type: PropTypes.oneOf(['question', 'flashcard']).isRequired
};

QuestionSummary.propTypes = {
  icon: PropTypes.elementType.isRequired,
  count: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['question', 'flashcard', 'summary']).isRequired,
  color: PropTypes.string.isRequired,
  additionalInfo: PropTypes.shape({
    words: PropTypes.number,
    sections: PropTypes.number
  })
};

export { QuestionSummary };
export default ItemQA;