import './answersControls.scss';
import { useEffect, useState } from 'react';

/* // Onboarding
import { Steps } from "intro.js-react";
import { useSelector } from 'react-redux';
import useGetOnboardingState from '../../../uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState';
import "intro.js/introjs.css"; */

const AnswersControls = ({ showChat, examState, isExamStarted, setShowChat, MessageCircle, MicOff, Mic, isRecording, toggleRecording, startExam }) => {
  /* const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 8 ? true : false);  
  const { updateCurrentStep, getCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    getCurrentStep();
    if(currentStep === 9) {
      setStepsEnabled(false);
    };
  }, [getCurrentStep, currentStep]);
  
  const steps = [
    currentStep === 8 && {
      element: '.controls',
      intro: "Clicca sul microfono o la chat per rispondere alle domande",
    },
  ].filter(Boolean);

  const onExit = async () => {
    setStepsEnabled(false);
    
    if(currentStep === 8) {
      updateCurrentStep(9);
    };
  }; */

  const handleToggleRecording = () => {
    /* if(currentStep === 8) {
      updateCurrentStep(9);
    };

    setStepsEnabled(false); */
    toggleRecording();
  };

  /* useEffect(() => {
    const helperLayer = document.querySelector('.introjs-helperLayer');
    if (helperLayer) {
      helperLayer.style.marginTop = '0px';
    }
    
    const tooltip = document.querySelector('.introjs-tooltip');
    if (tooltip) {
      tooltip.style.marginTop = "0px";  
    }
  }, [stepsEnabled]); */

  const handleOpenChat = () => {
   /*  if(currentStep === 8) {
      updateCurrentStep(9);
    }; */
    if(examState === 'listening' || examState === 'waiting') {
      setShowChat(!showChat);
    };
    
    if(!isExamStarted) {
      startExam();
    };
  };

  return (
    <>
      {/* <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={0}
        onExit={onExit}
      /> */}
      {examState !== 'answered' && 
        <div className={`controls ${showChat ? "controls chat-active" :  ""}`}>
          <span
          className={`control-button mic ${
            isRecording ? 'recording' : 'not-recording'
          } ${showChat || examState === 'processing' ? 'mic-inactive' : ''}`}
          onClick={handleToggleRecording}
          disabled={examState === 'answered' || examState === 'listening' || examState === 'waiting'}
          >
            {isRecording ? <MicOff size={24} /> : <Mic size={24} />}
          </span>
          <span
          className={`control-button chat ${isRecording || examState === 'processing' ? 'inactive' : ''}`}
          onClick={handleOpenChat}
          disabled={isRecording}
          >
          <MessageCircle size={24} />
          </span>
        </div>
      }
    </>
  )
}

export default AnswersControls;