import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Gift, Users, Share2 } from 'lucide-react';
import "./referralCode.scss";

const ReferralCode = () => {
  const { user } = useSelector(state => state.user);
  const navigate = useNavigate();
  const [friendsInvited, setFriendsInvited] = useState(0); // Numero di persone invitate
  const [amountEarned, setAmountEarned] = useState(0); // Ammontare totale guadagnato in €
  
  const handleRefferalCodeActions = () => {  
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'referralCodeSaved', {
        'event_category': 'ReferralCode',
        'event_label': 'referralCode Saved',
        'email': user.email
      });
    } else {
      console.error("gtag is not defined");
    }
  }

  const goToUpgrade = () => {
    navigate("/home/piani");
  };
  
  const API_URL = process.env.NODE_ENV === "development" 
    ? process.env.REACT_APP_API_URL_DEVELOPMENT 
    : process.env.REACT_APP_API_URL_PRODUCTION;

  useEffect(() => {
    const fetchPromotionDetails = async () => {
      if (!user?.referralCode) {
        console.log("No referral code available");
        return;
      }

      try {
        // Get auth token from localStorage or dove lo conservi
        const token = localStorage.getItem('token'); // o altro metodo per ottenere il token

        if (!token) {
          console.log("No authentication token available");
          return;
        }

        const apiUrl = `${API_URL}/fetch-promotion-details?referralCode=${encodeURIComponent(user.referralCode)}`;
        
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          credentials: 'include',
        });

        if (!response.ok) {
          if (response.status === 401) {
            console.log("Authentication error");
            // Gestisci il caso di autenticazione scaduta
            return;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();

        if (!data || !data.data) {
          console.log("Invalid response format");
          return;
        }

        if (data.data && data.data.length > 0) {
          const promotionCode = data.data[0];
          setAmountEarned(promotionCode.times_redeemed * 5);
          setFriendsInvited(promotionCode.times_redeemed);
        } else {
          // Se non ci sono dati, impostiamo i valori a 0
          setAmountEarned(0);
          setFriendsInvited(0);
        }
      } catch (error) {
        console.error("Error fetching promotion details:", error);
        // In caso di errore, impostiamo comunque i valori a 0
        setAmountEarned(0);
        setFriendsInvited(0);
        
        // Se l'errore è di rete, potremmo voler ritentare
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
          console.log("Network error, might retry later");
          // Qui potresti implementare una logica di retry
        }
      }
    };

    if (user?.plan === 'Premium') {
      fetchPromotionDetails();
    } else {
      // Se l'utente non è premium, impostiamo i valori a 0
      setAmountEarned(0);
      setFriendsInvited(0);
    }
  }, [user?.referralCode, user?.plan, API_URL]);

  const handleShare = useCallback(() => {
    const shareCode = user.referralCode;
    navigator.clipboard.writeText(shareCode).then(() => {
      if (window.Notification && Notification.permission === "granted") {
        new Notification("StudierAI", { body: "Link di invito copiato negli appunti!" });
      } else {
        alert("Link di invito copiato negli appunti!");
      }
    }).catch(err => {
      console.error("Errore durante la copia negli appunti:", err);
      alert("Si è verificato un errore durante la copia del link. Per favore, prova di nuovo.");
    });
  }, [user.referralCode]);

  // Funzione per formattare i numeri con separatori di migliaia
  const formatNumber = (num) => num.toLocaleString('it-IT');

  return (
    <div className="ReferralCode">
      <div className="ReferralCode__container">
        <div className="ReferralCode__card">
          <div className="ReferralCode__card__top-card">
            <h3 className="ReferralCode__card__title">
              Guadagna 5€ per ogni amico che si iscrive a StudierAI Premium!
            </h3>
            
            <div className="value-proposition">
              <div className="value-proposition__item">
                <Users />
                <p>I tuoi amici riceveranno uno sconto di 2€ per l'iscrizione all'abbonamento premium!</p>
              </div>
              <div className="value-proposition__item">
                <Gift />
                <p>Tu ricevi 5€ in crediti Amazon per ogni amico iscritto</p>
              </div>
            </div>
          </div>
          
          <div className="ReferralCode__card__statistics-card">
            <div className="ReferralCode__card__statistics-card__ongoing-container">
              <h3 className="ReferralCode__card__statistics-card__referral-label">
                Statistiche continue
              </h3>
              <div className="ReferralCode__card__statistics-card__ongoing-row">
                {/* Numero di amici invitati */}
                <div className="ReferralCode__card__statistics-card__ongoing-row__container">
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__subtitle">
                    RICOMPENSE TOTALI
                  </span>
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__amount">
                    {user.plan === 'Premium' ? formatNumber(friendsInvited) : 0}
                  </span>
                </div>

                {/* Ammontare totale guadagnato in € */}
                <div className="ReferralCode__card__statistics-card__ongoing-row__container">
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__subtitle">
                    VALORE COMPLESSIVO
                  </span>
                  <span className="ReferralCode__card__statistics-card__ongoing-row__container__amount">
                    {user.plan === 'Premium' ? `${formatNumber(amountEarned)}€` : "0€"}
                  </span>
                </div>
              </div>
            </div>
            
            {user.plan === 'Premium' ? (
              <div className="ReferralCode__card__statistics-card__referralCode-container">
                <h3 className="ReferralCode__card__statistics-card__referralCode-container__referral-label">
                  Codice referral
                </h3>
                <div className="referral-code-input-group" onClick={handleRefferalCodeActions}>
                  <input 
                    className="ReferralCode__card__statistics-card__referralCode-container__input"
                    value={user.referralCode}
                    readOnly
                    onClick={handleShare}
                  />
                  <button 
                    className="share-button" 
                    onClick={handleShare}
                    aria-label="Copia codice"
                  >
                    <Share2 />
                  </button>
                </div>
              </div>
            ) : (
              <div className="ReferralCode__card__statistics-card__upgrade-container">
                <h3 className="ReferralCode__card__statistics-card__upgrade-container__upgrade-label">
                  Sblocca il codice referral
                </h3>
                <button 
                  className="ReferralCode__card__statistics-card__upgrade-container__upgrade-btn"
                  onClick={goToUpgrade}
                >
                  Aggiorna il tuo piano
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="ReferralCode__container__giftcards-container">
          <h3 className="ReferralCode__card__title">Giftcards</h3>
          <span className="ReferralCode__container__giftcards-container__subtitle">
            Le ricompense appariranno qui sotto ogni primo giorno del mese.
          </span>
          <div className="ReferralCode__container__giftcards-container__giftcards-table">
            <div className="ReferralCode__container__giftcards-container__giftcards-table__giftcard">
              Nessuna giftcard disponibile
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
