import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Heading from "@ui/heading/heading";
import { motion } from "framer-motion";

import "./functionalityItem.scss";

const FunctionalityItem = ({ title, text, image }) => {
  const [loaded, setLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    if (img.complete) {
      handleImageLoad();
    }
    return () => {
      img.onload = null;
    };
  }, [image, handleImageLoad]);

  return (
    <motion.div 
      className="FunctionalityItem"
      initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: isMobile ? 0 : 0.6 }}
    >
      <motion.div 
        className="FunctionalityItem__content"
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.2 }}
      >
        <div className="FunctionalityItem__text">
          <Heading title={title} heading="h2" className="FunctionalityItem__title" />
          <p className="FunctionalityItem__description">{text}</p>
        </div>
      </motion.div>
      <motion.div 
        className="FunctionalityItem__image-container"
        initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
        whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.3 }}
      >
        <img
          className={clsx("FunctionalityItem__image", !loaded && "blurImage")}
          src={image}
          alt={title}
          onLoad={handleImageLoad}
        />
      </motion.div>
    </motion.div>
  );
};

FunctionalityItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default React.memo(FunctionalityItem);