import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateSpeech } from '../../../services/openaiApi';
import { Send } from 'lucide-react';
import { doc, increment, updateDoc } from "firebase/firestore";
import { getUserProfile } from '../../../lib/firestoreQueries';
import { firestore } from "@redux/firebase";
import ChatModalFreeSubscription from './chatModalFreeSubscription/chatModalFreeSubscription';
import ExamCardHeader from './ExamCardHeader/ExamCardHeader';
import ExamProgress from './ExamProgress/ExamProgress';
import ExamContent from './ExamContent/ExamContent';
import FeedbackCard from './FeedbackCard/FeedbackCard';
import ChatCardForm from './chatCardForm/chatCardForm';
import useMessages from './hooks/useMessages';
import useChatForm from './chatCardForm/hooks/useChatForm';
import useShowModal from '../hooks/useShowModal';
import useSaveExam from '../chatHeader/hook/useSaveExam';
import Button from '../../../components/ui/button/button';
import './examCard.scss';
import { setLimitExamsReached } from '../../../redux/actions/loadScoreActions';
import { useLocation } from 'react-router-dom';

const ExamCard = ({ qa, currentQA, documentID }) => {
  // State
  const [examState, setExamState] = useState('waiting');
  const [isExamStarted, setIsExamStarted] = useState(false);
  const currentIndex = currentQA ? currentQA.length-1 : 0; 
  const [currentQuestion, setCurrentQuestion] = useState(currentIndex);
  const [estimatedVote, setEstimatedVote] = useState(0);
  const [examProgress, setExamProgress] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [aiIconState, setAiIconState] = useState('stopped');
  const [chatMessages, setChatMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [userAnswer, setUserAnswer] = useState(null);
  const [showExamFinishConfirmation, setShowExamFinishConfirmation] = useState(false);

  // Audio
  const inputRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioBufferSourceRef = useRef(null);

  // Location state
  const location = useLocation();
  const { reviewMode } = location.state || {};

  // Exam state
  const currentExamID = useSelector(state => state.exam.currentExamID);
  
  // User info
  const { user } = useSelector(state => state.auth);
  const { limitExamsReached } = useSelector(state => state.leadScore);
  const userPlan = user?.plan ?? "Base";
  const isFreeAccount = user?.role !== "Admin" && userPlan === "Base";
  const { showModal, closeModalHandler, incrementExamCount, examCount } = useShowModal(isFreeAccount, documentID);
  const dispatch = useDispatch();

  // Chat
  const {
    state,
    addMessage,
    deleteLoaderMessage,
    retryQuestion,
    skipQuestion,
    onCreateFeedback,
    examResult,
  } = useMessages(qa, currentQA, documentID);
  
  const updatedQA = useMemo(() => {
    return qa.filter(qa => qa.feedback?.isHelpful !== false && !qa.isFlashcard);
  }, [qa]);

  // Save exam
  const { saveExam, isSaving } = useSaveExam(documentID, Array.from(state.messages), currentExamID);

  // Chat form
  const { getFeedback } = useChatForm(
    state.messageID,
    addMessage,
    deleteLoaderMessage,
    onCreateFeedback,
    retryQuestion,
  );

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    const totalQuestions = updatedQA.length;
    let averageScore = 0;
    
    if(!currentQA) return
    
    for(let qa of currentQA) {
      averageScore += qa[1][3]?.score ? qa[1][3]?.score : 0;
    };
    
    averageScore = averageScore / totalQuestions;  
    
    // Per evitare numeri periodici
    if (averageScore % 1 !== 0) {
      averageScore = Math.floor((averageScore * 10) / 10);
    }
    
    setEstimatedVote(averageScore);
  }, [currentQA, updatedQA.length]);

  const firstUserAnswer = async () => {    
    const userId = JSON.parse(localStorage.getItem("userId"));
    const user = await getUserProfile(userId);
    let examsAttempted = user.examsAttempted ? user.examsAttempted : 0;
    
    try {
      if(state.messages.size === 1 && examsAttempted === 0) {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'firstUserAnswer', {
            'event_category': 'Exam',
            'event_label': 'First User Answer',
            'email': user.email          
          });        
        } else {
          console.error("gtag is not defined");
        }
      }
    } catch(error) {
      console.error("Error getting userID for analytic event", error)
    }
  };

  useEffect(() => {
    if(audioBufferSourceRef.current) {
      setAiIconState('speaking');
    }
  }, [audioBufferSourceRef.current])
  
  const generateAndPlaySpeech = useCallback(async (answer) => {
    try {
      const arrayBuffer = await generateSpeech(answer.feedback);

      if (!audioContextRef.current) {
        console.error('AudioContext not initialized');
        return;
      }

      const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);

      if (audioBufferSourceRef.current) {
        audioBufferSourceRef.current.stop();
      }
      
      audioBufferSourceRef.current = audioContextRef.current.createBufferSource();
      audioBufferSourceRef.current.buffer = audioBuffer;
      audioBufferSourceRef.current.connect(audioContextRef.current.destination);

      audioBufferSourceRef.current.onended = () => {
        setAiIconState('stopped');
      };
      
      // Su Safari, potrebbe essere necessario riprendere l'AudioContext
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      audioBufferSourceRef.current.start();
      setAiIconState('speaking');
    } catch (error) {
      console.error('Errore nella generazione o riproduzione dell\'audio:', error);
      setAiIconState('stopped');
    }
  }, [setAiIconState]);

  const handleExamCompletion = useCallback(async () => {
    const examID = location.state.examID;
    examResult(examID);
    incrementExamCount();
    dispatch(setLimitExamsReached());
    if(limitExamsReached === 1) {
      // GA4 Event to count all users answers
      if (typeof window.gtag === 'function') {
       window.gtag('event', 'limitExamsReached', {
         'event_category': 'Exam',
         'event_label': 'Limit Exams Reached',
         'email': user.email
       });        
       
       try { 
         const userId = localStorage.getItem('userId');
         let userStats = doc(firestore, 'userStats', JSON.parse(userId));
    
         await updateDoc(userStats, {
           examLimitReached: increment(1),  
         }, { merge: true });
         
         
       } catch(error) {
         console.error('Error fetching user stats', error);
       }
     } else {
       console.error("gtag is not defined");
     }
    };

  }, [examResult, incrementExamCount, dispatch, user.email, limitExamsReached]);

  const handleConfirmFinishExam = useCallback(() => {
    setShowExamFinishConfirmation(false);
    handleExamCompletion();

    if (isFreeAccount && examCount === 0) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'userAnsweredAllFreeQuestions', {
          'event_category': 'Exam',
          'event_label': 'User Answerered All Free Questions',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }
    }

    if (typeof window.gtag === 'function') {
      window.gtag('event', 'viewExamResultPage', {
        'event_category': 'View',
        'event_label': 'view Exam Result Page',
        'email': user.email    
      });        
    } else {
      console.error("gtag is not defined");
    }
  }, [examCount, handleExamCompletion, isFreeAccount, user.email]);

  const nextQuestion = useCallback(() => {
    try {
      if(feedback) {
        if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped')) return;
      }

      if(audioContextRef.current) {
        audioContextRef.current.suspend();
        
        setAiIconState('stopped');
      }

      const totalQuestions = updatedQA.length;
      const validTotalQuestions = Number.isInteger(totalQuestions) && totalQuestions > 0 ? totalQuestions : 0;
      const validCurrentIndex = Number.isInteger(currentQuestion) && currentQuestion >= 0 ? currentQuestion : 0;

      const progress = validTotalQuestions > 0 ? ((validCurrentIndex + 2) / validTotalQuestions) * 100 : 0;

      if (currentQuestion < updatedQA.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
        setExamProgress(progress);
        setExamState('listening');
        setFeedback(null);
        setShowChat(false);
      } else {
        setExamProgress(100);
        setExamState('completed');
        handleConfirmFinishExam();
      }
    } catch (error) {
      console.error("Error moving to the next question:", error);
    }
  }, [updatedQA.length, currentQuestion, handleConfirmFinishExam, examState, aiIconState, feedback]);
  
  const processAnswer = useCallback(async (answer) => {
    try {
      setFeedback(answer);
      
      // skip feedback if exam is in interrogation mode      
      if(!reviewMode) {
        setExamState('listening');
        /* setChatMessages([]);
        setShowChat(false); */
      } else {
        setExamState('answered');
        setChatMessages([]);
        setShowChat(false);
      }
      
      
      // GA4 Event to count all users answers
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'usersAnswersCount', {
          'event_category': 'Exam',
          'event_label': 'User Answerered Question',
          'email': user.email
        });        
      } else {
        console.error("gtag is not defined");
      }
  
      if(reviewMode) {
        await generateAndPlaySpeech(answer);
      };
      
    } catch (error) {
      console.error("Error processing answer:", error);
    }
  }, [generateAndPlaySpeech, user.email, reviewMode]);
  

  const sendChatMessage = useCallback((answer) => {
    if(!answer) return;
    setChatMessages((prevMessages) => [...prevMessages, { text: answer, sender: 'user' }]);
  }, []);

  const startExam = useCallback(() => {
    try {
      setIsExamStarted(true);
      setExamState('listening');
      setExamProgress(100 / updatedQA.length);
    } catch (error) {
      console.error("Error starting the exam:", error);
    }
  }, [updatedQA.length]);

  const handleCancelFinishExam = () => {
    setShowExamFinishConfirmation(false);
  };
  
  const retryCurrQuestion = useCallback(async () => {
    try {
      if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped')) return;
      
      //Stop speaking from previous question
      if(audioContextRef.current) {
        audioContextRef.current.suspend();
        
        setAiIconState('stopped');
      }
      
      await getFeedback({}, true);
      setExamState('listening');
      setFeedback(null);
      setShowChat(false);
    } catch (error) {
      console.error("Error retrying the question:", error);
    }
  }, [getFeedback, examState, aiIconState]);

  const handleFinishExam = useCallback(() => {
    if(examState === 'processing' || (aiIconState !== 'speaking' && aiIconState !== 'stopped') || examState === 'waiting') return;

    if(audioContextRef.current) {
      audioContextRef.current.suspend();
      
      setAiIconState('stopped');
    }

    setShowExamFinishConfirmation(true);
  }, [examState, aiIconState]);


  const isInProgress = !!(currentQA && currentQA.length > 0);

  return (
    <div className="Examcard-container">
      {showModal && <ChatModalFreeSubscription onClose={closeModalHandler} examCount={examCount} />}
      <ExamCardHeader
        isExamStarted={isExamStarted}
        handleConfirmFinishExam={handleConfirmFinishExam}
        saveExam={saveExam}
        currentQa={currentQuestion+1}
        documentID={documentID}
        estimatedVote={estimatedVote}
        isSaving={isSaving}
        isInProgress={isInProgress}
        audioRef={audioContextRef}
        examState={examState}
        aiIconState={aiIconState}
        feedback={feedback}
        setAiIconState={setAiIconState}
        isRecording={isRecording}
        reviewMode={reviewMode}
      />

      <main className={reviewMode ? "Examcard-container__examcard-main" : "Examcard-container__examcard-main-interrogation"}>
        <div className='Examcard-container__exam-container'>
          <ExamProgress
            isExamStarted={isExamStarted}
            qa={updatedQA}
            currentQA={currentQuestion}
            progress={examProgress}
          />

          <ExamContent
            examState={examState}
            isExamStarted={isExamStarted}
            qa={updatedQA}
            currentQA={currentQuestion}
            isRecording={isRecording}
            onSkipQuestion={skipQuestion}
            nextQuestion={nextQuestion}
            startExam={startExam}
            aiIconState={aiIconState}
            userAnswer={userAnswer}
            reviewMode={reviewMode}
            handleExamCompletion={handleExamCompletion}
          />

          {isFreeAccount && examCount >= 1 && null}

          {isFreeAccount && examCount < 1 && (
            <div className="ExamCard__footer">
              <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                setAiIconState={setAiIconState}
                setUserAnswer={setUserAnswer}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
                reviewMode={reviewMode}
                nextQuestion={nextQuestion}
                currentQuestion={currentQuestion}
                qa={updatedQA}
              />
            </div>
          )}

          {userPlan === "Premium" && (
            <div className="ExamCard__footer">
              <ChatCardForm
                isExamFinished={state.isExamFinished}
                messageID={state.messageID}
                onAddMessage={addMessage}
                onCreateFeedback={onCreateFeedback}
                onFeedbackError={deleteLoaderMessage}
                setIsRecording={setIsRecording}
                isRecording={isRecording}
                setExamState={setExamState}
                startExam={startExam}
                examState={examState}
                setShowChat={setShowChat}
                firstUserAnswer={firstUserAnswer}
                setAiIconState={setAiIconState}
                setUserAnswer={setUserAnswer}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={Array.from(state.messages)}
                showChat={showChat}
                isExamStarted={isExamStarted}
                reviewMode={reviewMode}
                nextQuestion={nextQuestion}
                qa={updatedQA}
                // current question index from 0
                currentQuestion={currentQuestion}
              />
            </div>
          )}

          {showExamFinishConfirmation && (
            <div className="ExamFinishToastContainer">
              <div className="ExamFinishToastContainer__examFinishToast">
                <h2 className="ExamFinishToastContainer__title">Conferma</h2>
                <p className="ExamFinishToastContainer__message">Vuoi andare direttamente al resoconto?</p>
                <div className="ExamFinishToastContainer__buttons">
                  <Button onClick={handleConfirmFinishExam} variant="primary" size="md">
                    Sì, termina l'esame
                  </Button>
                  <Button onClick={handleCancelFinishExam} variant="secondary" size="md">
                    No, continua l'esame
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <FeedbackCard
          isExamStarted={isExamStarted}
          feedback={feedback}
          nextQuestion={nextQuestion}
          retryCurrQuestion={retryCurrQuestion}
          handleFinishExam={handleFinishExam}
          examState={examState}
          aiIconState={aiIconState}
          isRecording={isRecording}
          currentQuestion={currentQuestion + 1}
          qa={updatedQA}
          reviewMode={reviewMode}
          saveExam={saveExam}
        />
      </main>
    </div>
  );
};

export default ExamCard;