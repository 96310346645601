import React from "react";
import useSignOut from "@hooks/useSignOut";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Dropdown from "@ui/dropdown/dropdown";
import Heading from "@ui/heading/heading";
import { PlayCircle, DollarSign, HelpCircle, LogOut, MessageSquarePlus } from "lucide-react";

import "./userDropdown.scss";

const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, l => l.toUpperCase());
};


const UserDropdown = React.memo(({ onClose }) => {
  const { logoutUserHandler } = useSignOut(onClose.bind(null, false));
  const { user } = useSelector((state) => state.auth);
  const displayName = user.fullName ? capitalizeWords(user.fullName) : "Guest";
  
  //Trigger function for GA4 event
  const handleReferralPageEvent = () => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'viewReferralPage', {
        'event_category': 'View',
        'event_label': 'view Referral Page',
        'email': user.email    
      });        
    } else {
      console.error("gtag is not defined");
    }
  };

  return (
    <Dropdown className="UserDropdown" aria-label="Menu utente">
      <Dropdown.ItemLink
        className="UserDropdown__item UserDropdown__item--profile"
        to="/home/profilo"
        onClick={() => onClose(false)}
        aria-label="Profilo utente"
      >
        <Heading title={displayName} heading="h5" />
        <p>{user.plan ? `Piano: ${user.plan}` : "Piano Gratuito"}</p>
      </Dropdown.ItemLink>
      <div className="UserDropdown__body">
        <Dropdown.Item
          className="UserDropdown__item"
          onClick={() => window.open("https://www.youtube.com/watch?v=0EbfTKC1HPI?si=oBAAwT8mkTOSGQHr&start=1", "_blank")}
          aria-label="Guarda il tutorial di StudierAI su YouTube"
        >
          <PlayCircle className="UserDropdown__item-icon" aria-hidden="true" size={20} strokeWidth={1.5} />
          <p className="UserDropdown__item-text">Video Tutorial</p>
        </Dropdown.Item>
        <Dropdown.ItemLink 
          className="UserDropdown__item"
          to="/home/referralCode" 
          aria-label="Guadagna 5 euro"
          onClick={handleReferralPageEvent}  
        >
          <DollarSign className="UserDropdown__item-icon" aria-hidden="true" size={20} strokeWidth={1.5} />
          <p className="UserDropdown__item-text">Guadagna 5&euro;</p>
        </Dropdown.ItemLink>
        <Dropdown.ItemLink 
          className="UserDropdown__item" 
          to="/home/feedback" 
          onClick={() => onClose(false)} 
          aria-label="Condividi le tue idee"
        >
          <MessageSquarePlus className="UserDropdown__item-icon" aria-hidden="true" size={20} strokeWidth={1.5} />
          <p className="UserDropdown__item-text">Condividi le tue idee</p>
        </Dropdown.ItemLink>
        <Dropdown.ItemLink className="UserDropdown__item" to="/assistenza" onClick={() => onClose(false)} aria-label="Richiedi assistenza">
          <HelpCircle className="UserDropdown__item-icon" aria-hidden="true" size={20} strokeWidth={1.5} />
          <p className="UserDropdown__item-text">Assistenza</p>
        </Dropdown.ItemLink>
      </div>
      <div className="UserDropdown__footer">
        <Dropdown.Item className="UserDropdown__item UserDropdown__item--logout" onClick={logoutUserHandler} aria-label="Esci dall'account">
          <LogOut className="UserDropdown__item-icon" aria-hidden="true" size={20} strokeWidth={1.5} />
          <p className="UserDropdown__item-text">Logout</p>
        </Dropdown.Item>
      </div>
    </Dropdown>
  );
});

UserDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
};

UserDropdown.displayName = 'UserDropdown';

export default UserDropdown;