import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getQuestions } from "@lib/firestoreQueries";

// Utility function to check if a question is a real flashcard
const isRealFlashcard = (qa) => {
  if (!qa) return false;
  
  // Per il nuovo formato, verifichiamo la presenza di card_question e card_answer
  if (qa.card_question && qa.card_answer) return true;
  
  // Per il formato intermedio, verifichiamo question e answer con metadata
  if (qa.question && qa.answer && qa.metadata?.isFlashcard === true) return true;
  
  // Per retrocompatibilità, manteniamo la vecchia logica
  if (!qa.question || !qa.answer) return false;
  
  // Se è un documento vecchio, consideriamo valide tutte le domande che hanno question e answer
  // e non sono esplicitamente marcate come domande d'esame
  const isNotExamQuestion = qa?.metadata?.isExamQuestion !== true;
  const hasQuestionAndAnswer = qa.question && qa.answer;
  
  return hasQuestionAndAnswer && isNotExamQuestion;
};

const useGetFlashcards = (idSelectedDocument, isLegacyFormat = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [flashcards, setFlashcards] = useState([]);

  // get flashcards by specific document id
  const getFlashcards = useCallback(async () => {
    try {
      if (!idSelectedDocument) return;

      setIsLoading(true);
      console.log('Fetching flashcards for document:', idSelectedDocument);
      
      const questions = await getQuestions(idSelectedDocument);
      console.log('Raw questions data:', questions);
      
      // Prima filtriamo per tipo di domanda
      const filteredQuestions = questions.docs
        .map(doc => {
          const data = doc.data();
          console.log('Processing question:', data);
          return data;
        })
        .filter(qa => {
          // Se è un documento vecchio (legacy), mostra tutte le domande valide
          if (isLegacyFormat) {
            return qa && qa.question && qa.answer && qa?.metadata?.isExamQuestion !== true;
          }
          
          // Per i nuovi documenti, verifichiamo se è una vera flashcard
          return isRealFlashcard(qa);
        })
        .map(qa => ({
          // Normalizza il formato della flashcard
          question: qa.card_question || qa.question,
          answer: qa.card_answer || qa.answer,
          metadata: {
            ...qa.metadata,
            isFlashcard: true
          },
          feedback: qa.feedback,
          tags: qa.tags || []
        }));

      console.log('Filtered questions:', filteredQuestions);

      // Poi rimuoviamo i duplicati usando un Set per tenere traccia delle domande già viste
      const seenQuestions = new Set();
      const uniqueQuestions = filteredQuestions.filter(qa => {
        // Verifichiamo che la domanda esista e sia una stringa
        if (!qa?.question || typeof qa.question !== 'string') {
          console.log('Invalid question format:', qa);
          return false;
        }
        
        // Normalizziamo la domanda (rimuoviamo spazi extra e rendiamo lowercase)
        const normalizedQuestion = qa.question.trim().toLowerCase();
        
        // Se la domanda non è stata vista, la aggiungiamo al set e la manteniamo
        if (!seenQuestions.has(normalizedQuestion)) {
          seenQuestions.add(normalizedQuestion);
          console.log('Adding unique question:', qa.question);
          return true;
        }
        console.log('Duplicate question found:', qa.question);
        return false;
      });

      console.log('Unique questions:', uniqueQuestions);
      
      // Filtriamo le domande segnalate come non utili
      const validQuestions = uniqueQuestions.filter(qa => {
        const isHelpful = qa.feedback?.isHelpful !== false;
        console.log('Question helpful status:', isHelpful, 'for:', qa.question);
        return isHelpful;
      });

      console.log('Final filtered questions:', validQuestions);
      
      setFlashcards(validQuestions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error in getFlashcards:', error);
      toast.error("Errore nel recupero delle flashcard");
      setIsLoading(false);
    }
  }, [idSelectedDocument, isLegacyFormat]);

  useEffect(() => {
    console.log('useGetFlashcards effect running with:', {
      idSelectedDocument,
      isLegacyFormat
    });
    getFlashcards();
  }, [getFlashcards, idSelectedDocument, isLegacyFormat]);

  return { isLoading, flashcards };
};

export default useGetFlashcards;
