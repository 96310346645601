import React, { useMemo, useState, useEffect } from "react";
import Slider from "react-slick";
import Documents from "@assets/images/documents.png";
import Flashcards from "@assets/images/flashcards.png";
import Chat from "@assets/images/chat.png";
import Resoconto from "@assets/images/resoconto.png"; 
import { motion } from "framer-motion";

import FunctionalityItem from "./functionalityItem/functionalityItem";

/* styles */
import "./functionality.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Functionality = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = useMemo(() => ({
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  }), []);

  const slides = useMemo(() => [
    {
      title: "Carica il tuo materiale",
      text: "Carica qualsiasi documento (PDF, DOCX,TXT...), foto di appunti, video e audio lezioni con un clic (in tutte le lingue). StudierAI li trasforma subito in un percorso di apprendimento su misura per te.",
      image: Documents
    },
    {
      title: "Ripassa i tuoi documenti",
      text: "Visualizza appunti, schemi, mappe e qualsiasi tipo di documento in Flashcard interattive, domande di ripetizione, domande a risposta multipla e riassunti per un ripasso efficace e veloce.",
      image: Flashcards
    },
    {
      title: "Simula l'esame orale",
      text: "StudierAI ti aiuta a perfezionare le tue risposte e a ridurre l'ansia per tutte le materie in qualsiasi lingua. Esercitati oralmente con interrogazioni simulate e ricevi feedback immediati.",
      image: Chat
    },
    {
      title: "Comprendi il tuo voto",
      text: "StudierAI ti offre a fine esame un resoconto mirato per aiutarti a correggere gli errori e a consolidare le tue conoscenze. Un'analisi concreta per risultati concreti.",
      image: Resoconto
    }
  ], []);

  return (
    <div id="functionality" className="Functionality">
      <div className="container">
        <motion.div
          initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.6 }}
        >
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <motion.div
                key={index}
                initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.2 }}
              >
                <FunctionalityItem
                  title={slide.title}
                  text={slide.text}
                  image={slide.image}
                />
              </motion.div>
            ))}
          </Slider>
        </motion.div>
      </div>
    </div>
  );
};

export default React.memo(Functionality);
