export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';
export const SET_EXTRACTED_QA = 'SET_EXTRACTED_QA';
export const SET_SUBMITTING_DOC = 'SET_SUBMITTING_DOC';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_PROCESSING_STATE = "SET_PROCESSING_STATE";
export const SET_GENERATE = "SET_GENERATE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_DOCUMENTS_QA = "SET_DOCUMENTS_QA";
export const SET_DOCUMENTS_TAGS = "SET_DOCUMENTS_TAGS";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_SUMMARY = "SET_SUMMARY";
export const SET_PROCESSED_NOTES = "SET_PROCESSED_NOTES";
export const DELETE__NOTE_FILE = "DELETE__NOTE_FILE";
export const RESET_PROCESSED_NOTES = "RESET_PROCESSED_NOTES";
export const SET_LIMIT_REACHED = "SET_LIMIT_REACHED";
export const SET_POPUP_VIEWD = "SET_POPUP_VIEWD"; 
export const UPGRAGE_CLICKED = "UPGRAGE_CLICKED";
export const LAST_POPUP_DATE = "LAST_POPUP_DATE";
export const POPUP_CLOSED = "POPUP_CLOSED";
export const SET_LIMIT_EXAMS_REACHED = "SET_LIMIT_EXAMS_REACHED";
export const SET_FLASHCARDS_QA = "SET_FLASHCARDS_QA";
export const SET_INTERROGATION_QA = "SET_INTERROGATION_QA";
export const SET_CURRENT_EXAM_ID = "SET_CURRENT_EXAM_ID";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_QUIZ_QA = "SET_QUIZ_QA";
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
