import { SET_CURRENT_STEP } from '../actions/types';

const initialState = {
  currentStep: null,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    default:
      return state;
  }
};

export default onboardingReducer;