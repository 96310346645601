import React, { useEffect, useState } from "react";
import "./chatCardForm.scss";
import PropTypes from "prop-types";
import useChatForm from "./hooks/useChatForm";
import useAudio from "./hooks/useAudio";

import Field from "@ui/field/field";
import AudioRecorder from "./audioRecorder/audioRecorder";

import AnswersControls from "../AnswersControls/AnswersControls";
import ExamChat from "../ExamChat/ExamChat";
import { MicOff, Mic, MessageCircle } from "lucide-react";
import { useCallback } from "react";
import { toast } from "react-toastify"; 
import { useDispatch } from "react-redux";
import { setInterrogationQA } from "../../../../redux/actions/examActions";

const ChatCardForm = ({ 
  isExamFinished,
  messageID, 
  onAddMessage, 
  onFeedbackError, 
  onCreateFeedback,
  firstUserAnswer,
  setUserAnswer,

  showChat,
  examState,
  isExamStarted,
  setShowChat,
  isRecording,
  startExam,
  setIsRecording,
  setExamState,
  setAiIconState,

  chatMessages,  
  inputRef,
  Send,
  sendChatMessage,
  processAnswer,
  messages,
  reviewMode,
  nextQuestion,
  currentQuestion,
  qa,
}) => {
  const { fetcher, register, handleSubmit, getFeedback } = useChatForm(
    messageID,
    onAddMessage,
    onFeedbackError,
    onCreateFeedback,
  );
  const { showAudio, showAudioRecorder, isPermissionGranted, hideAudioRecorder } = useAudio();

  const [localFeedback, setLocalFeedback] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("ChatCardForm rendered with props:", { isExamFinished, messageID });
    if(examState === 'processing') setAiIconState('processing');
  }, [isExamFinished, messageID, setAiIconState, examState]);

  useEffect(() => {
    if (fetcher.data) {
      setLocalFeedback(fetcher.data.feedback);
    }
  }, [fetcher.data, setAiIconState]);

  useEffect(() => {
    if (localFeedback) {
      processAnswer(localFeedback); 
      setLocalFeedback(null);
    }
  }, [localFeedback, processAnswer]);

  const saveInterrogationQA = (data) => {
    const index = currentQuestion;  
    const question = qa[index].question;
    const correct_answer = qa[index].answer;
    const answer = data.answer;  
    dispatch(setInterrogationQA({ index, question, correct_answer, answer }));
  };

  const handleGetFeedback = (data) => {
    // handle chat void submits
    if(!data.answer) return;
    if(!reviewMode) {
      
      // Save interrogationQA for review mode, to get final score
      saveInterrogationQA(data);
      
      nextQuestion();
    };
    
    console.log("Submitting feedback with data:", data);
    setUserAnswer(data.answer)
    sendChatMessage(data.answer);
    firstUserAnswer();
    
    /* if(reviewMode) {
    }; */
    setExamState('processing');
    getFeedback(data);
  };

  const toggleRecording = useCallback(() => {
    if (examState === 'listening' || examState === 'waiting') {
      try {
        if (!isPermissionGranted) {
          toast.error("Permessi microfono disabilitati. Per favore abilita l'accesso al microfono nelle impostazioni del tuo browser.");
          return;
        }

        if (!isExamStarted) {
          setIsRecording(true);
          startExam();
          showAudioRecorder();
        } else if (!isRecording && !showChat) {
          setIsRecording(true);
          setExamState('listening');
          showAudioRecorder();
        }
      } catch (error) {
        console.error("Error toggling recording:", error);
      }
    }
  }, [isExamStarted, isRecording, startExam, showAudioRecorder, setExamState, setIsRecording, showChat, examState, isPermissionGranted]);

  return (
    <fetcher.Form
      className={showChat && !isRecording ? "ChatCardForm" : "chat-inactive"}
      onSubmit={handleSubmit(handleGetFeedback)}
      noValidate
    >
      <Field className={isExamFinished ? "ChatCardForm__field" : ""}>
        <Field.Label className="ChatCardForm__label" htmlFor="answer" />
        <div className="ChatCardForm__group">
          {!showAudio ? (
            <>
              <AnswersControls
                showChat={showChat}
                examState={examState}
                isExamStarted={isExamStarted}
                setShowChat={setShowChat}
                isRecording={isRecording}
                MessageCircle={MessageCircle}
                MicOff={MicOff}
                Mic={Mic}
                toggleRecording={toggleRecording}
                startExam={startExam}
              />

              <ExamChat
                showChat={showChat}
                chatMessages={chatMessages}
                inputRef={inputRef}
                Send={Send}
                sendChatMessage={sendChatMessage}
                processAnswer={processAnswer}
                messages={messages}
                handleGetFeedback={handleGetFeedback}
                isExamFinished={isExamFinished}
                messageID={messageID}
                onCreateFeedback={onCreateFeedback}
                onAddMessage={onAddMessage}
                onFeedbackError={onFeedbackError}
                
                isRecording={isRecording}
                toggleRecording={toggleRecording}
                examState={examState}
                getFeedback={getFeedback}
                register={register}
                handleSubmit={handleSubmit}
                firstUserAnswer={firstUserAnswer}
                nextQuestion={nextQuestion}
              />  
            </>
          ) : (
            <AudioRecorder 
            onHideAudioRecorder={hideAudioRecorder} 
            firstUserAnswer={firstUserAnswer}
            onFeedback={getFeedback} 
            onAddMessage={onAddMessage} 
            showChat={showChat}
            setShowChat={setShowChat}
            isRecording={isRecording}
            toggleRecording={toggleRecording}
            examState={examState}
            setExamState={setExamState}
            isExamStarted={isExamStarted}
            setIsRecording={setIsRecording}
            setUserAnswer={setUserAnswer}
            nextQuestion={nextQuestion}
            currentQuestion={currentQuestion}
            qa={qa}
            reviewMode={reviewMode}
            />
          )}        
        </div>
      </Field>
    </fetcher.Form>
  );
};

ChatCardForm.propTypes = {
  isExamFinished: PropTypes.bool.isRequired,
  messageID: PropTypes.string.isRequired,
  onAddMessage: PropTypes.func.isRequired,
  onCreateFeedback: PropTypes.func.isRequired,
  onFeedbackError: PropTypes.func.isRequired,
  firstUserAnswer: PropTypes.func.isRequired, 
};

export default ChatCardForm;