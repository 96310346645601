import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "@redux/firebase";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";

const useSignInGoogle = mode => {
  const signInWithGoogle = async () => {
    if (mode === "signUp") {
      localStorage.setItem("newUser", "true") 
    } 
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // trigger event for account creation
      if (mode === "signUp") {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'userSignUpGoogle', {
            'event_category': 'Account',
            'event_label': 'User Sign Up Google',
            'email': user.email
          });        

          const userData = {
            fullName: user.displayName,
            email: user.email,
            /* currentStep: 20, */
            documentsUploaded: 0,
            maxDocumentsUpload: 2,
            examsAttempted: 0,
            plan: "Base",
            // Add other necessary fields here
          };
          await setDoc(doc(firestore, "students", user.uid), userData);
        } else {
          console.error("gtag is not defined");
        }

        toast.success("Registrazione con Google avvenuta con successo!");
      } else {
        // trigger GA4 custom event for user googleLogin
          if (typeof window.gtag === 'function') {
            window.gtag('event', 'userGoogleLogin', {
              'event_category': 'User',
              'event_label': 'user google login',
              'email': user.email,
            });        
          } else {
            console.error("gtag is not defined");
          };

        toast.success("Login con Google avvenuto con successo!");
      }

    } catch (error) {
      console.error("Errore durante il login con Google: " + error.message);
    }
  };

  return {
    signInWithGoogle,
  };
};

export default useSignInGoogle;