import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { firestore } from "@redux/firebase";
import { collection, setDoc, doc, serverTimestamp, increment, updateDoc, deleteDoc, query, where, getDocs } from "firebase/firestore";
import { saveQAExam } from "@lib/firestoreQueries";
import { useDispatch } from "react-redux";
import { setCurrentExamID } from "../../../../redux/actions/examActions";
import { useSelector } from "react-redux";

const useSaveExam = (documentID, messages) => {
  const [isSaving, setIsSaving] = useState(false);
  const { state } = useLocation();
  const savedExamID = useSelector(state => state.exam.currentExamID);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveExam = async (status, questionsAnswered, saveType) => {
    try {
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }
      setIsSaving(true);

      const examsQuery = query(
        collection(firestore, "exam"),
        where("documentID", "==", state?.documentID || documentID),
        where("status", "==", "in-progress"),
      );

      const snapshot = await getDocs(examsQuery);
      const exams = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const currentExam = exams.find(exam => exam.examID === savedExamID);
      let examRef;

      console.log(currentExam)

      if (!currentExam) {
        // Create new document
        examRef = doc(collection(firestore, "exam"));
        // Save the examID in the redux store
        dispatch(setCurrentExamID(examRef.id))

        const examObject = {
          autoSave: saveType === 'auto-save',
          documentID: state?.documentID || documentID,
          examDifficulty: "",
          examEnd: serverTimestamp(),
          examID: examRef.id,
          status: 'in-progress',
          savedAtQuestionNumber: questionsAnswered+1,
        };
        await setDoc(examRef, examObject);
      } else {
        // Update existing document
        examRef = doc(firestore, "exam", currentExam.id);
        await updateDoc(examRef, {
          examEnd: serverTimestamp(),
          status: 'in-progress',
          savedAtQuestionNumber: questionsAnswered,
          autoSave: saveType === 'auto-save',
        });
      }

      await saveQAExam(examRef.id, state?.messages || messages);

      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(1),
      });

      setIsSaving(false);

      if(saveType !== 'auto-save') {
        navigate(-1);
      }

      return true;
    } catch (error) {
      console.error("Errore durante il salvataggio dell'esame:", error);
      setIsSaving(false);
      throw error;
    }
  };

  const deleteExam = async (documentID) => {
    try {
      setIsSaving(true);
      const userID = JSON.parse(localStorage.getItem("userId"));
      if (!userID) {
        throw new Error("User ID non trovato.");
      }

      // Elimina l'esame da Firestore
      const examRef = doc(firestore, "exam", documentID);
      await deleteDoc(examRef);

      // Aggiorna il contatore degli esami tentati (decrementa di 1)
      const documentRef = doc(firestore, "students", userID);
      await updateDoc(documentRef, {
        examsAttempted: increment(-1),
      });

      setIsSaving(false);
      return true;
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'esame:", error);
      setIsSaving(false);
      throw error;
    }
  };

  return { isSaving, saveExam, deleteExam };
};

export default useSaveExam;