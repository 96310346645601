import "./subscriptionModes.scss";

export const SubscriptionModes = ({ isAnnual, setIsAnnual, isUpgrade, setIsUpgrade }) => {

  const handleSwitchOn = () => {
    return isUpgrade === false ? setIsUpgrade(true) : isAnnual === false ? setIsAnnual(true) : null;
  };
  
  const handleSwitchOff = () => {
    return isAnnual ? setIsAnnual(false) : isUpgrade ? setIsUpgrade(false) : null ;
  };

  return (
    <div className="SubscriptionModes__sub-mode-container">
      <div className="SubscriptionModes__sub-mode-container__inner-container">
        <span onClick={handleSwitchOff} className={`SubscriptionModes__sub-mode-container__inner-container__mode ${(isAnnual === false || isUpgrade === false) && 'SubscriptionModes__sub-mode-container__inner-container__mode-active'}`}>{isUpgrade === undefined ? 'Mensile' : 'Il mio piano'}</span>
        <div onClick={handleSwitchOn} className={`SubscriptionModes__sub-mode-container__inner-container__mode ${(isAnnual || isUpgrade) && 'SubscriptionModes__sub-mode-container__inner-container__mode-active'}`}>
          {isUpgrade === undefined ? 'Annuale' : 'Aggiorna piano'}
          {isUpgrade === undefined && <span className="SubscriptionModes__sub-mode-container__inner-container__annual-sales">Offerta</span>}
        </div>
      </div>
    </div>
  )
};