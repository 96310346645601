import { ChevronRight, MessageSquareText, ChevronDown, ChevronUp } from "lucide-react";
import SpeakerIcon from "../SpeakerIcon/SpeakerIcon";
import './examContent.scss';
import { useState } from "react";

const ExamContent = ({ 
  isExamStarted, 
  examState,
  qa, 
  currentQA, 
  isRecording,
   onSkipQuestion, 
  nextQuestion, 
  startExam, 
  aiIconState, 
  userAnswer, 
  reviewMode,
  }) => {

  const [userAnswerOpen, setUserAnswerOpen] = useState(false);
  
  const handleSkipQuestion = () => {    
    if(examState === 'processing' || isRecording) return;
    
    onSkipQuestion();
    nextQuestion();
    startExam();
  } 

  return (
    <div className={`Exam-content ${examState === 'answered' ? 'answer-complete' : ''}`}>
      {(examState === 'waiting' || examState === 'listening' || examState === 'processing') && (
        <div className={`Exam-content__question-container ${ isExamStarted ? "question-active" : "question-inactive"}`}>
          <div className="Exam-content__question-container__question">
            {qa[currentQA].question}
          </div>
        </div>
      )}

      {examState === 'answered' 
        ?
          <>
            <SpeakerIcon 
              aiIconState={aiIconState} 
              examState={examState}  
            />
            
            {currentQA+1 === qa.length && aiIconState !== 'speaking' && aiIconState !== 'processing' 
              ? 
                <>
                <div className="completed">
                  <p className="completed-message">Esame Completato</p>
                  <p className="completed-submessage">
                    Ottimo lavoro! Hai completato tutte le domande.
                  </p>
                </div>
                
                 <div className={`user-answer-container ${userAnswerOpen ? 'active-container' : 'collapsed-container'}`} onClick={() => setUserAnswerOpen(prev => !prev)}>
                    <div className="user-answer-row">
                      <MessageSquareText className="user-answer-icon" size={26}/>
                      <span className="user-answer-subtitle">La tua Risposta</span>
                      {userAnswerOpen 
                        ? <ChevronUp className="user-answer-drop-icon" size={26}/>
                        : <ChevronDown className="user-answer-drop-icon" size={26}/>
                      }  
                    </div>
                  <p className={`user-answer  ${userAnswerOpen && 'answer-open'}`}>{userAnswer}</p>
                  </div>
                </>
              : 
                <>
                  <p className={reviewMode ? 'status-message' : 'status-message-interrogation'} >{
                    aiIconState === 'speaking'
                    ? 'Sto parlando'
                    : aiIconState === 'processing' 
                    ? 'Feedback Ricevuto!'
                    : ''
                  }</p>
                  
                  <div className={`user-answer-container ${userAnswerOpen && 'active-container'}`} onClick={() => setUserAnswerOpen(prev => !prev)}>
                    <div className="user-answer-row">
                      <MessageSquareText className="user-answer-icon" size={26}/>
                      <span className="user-answer-subtitle">La tua Risposta</span>
                      {userAnswerOpen 
                        ? <ChevronUp className="user-answer-drop-icon" size={26}/>
                        : <ChevronDown className="user-answer-drop-icon" size={26}/>
                      }  
                    </div>
                    <p className={`user-answer  ${userAnswerOpen && 'answer-open'}`}>{userAnswer}</p>
                  </div>
                </>
            }
          </>
          
        :
          <>
            {reviewMode && (
              <SpeakerIcon aiIconState={aiIconState} />
            )}
            <p className={`status-message ${isExamStarted ? 'exam-started' : 'exam-not-started'} ${!reviewMode ? 'status-message-interrogation' : ''}`}>
              {!isExamStarted
                ? 'Premi il microfono o la chat per iniziare'
                :  isRecording
                ? 'Ti sto ascoltando...'
                : examState === 'processing'
                ? 'Elaboro la tua risposta...'
                : 'Premi il microfono per rispondere o usa la chat'}
            </p> 
            {reviewMode && (
              <div className={`skip-container display-row ${isExamStarted ? 'active' : ''}`}>
                <span className={`skip-question ${isRecording || examState === 'processing' ? 'inactive' : ''}`} onClick={handleSkipQuestion}>Salta domanda</span>
                <ChevronRight className='skip-icon' size={20}/>
              </div>
            )}
          </>
      }

    </div>
  )
}

export default ExamContent;