import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { ChevronDown } from "lucide-react";
import { NAVIGATION_ITEMS } from "../constants";
import "./Dropmenudown.scss";

const HomeDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const items = NAVIGATION_ITEMS.home.items;

  const getSectionOffset = useCallback((sectionId) => {
    // Offset personalizzati per sezione
    const offsets = {
      'functionality': 150,      // Funzionalità richiede più spazio sopra
      'beta-testimonial-slider': 80,  // Recensioni va bene con offset standard
      'video-section': 20,      // Tutorial richiede più spazio
      'price': 10,             // Piani e prezzi richiede più spazio
      'faq': 5,               // FAQ richiede più spazio
      'studierai-stats': 30    // Statistiche richiede più spazio
    };
    return offsets[sectionId] || 80; // Default a 80 se non specificato
  }, []);

  const scrollToSection = useCallback((sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = getSectionOffset(sectionId);
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }, [getSectionOffset]);

  const handleClick = useCallback((scrollTo) => {
    setIsOpen(false);
    
    if (location.pathname !== '/') {
      // Se non siamo nella home, prima navighiamo alla home
      navigate('/');
      // Dopo la navigazione, scrolliamo alla sezione
      setTimeout(() => {
        scrollToSection(scrollTo);
      }, 100);
    } else {
      // Se siamo già nella home, scrolliamo direttamente
      scrollToSection(scrollTo);
    }
  }, [navigate, location.pathname, scrollToSection]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleTriggerClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(prev => !prev);
  }, []);

  return (
    <div 
      ref={dropdownRef}
      className="DropMenudown"
    >
      <button
        className={clsx(
          "DropMenudown__trigger",
          isOpen && "DropMenudown__trigger--active"
        )}
        onClick={handleTriggerClick}
        aria-expanded={isOpen}
      >
        {NAVIGATION_ITEMS.home.label}
        <ChevronDown 
          size={18}
          className={clsx(
            "DropMenudown__chevron",
            isOpen && "DropMenudown__chevron--rotated"
          )}
        />
      </button>

      <div 
        className={clsx(
          "DropMenudown__content",
          isOpen && "DropMenudown__content--visible"
        )}
      >
        <div className="DropMenudown__items">
          {items.map((item, idx) => (
            <button
              key={idx}
              onClick={() => handleClick(item.scrollTo)}
              className="DropMenudown__item"
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomeDropdown; 