import React from "react";
import PropTypes from "prop-types";
import useSignInGoogle from "./hook/useSignInGoogle";

import Button from "@ui/button/button";
import { FaGoogle } from "react-icons/fa";

/* styles */
import "./authButtons.scss";

const AuthButtons = ({ isSubmitting, mode = "signUp" }) => {
  const { signInWithGoogle } = useSignInGoogle(mode);

  return (
    <div className="AuthButtons">
      {/* Bottone "Registrati con Google" */}
      <Button
        disabled={isSubmitting}
        className="AuthButtons--googleButton"
        variant="secondary"
        size="md"
        onClick={signInWithGoogle}
      >
        <FaGoogle />
        {mode === "signUp" ? "Registrati con Google" : "Accedi con Google"}
      </Button>

      {/* Divisore con "OPPURE" */}
      <div className="AuthButtons__divider">
        <p className="AuthButtons__divider-text">OPPURE</p>
      </div>
    </div>
  );
};

/* PropTypes */
AuthButtons.propTypes = {
  isSubmitting: PropTypes.bool,
  mode: PropTypes.oneOf(["signUp", "signIn"]),
};

export default AuthButtons;
