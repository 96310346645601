import Logo from "@ui/logo/logo";
import Navbar from "@ui/navbar/navbar";
import { FileSearch, Sparkles, Folders } from "lucide-react";
import UserAvatarDropdown from "./userAvatarDropdown/userAvatarDropdown";
import HamburgerMenu from "./hamburgerMenu/hamburgerMenu";
import UpgradePlan from "./upgradePlan/upgradePlan";
import Menu from "./menu/menu";

/* styles */
import "./header.scss";

/* // Onboarding
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import useGetOnboardingState from "../../../pages/uploadNotes/uploadNotesCard/uploadNotesForm/hooks/useGetOnboardingState"; */

const Header = () => {
  /* const currentStep = useSelector(state => state.onboarding.currentStep);
  const [stepsEnabled, setStepsEnabled] = useState(currentStep === 12);
  const { getCurrentStep, updateCurrentStep } = useGetOnboardingState();

  useEffect(() => {
    const handleResize = () => {
      setStepsEnabled(currentStep === 12);
    };

    getCurrentStep();
    handleResize(); // Check on mount

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [currentStep, getCurrentStep]);

  const steps = [
    currentStep === 12 && {
      element: window.innerWidth >= 992 ? '.materiale-navItem' : '.HamburgerMenu__button',
      intro: "Nella sezione Materiali puoi vedere i tuoi documenti caricati",
    }
  ].filter(Boolean);
  
  const onExit = async () => {
    if(currentStep === 12) {
      updateCurrentStep(14);
    };

    setStepsEnabled(false);
  }; */
  
  return (
    <header className="Header">
      {/* {stepsEnabled && (  
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={onExit}
        />  
      )} */}
      <div className="container">
        <div className="Header__row">
          <div className="Header__left">
            <Logo className="Header__logo" />
          </div>
          <Navbar className="Header__navbar">
            <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Carica Appunti"
              icon={<FileSearch size={20} />}
              to="/home"
              end
            />
            <Navbar.ItemIcon
              className="Header__navbar-navItem"
              title="Ripeti"
              icon={<Sparkles size={20} />}
              to="/home/ripeti"
              end
            />
            <Navbar.ItemIcon
              className="Header__navbar-navItem materiale-navItem"
              title="Materiale"
              icon={<Folders size={20} />}
              to="/home/materiale"
              end
            />
          </Navbar>
          <div className="Header__rightBox">
            <UserAvatarDropdown />
            <UpgradePlan className="Header__rightBox-upgradePlan" />
            <HamburgerMenu>
              <Menu />
            </HamburgerMenu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;