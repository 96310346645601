import React, { useEffect, useState, useCallback } from "react";
import "./landing.scss";

// Component imports
import Header from "./header/header";
import Hero from "./hero/hero";
import Functionality from "./functionality/functionality";
import Price from "./price/price";
import Faq from "./faq/faq";
import Footer from "./footer/footer";
import { BetaTestimonialSlider } from "./betaTestimonialSlider/betaTestimonialSlider";
import VideoSection from "./VideoSection/VideoSection";
import LandingMenu from "../../containers/landingMenu/landingMenu";
import StudierAIStats from "./StudierAIStats/StudierAIStats";

const Landing = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Memoize callback for better performance
  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  // Smooth scroll handling
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        element?.scrollIntoView({ behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Initial check
    
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  const MainContent = () => (
    <>
      <Header />
      <main>
        <Hero />
        <section id="functionality">
          <Functionality />
        </section>
        <section id="video-section">
          <VideoSection />
        </section>
        <section id="beta-testimonial-slider">
          <BetaTestimonialSlider />
        </section>
        <section id="price">
          <Price />
        </section>
        <section id="studierai-stats">
          <StudierAIStats />
        </section>
        <section id="faq">
          <Faq />
        </section>
      </main>
      <Footer />
      {isMenuOpen && (
        <div 
          className="menu-overlay" 
          role="dialog" 
          aria-modal="true"
        >
          <LandingMenu onClose={handleMenuClose} />
        </div>
      )}
    </>
  );

  return (
    <div className="Landing">
      <MainContent />
    </div>
  );
};

export default Landing;
