import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../redux/firebase";
import { setCurrentStep } from "../../../../../redux/actions/oboardingActions";
import { useDispatch } from "react-redux";

const useGetOnboardingState = () => {
  const dispatch = useDispatch();
  const updateCurrentStep = async (newStep) => {
    try {
      const userId = JSON.parse(localStorage.getItem("userId"));
      const userRef = doc(firestore, "students", userId);
      await updateDoc(userRef, { currentStep: newStep });
      dispatch(setCurrentStep(newStep));
    } catch (error) {
      console.error("Error updating current step:", error);
    }
  };

  const getCurrentStep = async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    const userRef = doc(firestore, "students", userId);
    const user = await getDoc(userRef);
    dispatch(setCurrentStep(user.data().currentStep));
  };

  return { updateCurrentStep, getCurrentStep };
};

export default useGetOnboardingState;