import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './materialsSummary.scss';
import useGetSummary from './hook/useGetSummary';
import Spinner from '@ui/spinner/spinner';
import { TbSection } from 'react-icons/tb';
import { HiDownload } from 'react-icons/hi';

const MaterialsSummary = ({ document }) => {
  const { isLoading, summary, isGenerating } = useGetSummary(document);
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef({});

  // Formatta il testo per una migliore leggibilità
  const formatText = (text) => {
    if (!text) return '';
    return text
      .replace(/\s+/g, ' ')
      .replace(/\s*([.,;:!?])\s*/g, '$1 ')
      .trim();
  };

  // Calcola le statistiche del riassunto
  const calculateStats = () => {
    if (!summary) return { readingTime: 0, charCount: 0, sectionsCount: 0 };

    // Funzione helper per contare i caratteri in modo accurato
    const countChars = (text) => {
      if (!text) return 0;
      // Rimuove spazi e newline, ma mantiene la punteggiatura per una stima più accurata
      return text.trim().length;
    };

    // Calcola i caratteri dell'abstract
    const abstractChars = countChars(summary.abstract);

    // Calcola i caratteri delle sezioni
    const sectionsChars = summary.sections?.reduce((acc, section) => 
      acc + countChars(section.content) + countChars(section.title), 0) || 0;
    
    // Calcola il totale dei caratteri
    const totalChars = abstractChars + sectionsChars;
    
    // Calcolo del tempo di lettura basato su 1200 caratteri al minuto (velocità media di lettura in italiano)
    // con un minimo di 1 minuto e arrotondamento al minuto superiore
    const readingTime = Math.max(1, Math.ceil(totalChars / 1200));

    return {
      readingTime,
      charCount: totalChars,
      sectionsCount: summary.sections?.length || 0
    };
  };

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    const element = sectionRefs.current[sectionId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Se sta caricando, mostra lo spinner
  if (isLoading) {
    return (
      <div className="MaterialsSummary">
        <div className="MaterialsSummary__loading">
          <Spinner size="sm" />
          <p>Caricamento riassunto...</p>
        </div>
      </div>
    );
  }

  // Se il summary è in generazione
  if (isGenerating) {
    return (
      <div className="MaterialsSummary">
        <div className="MaterialsSummary__loading">
          <Spinner size="sm" />
          <p>Generazione riassunto in corso...</p>
        </div>
      </div>
    );
  }

  // Se non c'è il documento, mostra subito il messaggio
  if (!document) {
    return (
      <div className="MaterialsSummary">
        <p className="MaterialsSummary__no-content">
          Riassunto non disponibile
        </p>
      </div>
    );
  }

  // Se non c'è il summary o non è nel formato corretto
  if (!summary) {
    console.debug('Summary not found:', { document, summary });
    return (
      <div className="MaterialsSummary">
        <p className="MaterialsSummary__no-content">
          Riassunto non disponibile
        </p>
      </div>
    );
  }

  const stats = calculateStats();

  const handleDownload = () => {
    if (!summary || typeof window === 'undefined') return;

    // Formatta il testo per il CSV rimuovendo caratteri speciali e migliorando la leggibilità
    const formatTextForCSV = (text) => {
      if (!text) return '';
      return text
        .replace(/\s+/g, ' ')          // Rimuove spazi multipli
        .replace(/\n+/g, ' ')          // Rimuove newline
        .replace(/\t+/g, ' ')          // Rimuove tab
        .replace(/"/g, '""')           // Escape delle virgolette per CSV
        .replace(/\s*([.,;:!?])\s*/g, '$1 ') // Formatta la punteggiatura
        .trim();
    };

    let csvRows = [];

    // Aggiungi l'intestazione del documento
    csvRows.push(['Riassunto del documento']);
    csvRows.push([]);  // Riga vuota per spaziatura

    // Aggiungi l'abstract se presente
    if (summary.abstract) {
      csvRows.push(['ABSTRACT']);
      csvRows.push([formatTextForCSV(summary.abstract)]);
      csvRows.push([]);  // Riga vuota per spaziatura
    }

    // Aggiungi le sezioni
    if (summary.sections?.length > 0) {
      csvRows.push(['SEZIONI']);
      summary.sections.forEach((section, index) => {
        csvRows.push([`Sezione ${index + 1}: ${formatTextForCSV(section.title)}`]);
        csvRows.push([formatTextForCSV(section.content)]);
        csvRows.push([]); // Riga vuota per spaziatura tra sezioni
      });
    }

    // Converti le righe in formato CSV
    const csvContent = csvRows
      .map(row => row.map(cell => `"${cell}"`).join(','))
      .join('\n');

    try {
      const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' }); // Aggiungo BOM per supporto Unicode
      const url = window.URL.createObjectURL(blob);
      const link = window.document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'riassunto.csv');
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore durante il download:', error);
    }
  };

  return (
    <div className="MaterialsSummary">
      {/* Stats Section */}
      <div className="MaterialsSummary__stats">
        <div className="MaterialsSummary__stats-header">
          <div 
            className="MaterialsSummary__stats-icon"
            onClick={handleDownload}
            title="Scarica riassunto"
          >
            <HiDownload size={24} />
          </div>
          <div className="MaterialsSummary__stats-info">
            <div className="MaterialsSummary__stats-details">
              <div className="MaterialsSummary__stats-item">
                ~{stats.readingTime} min
              </div>
              <div className="MaterialsSummary__stats-item">
                {stats.charCount.toLocaleString()} caratteri
              </div>
              <div className="MaterialsSummary__stats-item">
                <TbSection size={20} />
                {stats.sectionsCount} sezioni
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="MaterialsSummary__wrapper">
        {/* Indice */}
        <div className="MaterialsSummary__index-container">
          <div className="MaterialsSummary__index">
            <h6 className="MaterialsSummary__index-title">Indice dei contenuti</h6>
            <div className="MaterialsSummary__index-list">
              {summary.abstract && (
                <div 
                  className={`MaterialsSummary__index-item ${activeSection === 'abstract' ? 'MaterialsSummary__index-item--active' : ''}`}
                  onClick={() => handleSectionClick('abstract')}
                >
                  Introduzione
                </div>
              )}
              {summary.sections?.map((section, index) => (
                <div 
                  key={index}
                  className={`MaterialsSummary__index-item ${activeSection === `section-${index}` ? 'MaterialsSummary__index-item--active' : ''}`}
                  onClick={() => handleSectionClick(`section-${index}`)}
                >
                  {section.title}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Contenuto */}
        <div className="MaterialsSummary__container">
          <div className="MaterialsSummary__content">
            {/* Mostra l'abstract se presente */}
            {summary.abstract && (
              <div 
                ref={el => sectionRefs.current['abstract'] = el}
                className="MaterialsSummary__abstract"
              >
                <h6 className="MaterialsSummary__section-title">Introduzione</h6>
                <div className="MaterialsSummary__text">
                  {formatText(summary.abstract)}
                </div>
              </div>
            )}
            
            {/* Mostra le sezioni se presenti */}
            {summary.sections?.length > 0 && (
              <div className="MaterialsSummary__sections">
                {summary.sections.map((section, index) => (
                  <div 
                    key={index}
                    ref={el => sectionRefs.current[`section-${index}`] = el}
                    className="MaterialsSummary__section"
                  >
                    <h6 className="MaterialsSummary__section-title">{section.title}</h6>
                    <div className="MaterialsSummary__text">
                      {formatText(section.content)}
                    </div>
                    {section.visuals && (
                      <div className="MaterialsSummary__visuals">
                        <p>{formatText(section.visuals)}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MaterialsSummary.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    sectionsCount: PropTypes.number,
    summary: PropTypes.shape({
      abstract: PropTypes.string,
      hasAbstract: PropTypes.bool,
      sections: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        visuals: PropTypes.string
      })),
      sectionsCount: PropTypes.number
    }),
    rawExamData: PropTypes.shape({
      summary: PropTypes.shape({
        abstract: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          visuals: PropTypes.string
        }))
      })
    }),
    rawFlashcards: PropTypes.shape({
      summary: PropTypes.shape({
        abstract: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          visuals: PropTypes.string
        }))
      })
    }),
    examExtractedQA: PropTypes.arrayOf(PropTypes.shape({
      summary: PropTypes.shape({
        abstract: PropTypes.string,
        sections: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string,
          content: PropTypes.string,
          visuals: PropTypes.string
        }))
      })
    }))
  })
};

export default MaterialsSummary; 