import React, { useEffect, useState } from "react";
import User from "@assets/icons/user.png";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import "./userAvatar.scss";

const UserAvatar = () => {
  const [userProfileImage, setUserProfileImage] = useState('');

  const getUserProfileImage = async () => { 
    try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
            throw new Error("User ID not found in localStorage.");
        }

        const studentRef = doc(firestore, "students", JSON.parse(userId));
        const docSnap = await getDoc(studentRef);

        // Check if the document exists
        if (!docSnap.exists()) {
            console.warn("No such document found in 'students' collection. Using default image.");
            setUserProfileImage(User); // Set to default image
            return; // Exit the function
        }

        // Safely extract data
        const data = docSnap.data();
        setUserProfileImage(data.profileImage || User); // Use default if profileImage is not set
    } catch (error) {
        console.error("Error fetching user profile image:", error.message);
        setUserProfileImage(User); // Set to default image on error
    }
  };
  
  useEffect(() => {
    getUserProfileImage();
  },);

  return <img className="UserAvatar" src={userProfileImage || User} alt="User" />
};

export default UserAvatar;
