import React, { useState, useEffect } from 'react';
import { collection, query, where, getCountFromServer } from 'firebase/firestore';
import { firestore } from '../../../../redux/firebase';
import './Testimonials.scss';

const CACHE_KEY = 'studentsCount';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const Testimonials = () => {
  const [userCount, setUserCount] = useState(() => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { count, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return count;
      }
    }
    return 0;
  });
  const [isLoading, setIsLoading] = useState(!userCount);

  useEffect(() => {
    const fetchStudentsCount = async () => {
      try {
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { count, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setUserCount(count);
            setIsLoading(false);
            return;
          }
        }

        setIsLoading(true);
        const studentsRef = collection(firestore, 'students');
        const q = query(studentsRef, where('email', '!=', null));
        const snapshot = await getCountFromServer(q);
        const count = snapshot.data().count;

        setUserCount(count);
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          count,
          timestamp: Date.now()
        }));
        setIsLoading(false);
      } catch (error) {
        console.error('Errore nel conteggio degli studenti:', error);
        setIsLoading(false);
      }
    };

    fetchStudentsCount();

    const intervalId = setInterval(fetchStudentsCount, CACHE_DURATION);
    return () => clearInterval(intervalId);
  }, []);

  const stars = Array(5).fill().map((_, i) => (
    <span key={i} className="star">★</span>
  ));

  return (
    <div className="Testimonials">
      <div className="Testimonials__stars">
        {stars}
      </div>
      <div className="Testimonials__text">
        {isLoading ? (
          <div className="Testimonials__loading">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        ) : (
          <>
            <strong>+{userCount.toLocaleString('it-IT')}</strong> Studenti si preparano al meglio l'orale!
          </>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
