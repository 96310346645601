import React, { useState, useRef, useEffect, useCallback } from "react";
import { ChevronDown } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from "clsx";
import { NAVIGATION_ITEMS } from '../constants';

import "./Dropmenudown.scss";

const DropMenudown = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const items = NAVIGATION_ITEMS.altro.items;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside, { passive: true });
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, []);

  const handleClick = useCallback((to) => {
    if (location.pathname !== to) {
      navigate(to);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    setIsOpen(false);
    if (onClose) onClose();
  }, [location.pathname, navigate, onClose]);

  const handleTriggerClick = useCallback((e) => {
    if (e && e.type === 'click') {
      e.preventDefault();
      e.stopPropagation();
    }
    
    requestAnimationFrame(() => {
      setIsOpen(prev => !prev);
    });
  }, []);

  return (
    <div 
      ref={dropdownRef}
      className="DropMenudown"
    >
      <button
        className={clsx(
          "DropMenudown__trigger",
          isOpen && "DropMenudown__trigger--active"
        )}
        onClick={handleTriggerClick}
        aria-expanded={isOpen}
        aria-haspopup="menu"
        type="button"
      >
        {NAVIGATION_ITEMS.altro.label}
        <ChevronDown 
          size={18}
          className={clsx(
            "DropMenudown__chevron",
            isOpen && "DropMenudown__chevron--rotated"
          )}
          aria-hidden="true"
        />
      </button>

      <div 
        className={clsx(
          "DropMenudown__content",
          isOpen && "DropMenudown__content--visible"
        )}
        role="menu"
        aria-orientation="vertical"
      >
        <div className="DropMenudown__items">
          {items.map((item, idx) => (
            <button
              key={idx}
              onClick={() => handleClick(item.to)}
              className="DropMenudown__item"
              role="menuitem"
              type="button"
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DropMenudown);
