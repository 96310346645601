import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@ui/button/button';
import { TypewriterEffect } from './TypewriterEffect';
import Testimonials from './Testimonials/Testimonials';
import Banner from './Banner/Banner';
import AnimatedBackground from '../../../components/AnimatedBackground';
import { useEffect, useState } from 'react';

import './hero.scss';

const Hero = () => {
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const words = [
    { text: "Simula l'interrogazione " },
    { text: "Preparati all'esame" },
    { text: "Simula l'esame orale" },
    { text: "Migliora il tuo metodo" },
    { text: "Ottieni voti migliori" }
  ];

  return (
    <div className="Hero">
      {isDesktop && (
        <div className="Hero__background-wrapper">
          <AnimatedBackground />
        </div>
      )}
      <div className="container">
        <Banner />
        <div className="Hero__content">
          <TypewriterEffect
            words={words}
            className="Hero__typewriter"
            cursorClassName="Hero__cursor"
            typingSpeed={0.03}
          />
          <p className="Hero__text">
            Trasforma i tuoi documenti in simulazioni orali. Ricevi feedback immediati,voti su ogni interrogazione e molto altro.
          </p>
          <div className="Hero__cta-group">
            <Button 
              className="Hero__btn Hero__btn--primary" 
              size="lg" 
              variant="primary" 
              onClick={() => navigate("/signUp")}
            >
              Inizia Gratis Ora
            </Button>
          </div>
          <Testimonials />
        </div>
      </div>
    </div>
  );
};

export default Hero;
