import { ChevronDown } from "lucide-react";
import './questionSummary.scss';

const QuestionsSummary = ({
  totalQuestions,
  correctAnswers,
  partialAnswers,
  incorrectAnswers,
  skippedAnswers,
  colors,
  filter,
  setFilter,
  filteredQuestions,
  interrogationQA
}) => {
  return (
    <div className="QuestionsSummary">
      <div className="QuestionsSummary__section">
        <div className="QuestionsSummary__section-item">
          <p style={{ color: colors.text }}>Corrette</p>
          <p className="value value--green">{correctAnswers}</p>
        </div>
        <div className="QuestionsSummary__section-item">
          <p style={{ color: colors.text }}>Da Migliorare</p>
          <p className="value value--partial" style={{ color: colors.partial }}>{partialAnswers}</p>
        </div>
        <div className="QuestionsSummary__section-item">
          <p style={{ color: colors.text }}>Errate</p>
          <p className="value value--red">{incorrectAnswers}</p>
        </div>
        {interrogationQA.length === 0 && (
          <div className="QuestionsSummary__section-item">
            <p style={{ color: colors.text }}>Saltate</p>
            <p className="value value--default">{skippedAnswers}</p>
          </div>
        )}
      </div>

      <div className="QuestionsSummary__summary">
        <h3 className="QuestionsSummary__summary-title">Riepilogo Domande</h3>
        <p className="QuestionsSummary__summary-description">
          Rivedi le tue risposte e i feedback per migliorare. Concentrati sulle domande errate e da migliorare.
        </p>

        <div className="QuestionsSummary__summary-filter">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">Tutte le domande</option>
            <option value="correct">Corrette</option>
            <option value="partial">Da migliorare</option>
            <option value="incorrect">Errate</option>
            {interrogationQA.length === 0 && <option value="skipped">Saltate</option>}
          </select>
          <ChevronDown size={14} className="icon" />
        </div>

        <p className="QuestionsSummary__summary-stats">
          Domande visualizzate: {filteredQuestions.length} di {totalQuestions}
        </p>
      </div>
    </div>
  );
};

export default QuestionsSummary;
