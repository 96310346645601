import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useGetMultipleChoice from './hook/useGetMultipleChoice';
import MultipleChoice from './multipleChoice/multipleChoice';
import MaterialsFlashcardsNotFound from '../materialsFlashcards/materialsFlashcardsNotFound/materialsFlashcardsNotFound';
import Spinner from '@ui/spinner/spinner';
import './materialsMultipleChoice.scss';
import { HiDownload, HiX } from 'react-icons/hi';

const MaterialsMultipleChoice = ({ idSelectedDocument, isLegacyFormat = true }) => {
  const { questions: updatedQuestions, isLoading } = useGetMultipleChoice(idSelectedDocument, isLegacyFormat);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [hasShownTooltip, setHasShownTooltip] = useState(false);

  // Salva lo stato del tooltip nel localStorage
  useEffect(() => {
    const tooltipShown = localStorage.getItem('multipleChoiceTooltipShown');
    if (tooltipShown) {
      setHasShownTooltip(true);
    }
  }, []);

  const handleTooltipSeen = () => {
    if (!hasShownTooltip) {
      setHasShownTooltip(true);
      localStorage.setItem('multipleChoiceTooltipShown', 'true');
    }
  };

  const handleDownload = () => {
    // Prepara i dati delle domande in formato CSV
    const csvContent = updatedQuestions.map(qa => {
      // Escape delle virgole e newline per il CSV
      const escapedQuestion = `"${qa.query_text.replace(/"/g, '""')}"`;
      const escapedAnswer = `"${qa.correct_choice.replace(/"/g, '""')}"`;
      const escapedJustification = `"${qa.justification.replace(/"/g, '""')}"`;
      const escapedChoices = qa.answer_choices.map(choice => `"${choice.replace(/"/g, '""')}"`).join(',');
      return `${escapedQuestion},${escapedChoices},${escapedAnswer},${escapedJustification},${qa.difficulty_level}`;
    }).join('\n');

    const header = 'Domanda,Opzione 1,Opzione 2,Opzione 3,Opzione 4,Risposta Corretta,Spiegazione,Difficoltà\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'domande_multiple_choice.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Calcola le statistiche delle domande
  const calculateStats = () => {
    if (!updatedQuestions.length) return { total: 0, byDifficulty: {} };

    const stats = updatedQuestions.reduce((acc, question) => {
      const difficulty = question.difficulty_level || 'medium';
      acc.byDifficulty[difficulty] = (acc.byDifficulty[difficulty] || 0) + 1;
      return acc;
    }, { total: updatedQuestions.length, byDifficulty: {} });

    return stats;
  };

  // Filtra le domande in base alla difficoltà selezionata
  const filteredQuestions = selectedDifficulty
    ? updatedQuestions.filter(q => q.difficulty_level === selectedDifficulty)
    : updatedQuestions;

  // Gestisce il click su un badge di difficoltà
  const handleDifficultyClick = (difficulty) => {
    if (selectedDifficulty === difficulty) {
      setSelectedDifficulty(null);
    } else {
      setSelectedDifficulty(difficulty);
    }
    handleTooltipSeen();
  };

  // show spinner if is loading
  if (isLoading) {
    return <Spinner size='md' />;
  }

  // return select document if id selected document is null
  if (!idSelectedDocument) {
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />;
  }

  // return empty message if no questions found
  if (!isLoading && updatedQuestions.length === 0) {
    return (
      <MaterialsFlashcardsNotFound 
        message='Nessuna domanda a risposta multipla trovata nel documento selezionato'
      />
    );
  }

  const stats = calculateStats();

  return (
    <div className='MaterialsMultipleChoice'>
      {/* Stats Section */}
      <div className='MaterialsMultipleChoice__stats'>
        <div className='MaterialsMultipleChoice__stats-header'>
          <div 
            className='MaterialsMultipleChoice__stats-icon'
            onClick={handleDownload}
            title="Scarica domande"
          >
            <HiDownload size={24} />
          </div>
          <div className='MaterialsMultipleChoice__stats-info'>
            <span className='MaterialsMultipleChoice__stats-total'>
              {filteredQuestions.length} {filteredQuestions.length === 1 ? 'Domanda' : 'Domande'}
            </span>
            <div className='MaterialsMultipleChoice__stats-difficulties'>
              {Object.entries(stats.byDifficulty).map(([difficulty, count]) => (
                <button 
                  key={difficulty}
                  onClick={() => handleDifficultyClick(difficulty)}
                  onMouseEnter={handleTooltipSeen}
                  className={`MaterialsMultipleChoice__stats-difficulty MaterialsMultipleChoice__stats-difficulty--${difficulty} ${selectedDifficulty === difficulty ? 'MaterialsMultipleChoice__stats-difficulty--selected' : ''}`}
                  title={!hasShownTooltip ? "Clicca per filtrare le domande" : undefined}
                >
                  {count} {difficulty === 'easy' ? 'Facili' : difficulty === 'medium' ? 'Medie' : 'Difficili'}
                  {selectedDifficulty === difficulty && (
                    <HiX 
                      className="MaterialsMultipleChoice__stats-difficulty-clear"
                      title="Rimuovi filtro"
                    />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Questions Grid */}
      <div className='MaterialsMultipleChoice__container row gy-xs-1 g-md-1 g-lg-3'>
        {filteredQuestions.map((question, index) => (
          <MultipleChoice 
            key={index}
            queryText={question.query_text}
            answerChoices={question.answer_choices}
            correctChoice={question.correct_choice}
            justification={question.justification}
            difficultyLevel={question.difficulty_level}
          />
        ))}
      </div>
    </div>
  );
};

MaterialsMultipleChoice.propTypes = {
  idSelectedDocument: PropTypes.string,
  isLegacyFormat: PropTypes.bool
};

export default MaterialsMultipleChoice; 