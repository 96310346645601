import React, { useState } from 'react';
import './multipleChoice.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { BsQuestionCircle, BsCheckCircle, BsXCircle, BsLightbulb, BsArrowRepeat } from 'react-icons/bs';
import { useSelector } from 'react-redux';

const MultipleChoice = ({ queryText, answerChoices, correctChoice, justification, difficultyLevel }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const { user } = useSelector(state => state.auth);

  const handleAnswerSelect = (key) => {
    if (showFeedback) return;
    setSelectedAnswer(key);
    setShowFeedback(true);

    // Trigger GA4 custom event 
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'multipleChoiceAnswered', {
        'event_category': 'Documents',
        'event_label': 'Multiple Choice Answered',
        'email': user.email,
        'difficulty': difficultyLevel,
        'isCorrect': key === correctChoice
      });
    } else {
      console.error("gtag is not defined");
    }
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setSelectedAnswer(null);
    setShowFeedback(false);
  };

  const handleReveal = (e) => {
    e.stopPropagation();
    setShowFeedback(true);
    setSelectedAnswer(correctChoice);
  };

  const isAnswerCorrect = selectedAnswer === correctChoice;

  const getDifficultyLabel = (level) => {
    const labels = {
      easy: 'Facile',
      medium: 'Media',
      hard: 'Difficile'
    };
    return labels[level] || level;
  };

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <div className='MultipleChoice'>
        <div className='MultipleChoice__inner'>
          {/* Header with difficulty and controls */}
          <div className='MultipleChoice__header'>
            <div className='MultipleChoice__header-left'>
              <BsQuestionCircle className='MultipleChoice__icon' />
              {!showFeedback ? (
                <button 
                  className='MultipleChoice__reveal-btn'
                  onClick={handleReveal}
                  title="Mostra risposta"
                >
                  <BsLightbulb />
                </button>
              ) : (
                <button 
                  className='MultipleChoice__reset-btn'
                  onClick={handleReset}
                  title="Riprova"
                >
                  <BsArrowRepeat />
                </button>
              )}
            </div>
            <div className='MultipleChoice__header-right'>
              <span className={clsx(
                'MultipleChoice__difficulty',
                `MultipleChoice__difficulty--${difficultyLevel}`
              )}>
                {getDifficultyLabel(difficultyLevel)}
              </span>
            </div>
          </div>

          {/* Question text */}
          <div className='MultipleChoice__question'>
            <p className='MultipleChoice__text'>{queryText}</p>
          </div>

          {/* Answer choices */}
          <div className='MultipleChoice__choices'>
            {Object.entries(answerChoices).map(([key, choice]) => {
              const cleanChoice = choice.replace(/^opzione\s+(?:uno|due|tre|quattro|[1-4])\s*[-:]\s*/i, '');
              
              return (
                <button
                  key={key}
                  className={clsx(
                    'MultipleChoice__choice',
                    selectedAnswer === key && 'MultipleChoice__choice--selected',
                    showFeedback && key === correctChoice && 'MultipleChoice__choice--correct',
                    showFeedback && selectedAnswer === key && selectedAnswer !== correctChoice && 'MultipleChoice__choice--incorrect'
                  )}
                  onClick={() => handleAnswerSelect(key)}
                  disabled={showFeedback}
                >
                  <span className='MultipleChoice__choice-text'>{cleanChoice}</span>
                  {showFeedback && (
                    <span className='MultipleChoice__choice-icon'>
                      {key === correctChoice ? (
                        <BsCheckCircle className='MultipleChoice__choice-icon--correct' />
                      ) : (
                        selectedAnswer === key && 
                        <BsXCircle className='MultipleChoice__choice-icon--incorrect' />
                      )}
                    </span>
                  )}
                </button>
              );
            })}
          </div>

          {/* Feedback section */}
          {showFeedback && (
            <div className={clsx(
              'MultipleChoice__feedback',
              isAnswerCorrect 
                ? 'MultipleChoice__feedback--correct'
                : 'MultipleChoice__feedback--incorrect'
            )}>
              <div className='MultipleChoice__feedback-header'>
                {isAnswerCorrect ? (
                  <>
                    <BsCheckCircle className='MultipleChoice__feedback-icon--correct' />
                    <span>Corretto!</span>
                  </>
                ) : (
                  <>
                    <BsXCircle className='MultipleChoice__feedback-icon--incorrect' />
                    <span>Non corretto</span>
                  </>
                )}
              </div>
              <p className='MultipleChoice__feedback-text'>
                {justification}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MultipleChoice.propTypes = {
  queryText: PropTypes.string.isRequired,
  answerChoices: PropTypes.objectOf(PropTypes.string).isRequired,
  correctChoice: PropTypes.string.isRequired,
  justification: PropTypes.string.isRequired,
  difficultyLevel: PropTypes.oneOf(['easy', 'medium', 'hard']).isRequired
};

export default MultipleChoice; 