import { SET_INTERROGATION_QA, SET_CURRENT_EXAM_ID } from './types';

export const setInterrogationQA = (interrogationQA) => ({
  type: SET_INTERROGATION_QA,
  payload: interrogationQA,
});

export const setCurrentExamID = (examID) => ({
  type: SET_CURRENT_EXAM_ID,
  payload: examID,
});
