import React, { useEffect, useState } from "react";
import useSignUp from "./hook/useSignUp";

import Field from "@ui/field/field";
import Input from "@ui/input/input";
import PasswordToggle from "@containers/passwordToggle/passwordToggle";
import AuthButtons from "@containers/authButtons/authButtons";
import Button from "@ui/button/button";
import "./signUpForm.scss";

const SignUpForm = () => {
  const { Form, register, errors, isSubmitting, handleSubmit, submit, showPassword, handleShowPassword, watch } = useSignUp();
  const [validFields, setValidFields] = useState({
    fullName: false,
    email: false,
    password: false
  });

  // Monitora i cambiamenti nei campi
  useEffect(() => {
    const subscription = watch((formData) => {
      setValidFields({
        fullName: formData.fullName?.length > 0 && !errors?.fullName,
        email: formData.email?.length > 0 && !errors?.email,
        password: formData.password?.length > 0 && !errors?.password
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, errors]);

  return (
    <Form
      className="SignUpForm"
      onSubmit={handleSubmit(data => submit(data, { method: "post", action: "/signUp" }))}
      noValidate
    >
      {/* Bottone "Registrati con Google" */}
      <AuthButtons isSubmitting={isSubmitting} mode="signUp" />

      {/* Campi del modulo */}
      <Field className="SignUpForm__field">
        <Field.Label 
          label="Nome completo" 
          htmlFor="fullName" 
          required 
          isValid={validFields.fullName}
        />
        <Input 
          id="fullName" 
          name="fullName" 
          type="text" 
          placeholder="Mario Rossi" 
          {...register("fullName", {
            onChange: () => {
              const value = watch("fullName");
              setValidFields(prev => ({
                ...prev,
                fullName: value?.length > 0 && !errors?.fullName
              }));
            }
          })} 
        />
        {errors?.fullName && <Field.Error error={errors.fullName.message} />}
      </Field>
      <Field className="SignUpForm__field">
        <Field.Label label="Grado di studio" htmlFor="study" />
        <select className="SignUpForm__field--select" {...register("study")}>
          <option value="" hidden>
            Seleziona Grado
          </option>
          <option value="Medie">Medie</option>
          <option value="Superiori">Superiori</option>
          <option value="Università">Università</option>
        </select>
      </Field>
      <Field className="SignUpForm__field">
        <Field.Label 
          label="Email" 
          htmlFor="email" 
          required 
          isValid={validFields.email}
        />
        <Input 
          id="email" 
          name="email" 
          type="email" 
          placeholder="Indirizzo email" 
          {...register("email", {
            onChange: () => {
              const value = watch("email");
              setValidFields(prev => ({
                ...prev,
                email: value?.length > 0 && !errors?.email
              }));
            }
          })} 
        />
        {errors?.email && <Field.Error error={errors.email.message} />}
      </Field>
      <Field className="SignUpForm__field SignUpForm__field--password">
        <Field.Label 
          label="Password" 
          htmlFor="password" 
          required 
          isValid={validFields.password}
        />
        <div className="SignUpForm__field-box">
          <Input
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="*********"
            {...register("password", {
              onChange: () => {
                const value = watch("password");
                setValidFields(prev => ({
                  ...prev,
                  password: value?.length > 0 && !errors?.password
                }));
              }
            })}
          />
          <PasswordToggle isVisibility={showPassword} onVisibility={handleShowPassword} />
          {errors?.password && <Field.Error error={errors.password.message} />}
        </div>
      </Field>

      {/* Checkbox */}
      <div className="SignUpForm__box">
        <label htmlFor="policyAccepted">
          <Input type="checkbox" id="policyAccepted" {...register("policyAccepted")} />
          Ho letto e accetto i{" "}
          <a href="https://www.iubenda.com/termini-e-condizioni/73904869" target="_blank" rel="noopener noreferrer">
            Termini e Condizioni
          </a>{" "}
          e{" "}
          <a href="https://www.iubenda.com/privacy-policy/73904869" target="_blank" rel="noopener noreferrer">
            Privacy policy
          </a>
        </label>
        <label htmlFor="emailUseConsent">
          <Input type="checkbox" id="emailUseConsent" defaultChecked={true} {...register("emailUseConsent")} />
          Non perderti codici sconto esclusivi e aggiornamenti sulle nuove funzionalità di StudierAI!
        </label>
      </div>

      {/* Bottone "Crea Utente" */}
      <Button
        disabled={isSubmitting}
        type="submit"
        size="md"
        variant="primary"
        className="SignUpForm__createUserButton"
      >
        Crea Utente
      </Button>
    </Form>
  );
};

export default SignUpForm;


