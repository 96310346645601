import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronDown, ChevronUp } from "lucide-react";

import Logo from "@ui/logo/logo";
import { IoMdClose } from "react-icons/io";

/* styles */
import "./landingMenu.scss";

const LandingMenu = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isPerChiOpen, setIsPerChiOpen] = useState(false);
  const [isAltroOpen, setIsAltroOpen] = useState(false);

  const handleNavigate = (path, sectionId) => {
    if (location.pathname !== '/' && path.startsWith('/#')) {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          const headerOffset = 80;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }, 500);
    } else {
      navigate(path);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    onClose();
  };

  useEffect(() => {
    const mainHeader = document.querySelector('.LandingHeader__content');
    if (mainHeader) mainHeader.style.display = 'none';
    const copilot = document.querySelector('co-pilot');
    if (copilot) copilot.style.display = 'none';

    return () => {
      if (mainHeader) mainHeader.style.display = '';
      if (copilot) copilot.style.display = 'block';
    };
  }, []);

  return (
    <div className="LandingMenu">
      <header className="LandingMenu__header">
        <Logo
          className="LandingMenu__header-logo"
        />
        <div className="LandingMenu__header-box" onClick={onClose}>
          <IoMdClose />
        </div>
      </header>
      
      <div className="LandingMenu__content">
        <div className="LandingMenu__nav-items">
          {/* Home Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsHomeOpen(!isHomeOpen)}
          >
            Home
            {isHomeOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isHomeOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#functionality', 'functionality')}
              >
                Funzionalità
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#beta-testimonial-slider', 'beta-testimonial-slider')}
              >
                Recensioni
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#video-section', 'video-section')}
              >
                Tutorial
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#faq', 'faq')}
              >
                FAQ
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/#studierai-stats', 'studierai-stats')}
              >
                Statistiche
              </div>
            </div>
          )}

          {/* Per Chi Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsPerChiOpen(!isPerChiOpen)}
          >
            Studenti
            {isPerChiOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isPerChiOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty')}
              >
                Università
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty/scuole')}
              >
                Scuole
              </div>
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/faculty/partner')}
              >
                Diventa Partner
              </div>
            </div>
          )}

          {/* Piani e Prezzi */}
          <div 
            className="LandingMenu__nav-item"
            onClick={() => {
              navigate('/piani-e-prezzi');
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
              }, 100);
              onClose();
            }}
          >
            Piani e Prezzi
          </div>

          {/* Chi Siamo */}
          <div 
            className="LandingMenu__nav-item"
            onClick={() => handleNavigate('/chi-siamo')}
          >
            Chi Siamo
          </div>

          {/* Altro Dropdown */}
          <div 
            className="LandingMenu__nav-item LandingMenu__nav-item--dropdown"
            onClick={() => setIsAltroOpen(!isAltroOpen)}
          >
            Altro
            {isAltroOpen ? (
              <ChevronUp className="LandingMenu__chevron" />
            ) : (
              <ChevronDown className="LandingMenu__chevron" />
            )}
          </div>

          {isAltroOpen && (
            <div className="LandingMenu__dropdown-content">
              <div 
                className="LandingMenu__nav-item"
                onClick={() => handleNavigate('/assistenza')}
              >
                Contattaci
              </div>
            </div>
          )}
        </div>

        <div className="LandingMenu__auth">
          <button
            className="LandingMenu__auth-button LandingMenu__auth-button--login"
            onClick={() => handleNavigate('/signIn')}
          >
            Accedi
          </button>
          <button
            className="LandingMenu__auth-button LandingMenu__auth-button--register"
            onClick={() => handleNavigate('/signUp')}
          >
            Registrati Gratis
          </button>
        </div>
      </div>
    </div>
  );
};

LandingMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LandingMenu;
