import React, { useState, useEffect } from "react";
import "./index.scss";
import Price from "../landing/price/price";
import PricingFaq from "./pricingFaq/pricingFaq";
import Footer from "../landing/footer/footer";
import LandingHeader from "../landing/header/header";
import AnimatedBackground from "../../components/AnimatedBackground";
import { BetaTestimonialSlider } from "../landing/betaTestimonialSlider/betaTestimonialSlider";

const PricingPage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="PricingPage">
      {isDesktop && (
        <div className="PricingPage__background-wrapper">
          <AnimatedBackground />
        </div>
      )}
      <LandingHeader />
      <div className="PricingPage__content">
        <Price />
        <BetaTestimonialSlider />
        <PricingFaq />
      </div>
      <Footer />
    </div>
  );
};

export default PricingPage; 