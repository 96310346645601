import React, { useEffect, useRef } from "react";
import Button from "@ui/button/button";
import { ModalRoot } from "@ui/modal/modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, increment, updateDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "@redux/firebase";
import PropTypes from "prop-types";
import "./chatModalFreeSubscription.scss";
import { AlertCircle } from "lucide-react";

const ChatModalFreeSubscription = ({ onClose, examCount, uploadingDoc }) => {
  const navigate = useNavigate();
  const dialogRef = useRef(null);
  const { user } = useSelector(state => state.auth);
  const { popupViewed } = useSelector(state => state.leadScore);
  
  useEffect(() => {
    // trigger event for how many times popup was viewed
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'PopUpViewed', {
        'event_category': 'Account',
        'event_label': 'PopUp Viewed',
        'email': user.email,
        'popup_viewed': popupViewed+1,
      });        
    } else {
      console.error("gtag is not defined");
    };
    
    const updateUserLimits = async () => {
      try { 
        const userId = localStorage.getItem('userId');
        let userStats = doc(firestore, 'userStats', JSON.parse(userId));

        await updateDoc(userStats, {
          limitReached: increment(1),
          popupViewed: increment(1),
          lastPopupViewed: serverTimestamp(),
        });
        
        
      } catch(error) {
        console.error('Error fetching user stats', error);
      }
    };
    
    updateUserLimits();
  }, [popupViewed, user]);

  //Function GA4 event trigger 
  const handleTriggerEvent = () => {
    let event = uploadingDoc ? 'uploadDocToPremium' : 'ExamToPremium';  
    
      if (typeof window.gtag === 'function') {
        window.gtag('event', event, {
          'event_category': 'Account',
          'event_label': event,
          'email': user.email,
        });        
      } else {
        console.error("gtag is not defined");
      }

    navigate("/home/piani");
  };

  const handleClose = async () => {
    onClose();
    try { 
      const userId = localStorage.getItem('userId');
      let userStats = doc(firestore, 'userStats', JSON.parse(userId));

      await updateDoc(userStats, {
        popUpClosed: increment(1),
      });
      
    } catch(error) {
      console.error('Error fetching user stats', error);
    };
    
    // trigger event for how many times popup was closed
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'popUpClosed', {
        'event_category': 'Account',
        'event_label': 'popUpClosed',
        'email': user.email,
      });        
    } else {
      console.error("gtag is not defined");
    };
  };

  return (
    <ModalRoot.Modal ref={dialogRef} className="ChatModalFreeSubscription">
      <ModalRoot.Header
        className="ChatModalFreeSubscription__header"
        title="Limite Piano Gratuito"
        onClose={handleClose}
      />
      <ModalRoot.Content className="ChatModalFreeSubscription__content">
      {uploadingDoc && <p>Puoi caricare massimo 2 documenti componibili per il tuo piano gratuito.</p>}
        <div className={uploadingDoc && "ChatModalFreeSubscription__content-wrapper"}>
          <div className={uploadingDoc && "ChatModalFreeSubscription__content-text-wrapper"}>
            <p className={uploadingDoc && "ChatModalFreeSubscription__content-text"}>
              { uploadingDoc 
                ? 'File da caricare'
                
                : `Hai completato 1 esame. Per continuare ad usare StudierAI, abbonati al piano premium o ripeti un'altro documento.`
              }
            </p>
            {uploadingDoc && <AlertCircle size={18} className="ChatModalFreeSubscription__alert-icon" />}
          </div>

         {uploadingDoc && 
            <div>
              <span className="ChatModalFreeSubscription__content-span">2/2</span>
            </div>
          }
        </div>
        <Button
          className="ChatModalFreeSubscription__button"
          size="md"
          variant="primary"
          onClick={handleTriggerEvent}
        >
          Aggiorna il tuo piano
        </Button>
      </ModalRoot.Content>
    </ModalRoot.Modal>
  );
};

// chat modal free subscription props types
ChatModalFreeSubscription.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChatModalFreeSubscription;