import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./accordionItem.scss";

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <motion.div 
      className={`AccordionItem ${isOpen ? 'is-open' : ''}`}
      initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: isMobile ? 0 : 0.5 }}
    >
      <button 
        className="AccordionItem__question" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <motion.span 
          className="AccordionItem__icon"
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: isMobile ? 0 : 0.3 }}
        >
          ▼
        </motion.span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={isMobile ? { height: "auto", opacity: 1 } : { height: 0, opacity: 0 }}
            animate={isMobile ? { height: "auto", opacity: 1 } : { height: "auto", opacity: 1 }}
            exit={isMobile ? { height: 0, opacity: 0 } : { height: 0, opacity: 0 }}
            transition={{ duration: isMobile ? 0 : 0.3 }}
            style={{ overflow: "hidden" }}
          >
            <p className="AccordionItem__answer">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AccordionItem;