import React, { useState, useEffect } from "react";
import Heading from "@ui/heading/heading";
import "./faq.scss";
import Accordion from "./accordion/accordion";
import { motion } from "framer-motion";

const Faq = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <motion.section 
      id="faq" 
      className="Faq"
      initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: isMobile ? 0 : 0.6 }}
    >
      <div className="container">
        <div className="row justify-center g-3 g-lg-4">
          <div className="col-lg-8">
            <motion.div
              initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.2 }}
            >
              <Heading title="Domande Frequenti" heading="h3" className="Faq__title" />
              <p className="Faq__subtitle">Trova risposte alle domande più comuni su StudierAI</p>
            </motion.div>
          </div>
          <motion.div 
            className="col-lg-8"
            initial={isMobile ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            whileInView={isMobile ? { opacity: 1, y: 0 } : { opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: isMobile ? 0 : 0.6, delay: isMobile ? 0 : 0.3 }}
          >
            <Accordion />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Faq;