import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  GraduationCap,
  BookOpen,
  PencilRuler,
  School,
  Calculator,
  Trophy,
  BookMarked,
  Backpack,
  Glasses,
  Globe2,
  BookCheck,
  Presentation,
  Microscope,
  Atom,
  LibraryBig
} from 'lucide-react';

// Costanti di configurazione
const ICON_DENSITY = 50;    // Numero totale di icone
const MIN_DISTANCE = 12;    // Distanza minima tra icone
const MAX_DISTANCE = 20;    // Distanza massima di influenza del mouse
const REPEL_STRENGTH = 0.5; // Forza di repulsione del mouse
const ANIMATION_SPEED = 1.0; // Modificatore di velocità generale (1.0 = normale, 2.0 = doppia velocità, 0.5 = metà velocità)

const AnimatedBackground = () => {
  const [icons, setIcons] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [time, setTime] = useState(Date.now());

  const availableIcons = useMemo(() => [
    // Verdi primari
    { Icon: GraduationCap, color: '#349559', size: 52 },  // primary-500
    { Icon: BookOpen, color: '#68b984', size: 48 },       // primary-400
    { Icon: School, color: '#247745', size: 50 },         // primary-600
    { Icon: BookMarked, color: '#88cd9e', size: 48 },     // primary-300
    // Gialli/Arancioni secondari
    { Icon: Trophy, color: '#fed049', size: 46 },         // secondary-300
    { Icon: Presentation, color: '#f89a08', size: 46 },   // secondary-500
    { Icon: Calculator, color: '#fee589', size: 42 },     // secondary-200
    { Icon: Globe2, color: '#db7304', size: 50 },         // secondary-600
    // Mix di entrambi
    { Icon: PencilRuler, color: '#b7e3c2', size: 44 },   // primary-200
    { Icon: Backpack, color: '#fff2c6', size: 46 },      // secondary-100
    { Icon: Glasses, color: '#1d5f39', size: 44 },        // primary-700
    { Icon: BookCheck, color: '#b65007', size: 48 },      // secondary-700
    { Icon: Microscope, color: '#daf1de', size: 48 },     // primary-100
    { Icon: Atom, color: '#fdbc22', size: 50 },           // secondary-400
    { Icon: LibraryBig, color: '#f0f9f2', size: 46 }     // primary-50
  ], []);

  const areas = useMemo(() => ({
    left: { weight: 0.35, x: [5, 30], y: [10, 90] },
    right: { weight: 0.35, x: [70, 95], y: [10, 90] },
    bottom: { weight: 0.3, x: [30, 70], y: [65, 95] }
  }), []);

  const handleMouseMove = useCallback((e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setMousePosition({ x, y });
  }, []);

  const generateIcons = useCallback(() => {
    // Funzione spostata fuori dal loop e ottimizzata
    const isOverlapping = (newPos, existingPositions) => {
      return existingPositions.some(pos => {
        const dx = newPos.x - pos.x;
        const dy = newPos.y - pos.y;
        return Math.sqrt(dx * dx + dy * dy) < MIN_DISTANCE;
      });
    };

    const generateValidPosition = (existingPositions) => {
      let attempts = 0;
      let position;
      
      do {
        // Selezione dell'area basata sui pesi
        const rand = Math.random();
        let selectedArea;
        let cumWeight = 0;
        
        for (const [area, config] of Object.entries(areas)) {
          cumWeight += config.weight;
          if (rand <= cumWeight) {
            selectedArea = area;
            break;
          }
        }

        const areaConfig = areas[selectedArea];
        const x = areaConfig.x[0] + Math.random() * (areaConfig.x[1] - areaConfig.x[0]);
        const y = areaConfig.y[0] + Math.random() * (areaConfig.y[1] - areaConfig.y[0]);

        position = {
          x,
          y,
          rotation: Math.random() * 360,
          scale: 0.7 + Math.random() * 0.3,
          opacity: 0.6 + Math.random() * 0.2,
          floatAmplitude: 1.5 + Math.random() * 3,
          floatSpeed: (3000 + Math.random() * 2000) / ANIMATION_SPEED,
          rotateSpeed: (4000 + Math.random() * 2000) / ANIMATION_SPEED,
          rotateAmplitude: 2 + Math.random() * 3,
          phase: Math.random() * Math.PI * 2,
          pulseSpeed: (2000 + Math.random() * 2000) / ANIMATION_SPEED,
          pulsePhase: Math.random() * Math.PI * 2
        };
        
        attempts++;
      } while (
        attempts < 100 && 
        isOverlapping(position, existingPositions)
      );

      return position;
    };

    const positions = [];
    const newIcons = [];

    for (let i = 0; i < ICON_DENSITY; i++) {
      const position = generateValidPosition(positions);
      if (position) {
        positions.push(position);
        newIcons.push({
          ...position,
          id: Math.random().toString(36).substr(2, 9),
          iconIndex: i % availableIcons.length,
          baseX: position.x,
          baseY: position.y
        });
      }
    }

    return newIcons;
  }, [areas, availableIcons.length]);

  useEffect(() => {
    setIcons(generateIcons());
  }, [generateIcons]);

  useEffect(() => {
    const animate = () => {
      setTime(Date.now());
      requestAnimationFrame(animate);
    };
    const animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const getIconStyle = useCallback((icon) => {
    const dx = mousePosition.x - icon.baseX;
    const dy = mousePosition.y - icon.baseY;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Base style senza interazione mouse
    const floatX = Math.sin((time + icon.phase) / icon.floatSpeed) * icon.floatAmplitude;
    const floatY = Math.cos((time + icon.phase) / icon.floatSpeed) * icon.floatAmplitude;
    const rotateAngle = Math.sin((time + icon.phase) / icon.rotateSpeed) * icon.rotateAmplitude;
    const pulse = 1 + Math.sin((time + icon.pulsePhase) / icon.pulseSpeed) * 0.03;

    const baseStyle = {
      left: `${icon.baseX + floatX}%`,
      top: `${icon.baseY + floatY}%`,
      transform: `
        rotate(${icon.rotation + rotateAngle}deg)
        scale(${icon.scale * pulse})
      `,
      transition: 'left 0.15s ease-out, top 0.15s ease-out',
      zIndex: Math.floor(icon.scale * 10)
    };

    // Se l'icona è troppo lontana dal mouse, ritorna solo lo stile base
    if (distance > MAX_DISTANCE * 1.5) return baseStyle;

    // Calcola l'influenza del mouse con easing quadratico
    const influence = Math.pow(Math.max(0, 1 - distance / MAX_DISTANCE), 2);

    return {
      ...baseStyle,
      left: `${icon.baseX - dx * influence * REPEL_STRENGTH + floatX}%`,
      top: `${icon.baseY - dy * influence * REPEL_STRENGTH + floatY}%`
    };
  }, [mousePosition, time]);

  return (
    <div 
      className="absolute inset-0 overflow-hidden pointer-events-none"
      onMouseMove={handleMouseMove}
      style={{ height: '100%', width: '100%' }}
    >
      <style>{`
        @keyframes fade-in {
          from {
            opacity: 0;
            transform: scale(0.95);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }
        .animate-fade-in {
          animation: fade-in 1s ease-out forwards;
        }
      `}</style>
      <div className="relative w-full h-full">
        {icons.map((icon) => {
          const currentIcon = availableIcons[icon.iconIndex];
          const style = getIconStyle(icon);

          return (
            <div
              key={icon.id}
              className="absolute transition-all transform-gpu"
              style={style}
            >
              <currentIcon.Icon
                size={currentIcon.size}
                strokeWidth={1.3}
                style={{
                  color: currentIcon.color,
                  opacity: icon.opacity,
                  filter: `drop-shadow(0 0 15px ${currentIcon.color}40)`,
                  willChange: 'transform',
                  backfaceVisibility: 'hidden'
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnimatedBackground; 