import React from 'react';
import './question.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useFlipCard from '../../materialsFlashcards/flashcard/hook/useFlipCard';

import { BsQuestionCircle, BsReply } from 'react-icons/bs';
import useResetScroll from '../../materialsFlashcards/flashcard/hook/useResetScroll';
import { useSelector } from 'react-redux';

const Question = ({ question, answer }) => {
  const { flipCard, flip } = useFlipCard();
  const answerRef = useResetScroll(flipCard);
  const { user } = useSelector(state => state.auth);

  const handleQuestionFlip = () => {
    // Trigger GA4 custom event 
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'questionFlipped', {
        'event_category': 'Documents',
        'event_label': 'Question Flipped',
        'email': user.email
      });
    } else {
      console.error("gtag is not defined");
    }
    flip();
  };

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
      <div className={clsx('Question', flipCard && 'Question--flip')} onClick={handleQuestionFlip}>
        <div className='Question__inner'>
          <div ref={answerRef} className='Question__question'>
            <BsQuestionCircle className='Question__icon' />
            <p className='Question__text'>{question}</p>
          </div>
          <div ref={answerRef} className='Question__answer'>
            <BsReply className='Question__icon' />
            <p className='Question__text'>{answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
};

export default Question; 