import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../header/header';
import AnimatedBackground from '../../../components/AnimatedBackground';
import { 
  Scale, 
  Microscope, 
  Book, 
  TrendingUp,
  Bookmark,
  Calculator,
  Binary,
  GraduationCap,
  School,
  Handshake,
  Building2,
  Brain,
  Palette,
  Languages,
  BookOpen
} from 'lucide-react';
import './Faculty.scss';
import Footer from "../footer/footer";
import { useParams } from 'react-router-dom';

const Faculty = () => {
  const { tab } = useParams();
  const [activeCategory, setActiveCategory] = useState(
    tab === 'scuole' ? 'school' : 
    tab === 'partner' ? 'partner' : 
    'university'
  );
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [formData, setFormData] = useState({
    schoolName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useEffect(() => {
    setActiveCategory(
      tab === 'scuole' ? 'school' : 
      tab === 'partner' ? 'partner' : 
      'university'
    );
  }, [tab]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const universities = [
    {
      id: 'law',
      title: "Giurisprudenza",
      description: "Eccelli negli esami di diritto costituzionale e commerciale.",
      longDescription: "Preparati al meglio per gli esami di Diritto Civile, Penale, Costituzionale e Commerciale. StudierAI ti aiuta a memorizzare codici e procedure.",
      Icon: Scale,
      subjects: ["Diritto Civile", "Diritto Penale", "Diritto Costituzionale", "Diritto Commerciale"]
    },
    {
      id: 'medicine',
      title: "Medicina",
      description: "Rendi impeccabili le tue risposte su anatomia e farmacologia.",
      longDescription: "Padroneggia l'anatomia, la fisiologia e la farmacologia con il supporto dell'intelligenza artificiale. Preparati per gli esami orali con confidenza.",
      Icon: Microscope,
      subjects: ["Anatomia", "Fisiologia", "Farmacologia", "Patologia"]
    },
    {
      id: 'humanities',
      title: "Lettere e Filosofia",
      description: "Affronta con sicurezza esami di letteratura e storia dell'arte.",
      longDescription: "Dalla letteratura italiana alla filosofia antica, preparati per discussioni approfondite e analisi critiche dei testi più importanti.",
      Icon: Book,
      subjects: ["Letteratura Italiana", "Storia dell'Arte", "Filosofia", "Storia"]
    },
    {
      id: 'economics',
      title: "Economia",
      description: "Semplifica concetti complessi come macroeconomia e diritto commerciale.",
      longDescription: "Dall'economia aziendale alla finanza, StudierAI ti aiuta a comprendere e spiegare concetti complessi con chiarezza e precisione.",
      Icon: TrendingUp,
      subjects: ["Economia Aziendale", "Finanza", "Marketing", "Statistica"]
    },
    {
      id: 'engineering',
      title: "Ingegneria",
      description: "Padroneggia le materie teoriche e i concetti fondamentali.",
      longDescription: "Preparati per gli esami orali di materie teoriche come Scienza delle Costruzioni e Analisi Matematica con il supporto di StudierAI.",
      Icon: Calculator,
      subjects: ["Scienza delle Costruzioni", "Analisi Matematica", "Fisica Tecnica", "Meccanica Razionale"]
    },
    {
      id: 'political-science',
      title: "Scienze Politiche",
      description: "Eccelli nelle discussioni teoriche e nell'analisi critica.",
      longDescription: "Affronta con sicurezza gli esami orali di Relazioni Internazionali e Politica Comparata, sviluppando argomentazioni chiare e strutturate.",
      Icon: Building2,
      subjects: ["Relazioni Internazionali", "Politica Comparata", "Diritto Pubblico", "Storia delle Dottrine Politiche"]
    },
    {
      id: 'psychology',
      title: "Psicologia",
      description: "Padroneggia teorie e approcci psicologici.",
      longDescription: "Preparati per gli esami orali di Psicologia con StudierAI, che ti aiuta a comprendere e spiegare teorie complesse e casi clinici.",
      Icon: Brain,
      subjects: ["Psicologia Clinica", "Psicologia dello Sviluppo", "Neuropsicologia", "Psicologia Sociale"]
    },
    {
      id: 'education',
      title: "Scienze della Formazione",
      description: "Sviluppa competenze pedagogiche e didattiche.",
      longDescription: "Affronta con sicurezza gli esami orali di Pedagogia e Didattica, preparandoti a discutere teorie educative e approcci inclusivi.",
      Icon: GraduationCap,
      subjects: ["Pedagogia Generale", "Didattica Inclusiva", "Psicologia dell'Educazione", "Metodologie Educative"]
    }
  ];

  const schools = [
    {
      id: 'classical',
      title: "Liceo Classico",
      description: "Preparati con facilità a filosofia, latino e greco.",
      longDescription: "Dal greco al latino, dalla filosofia alla letteratura italiana, StudierAI ti supporta nella preparazione di ogni interrogazione.",
      Icon: Bookmark,
      subjects: ["Latino", "Greco", "Filosofia", "Letteratura"]
    },
    {
      id: 'scientific',
      title: "Liceo Scientifico",
      description: "Supera con successo matematica, chimica e fisica.",
      longDescription: "Affronta con sicurezza matematica, fisica e scienze. StudierAI ti aiuta a comprendere e spiegare anche i concetti più complessi.",
      Icon: Calculator,
      subjects: ["Matematica", "Fisica", "Chimica", "Scienze"]
    },
    {
      id: 'human-sciences',
      title: "Liceo Scienze Umane",
      description: "Eccelli nelle materie umanistiche e psicopedagogiche.",
      longDescription: "Preparati al meglio per le interrogazioni di psicologia, sociologia e pedagogia con il supporto di StudierAI.",
      Icon: Brain,
      subjects: ["Psicologia", "Sociologia", "Pedagogia", "Antropologia"]
    },
    {
      id: 'artistic',
      title: "Liceo Artistico",
      description: "Padroneggia la teoria dietro l'arte e il design.",
      longDescription: "Dalla storia dell'arte alle discipline geometriche, StudierAI ti aiuta a preparare la parte teorica delle tue materie.",
      Icon: Palette,
      subjects: ["Storia dell'Arte", "Discipline Geometriche", "Filosofia", "Teoria del Design"]
    },
    {
      id: 'linguistic',
      title: "Liceo Linguistico",
      description: "Perfeziona le tue competenze nelle lingue straniere.",
      longDescription: "Preparati per le interrogazioni in diverse lingue straniere, migliorando pronuncia e fluency con StudierAI.",
      Icon: Languages,
      subjects: ["Inglese", "Francese", "Tedesco/Spagnolo", "Letteratura Straniera"]
    },
    {
      id: 'technical',
      title: "Istituti Tecnici",
      description: "Domina le materie tecniche e commerciali.",
      longDescription: "Dalla programmazione all'economia aziendale, preparati al meglio per ogni verifica orale con il supporto di StudierAI.",
      Icon: Binary,
      subjects: ["Informatica", "Economia Aziendale", "Matematica", "Inglese"]
    },
    {
      id: 'commercial',
      title: "Istituto Tecnico Commerciale",
      description: "Eccelli nelle materie economiche e giuridiche.",
      longDescription: "Preparati al meglio per le interrogazioni di economia aziendale, diritto e scienze delle finanze con StudierAI.",
      Icon: BookOpen,
      subjects: ["Economia Aziendale", "Diritto", "Scienze delle Finanze", "Matematica Finanziaria"]
    }
  ];

  const FacultyCard = ({ id, title, description, longDescription, Icon, subjects }) => (
    <div className="faculty-card">
      <div className="faculty-card__inner">
        <div className="faculty-card__content">
          <div className="faculty-card__icon">
            <Icon size={32} strokeWidth={1.5} />
          </div>
          <h3 className="faculty-card__title">{title}</h3>
          <p className="faculty-card__description">
            {longDescription}
          </p>
          <div className="faculty-card__subjects">
            <h4 className="faculty-card__subjects-title">Materie principali:</h4>
            <div className="faculty-card__subjects-list">
              {subjects.map((subject, index) => (
                <span
                  key={index}
                  className="faculty-card__subject-tag"
                >
                  {subject}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const { schoolName, email, phone, message } = formData;
      const emailBody = `
Nome Istituto: ${schoolName}
Email: ${email}
Telefono: ${phone}
Messaggio: ${message}
      `;
      
      const studierAIEmail = `mailto:studierai.info@gmail.com?subject=Richiesta Partnership da ${schoolName}&body=${encodeURIComponent(emailBody)}`;
      window.open(studierAIEmail);
      
      setSubmitStatus('success');
      setFormData({
        schoolName: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="page-faculty">
      <Header />
      <div className="faculty-page">
        {/* Hero Section con Gradient Background */}
        <section className="faculty-page__hero">
          {isDesktop && (
            <div className="faculty-page__background-wrapper">
              <AnimatedBackground />
            </div>
          )}
          <div className="faculty-container">
            <motion.h1 
              className="faculty-page__title"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <span className="faculty-page__title-brand">StudierAI</span>: Il Tuo{' '}
              <span className="faculty-page__title-highlight">Alleato</span> per Ogni{' '}
              <span className="faculty-page__title-accent">Facoltà</span> e{' '}
              <span className="faculty-page__title-secondary">Istituto</span>
            </motion.h1>
            <motion.p 
              className="faculty-page__subtitle"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              Scopri come StudierAI ti aiuta a prepararti agli esami orali e a brillare nelle interrogazioni, ovunque tu studi
            </motion.p>
          </div>
        </section>

        <section className="faculty-page__content">
          <div className="faculty-container">
            <div className="faculty-page__category-buttons">
              <button
                onClick={() => setActiveCategory('university')}
                className={`faculty-page__category-button ${
                  activeCategory === 'university' ? 'faculty-page__category-button--active' : ''
                }`}
              >
                <GraduationCap size={20} strokeWidth={1.5} />
                Università
              </button>
              <button
                onClick={() => setActiveCategory('school')}
                className={`faculty-page__category-button ${
                  activeCategory === 'school' ? 'faculty-page__category-button--active' : ''
                }`}
              >
                <School size={20} strokeWidth={1.5} />
                Scuole
              </button>
              <button
                onClick={() => setActiveCategory('partner')}
                className={`faculty-page__category-button ${
                  activeCategory === 'partner' ? 'faculty-page__category-button--active' : ''
                }`}
              >
                <Handshake size={20} strokeWidth={1.5} />
                Diventa Partner
              </button>
            </div>

            {activeCategory === 'partner' ? (
              <div className="faculty-page__partner-container">
                <h2 className="faculty-page__partner-title">
                  Contattaci per informazioni o per un{' '}
                  <span className="faculty-page__partner-highlight">Preventivo Gratuito!</span>
                </h2>
                <form className="faculty-page__partner-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="schoolName">NOME DEL TUO ISTITUTO</label>
                    <input
                      type="text"
                      id="schoolName"
                      name="schoolName"
                      value={formData.schoolName}
                      onChange={handleChange}
                      required
                      placeholder="Nome della scuola o università"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">IL TUO INDIRIZZO EMAIL</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      placeholder="email@istituzione.it"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">NUMERO DI TELEFONO</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Inserire numero di telefono"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">MESSAGGIO</label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="Scrivi qui il tuo messaggio"
                      rows="4"
                      disabled={isSubmitting}
                    />
                  </div>

                  {submitStatus === 'success' && (
                    <div className="form-status form-status--success">
                      Richiesta inviata con successo! Ti contatteremo presto.
                    </div>
                  )}

                  {submitStatus === 'error' && (
                    <div className="form-status form-status--error">
                      Si è verificato un errore. Per favore riprova più tardi.
                    </div>
                  )}

                  <button 
                    type="submit" 
                    className="faculty-page__partner-submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Invio in corso...' : 'Invia richiesta'}
                  </button>
                </form>
              </div>
            ) : (
              <div className={`faculty-page__grid faculty-page__grid--${activeCategory}`}>
                {activeCategory === 'university'
                  ? universities.map((faculty) => <FacultyCard key={faculty.id} {...faculty} />)
                  : schools.map((school) => <FacultyCard key={school.id} {...school} />)
                }
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Faculty; 