import { useReducer, useCallback, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const generateUniqueId = () => '_' + Math.random().toString(36).substr(2, 9);

const calculateScore = (messages) => {
  let score = 0;
  messages.forEach(([_, messageArray]) => {
    messageArray.forEach(message => {
      if (message.sender === "user" && message.isFeedback) {
        score += message.score || 0;
      }
    });
  });
  return score;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE": {
      return {
        ...state,
        qa: action.payload.qa,
        messages: action.payload.messages,
        messageID: action.payload.messageID,
        isExamFinished: action.payload.isExamFinished,
        score: action.payload.score,
        currentQuestionIndex: action.payload.currentQuestionIndex,
      };
    }
    case "ADD_MESSAGE": {
      const updatedQA = state.qa?.filter(qa => qa.feedback?.isHelpful !== false) 
      const copyMessages = new Map(state.messages);

      // Check if the current question is the last one
      const isLastQuestion = state.currentQuestionIndex >= updatedQA.length - 1;

      console.log('ADDING MESSAGE:', copyMessages)
      const getCurrMessages = copyMessages.get(state.messageID) || [];
      if (!action.payload.id) {
        action.payload.id = generateUniqueId();
      }
      
      if ("isFeedback" in action.payload) {
        const lastMessage = getCurrMessages[getCurrMessages.length - 1];
        if (lastMessage?.text === "...") getCurrMessages.splice(-1, 1, action.payload);
        else getCurrMessages.push(action.payload);
        
        copyMessages.set(state.messageID, getCurrMessages);

        // Stop moving to the next question if this is the last one
        if (isLastQuestion) {
          const score = calculateScore(Array.from(copyMessages));
          return { ...state, messages: copyMessages, isExamFinished: true, score };
        };
        
        const nextQuestionIndex = state.currentQuestionIndex + 1;
        if (nextQuestionIndex < state.qa.length) {
          const nextQuestion = state.qa[nextQuestionIndex];
          const nextMessage = { id: nextQuestion.id, text: nextQuestion.question, sender: "bot" };
          copyMessages.set(nextQuestion.id, [nextMessage]);
          return { 
            ...state, 
            messages: copyMessages, 
            messageID: nextQuestion.id, 
            currentQuestionIndex: nextQuestionIndex 
          };
        } else {
          const score = calculateScore(Array.from(copyMessages));
          return { ...state, messages: copyMessages, isExamFinished: true, score };
        }
      }

      if (action.payload.type === "audio") {
        const updateMessages = [...getCurrMessages, action.payload];
        copyMessages.set(state.messageID, updateMessages);
        return { ...state, messages: copyMessages };
      }

      const lastMessage = getCurrMessages[getCurrMessages.length - 1];
      if (lastMessage?.sender === "user") getCurrMessages.splice(-1, 1);

      const updateMessages = [...getCurrMessages, action.payload, { id: generateUniqueId(), text: "...", sender: "bot" }];
      copyMessages.set(state.messageID, updateMessages);
      return { ...state, messages: copyMessages };
    }

    case "RETRY_QUESTION": {
      const copyMessages = new Map(state.messages);
    
      // Log before deletion
      console.log('MESSAGES BEFORE DELETE:', copyMessages);
    
      // Delete the current question's messages from the map (last question)
      if(copyMessages.size !== state.qa.length ) {
        copyMessages.delete(state.messageID);
      }
    
      // Identify the previous question ID and its messages
      const previousQuestionId = Array.from(copyMessages.keys()).pop();
      if (previousQuestionId) {
        const previousMessages = copyMessages.get(previousQuestionId) || [];
    
        // Remove the last two messages (feedback and user answer) if they exist
        if (previousMessages.length > 1) {
          previousMessages.pop(); // Remove feedback
          previousMessages.pop(); // Remove user's answer
        }
    
        // Update the previous question's messages in the map
        copyMessages.set(previousQuestionId, previousMessages);
    
        // Log after deletion
        console.log('MESSAGES AFTER DELETE:', copyMessages);
      }

      const currIndex = copyMessages.size !== state.qa.length ? state.currentQuestionIndex-1 : state.currentQuestionIndex;

      // Return the updated state
      return {
        ...state,
        messages: copyMessages,
        messageID: previousQuestionId || state.messageID,
        isExamFinished: false,
        currentQuestionIndex: currIndex
      };
    }
    
    case "SKIP_QUESTION": {
      const copyMessages = new Map(state.messages);
      const nextQuestionIndex = state.currentQuestionIndex + 1;
      
      if (nextQuestionIndex < state.qa.length) {
        const nextQuestion = state.qa[nextQuestionIndex];
        const nextMessage = { id: nextQuestion.id, text: nextQuestion.question, sender: "bot" };
        copyMessages.set(nextQuestion.id, [nextMessage]);
        return { 
          ...state, 
          messages: copyMessages, 
          messageID: nextQuestion.id, 
          currentQuestionIndex: nextQuestionIndex 
        };
      } else {
        const score = calculateScore(Array.from(copyMessages));
        return { ...state, isExamFinished: true, score };
      }
    }

    case "ERROR_ADD_MESSAGE": {
      const copyMessages = new Map(state.messages);
      const getCurrMessages = copyMessages.get(state.messageID) || [];
      const lastMessage = getCurrMessages[getCurrMessages.length - 1];

      if (lastMessage?.text === "...") getCurrMessages.splice(-1, 1);

      copyMessages.set(state.messageID, getCurrMessages);
      return { ...state, messages: copyMessages };
    }

    default:
      return state;
  }
};

export const useMessages = (qa, currentQA, documentID) => {
  const [state, dispatch] = useReducer(reducer, {
    qa: [],
    messages: new Map(),
    messageID: '',
    isExamFinished: false,
    score: 0,
    currentQuestionIndex: 0,
  });
  const [isFeedback, setIsFeedback] = useState(false);
  const [isStopDisabled, setIsStopDisabled] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const navigate = useNavigate();

  // Aggiungiamo i ref per gestire la registrazione
  const mediaRecorder = useRef(null);
  const stream = useRef(null);

  useEffect(() => {
    let initialMessages;
    let initialMessageID;
    let isExamFinished = false;
    let score = 0;
    let currentQuestionIndex = 0;

    if (currentQA) {
      initialMessages = new Map(currentQA);
      currentQuestionIndex = initialMessages.size - 1;
      initialMessageID = Array.from(initialMessages.keys())[currentQuestionIndex];
      isExamFinished = initialMessages.size === qa.length;
      score = calculateScore(Array.from(initialMessages));
    } else {
      initialMessages = new Map([[qa[0].id, [{ id: generateUniqueId(), text: qa[0]?.question, sender: "bot" }]]]);
      initialMessageID = qa[0]?.id;
    }

    dispatch({
      type: "INITIALIZE",
      payload: {
        qa,
        messages: initialMessages,
        messageID: initialMessageID,
        isExamFinished,
        score,
        currentQuestionIndex,
      },
    });
  }, [qa, currentQA]);

  const addMessage = useCallback(payload => {
    if (!payload.id) {
      payload.id = generateUniqueId();
    }
    dispatch({ type: "ADD_MESSAGE", payload });
  }, []);

  const deleteLoaderMessage = useCallback(() => {
    dispatch({ type: "ERROR_ADD_MESSAGE" });
  }, []);

  const retryQuestion = useCallback(() => {
    dispatch({ type: "RETRY_QUESTION" });
  }, []);

  const skipQuestion = useCallback(() => {
    dispatch({ type: "SKIP_QUESTION" });
  }, []);

  const onCreateFeedback = useCallback(() => setIsFeedback(prev => !prev), []);

  const examResult = useCallback((examID) => {
    const convertMapToArray = Array.from(state.messages);
    navigate("/risultato-esame", { state: { messages: convertMapToArray, documentID, score: state.score, qa: state.qa, examID: examID } });
  }, [state.messages, state.score, documentID, navigate, state.qa]);

  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.current = audioStream;
      mediaRecorder.current = new MediaRecorder(stream.current);
      mediaRecorder.current.start();
      setIsRecording(true);
      
      setIsStopDisabled(true);
      setTimeout(() => {
        setIsStopDisabled(false);
      }, 1000);

      mediaRecorder.current.ondataavailable = (event) => {
      };
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (isStopDisabled) return;
    
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      
      // Ferma tutti i track dello stream
      if (stream.current) {
        stream.current.getTracks().forEach(track => track.stop());
      }
    }
  };

  return {
    state,
    isFeedback,
    addMessage,
    deleteLoaderMessage,
    retryQuestion,
    skipQuestion,
    onCreateFeedback,
    examResult,
    isRecording,
    startRecording,
    stopRecording,
    isStopDisabled
  };
};

export default useMessages;