import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import useStickyHeader from "./hook/useStickyHeader";

import Logo from "@ui/logo/logo";
import Button from "@ui/button/button";
import NavHashItem from "@components/ui/navbar/navHashItem/navHashItem";
import HamburgerMenu from "@containers/layout/header/hamburgerMenu/hamburgerMenu";
import LandingMenu from "@containers/landingMenu/landingMenu";

// Importo i componenti dropdown
import HomeDropdown from "./DropMenudown/HomeDropdown";
import PerChiDropdown from "./DropMenudown/PerChiDropdown";
import DropMenudown from "./DropMenudown/Dropmenudown";

/* styles */
import "./header.scss";

const LandingHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSticky } = useStickyHeader();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigateToHome = () => {
    if (location.pathname === '/') {
      // Se siamo già nella home page, scorriamo alla sezione Hero
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    } else {
      // Se siamo in un'altra pagina, prima navighiamo alla home
      navigate('/');
      // Dopo la navigazione, assicuriamoci di scrollare in cima
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, 100);
    }
  };

  const handleChiSiamoClick = () => {
    navigate("/chi-siamo");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePianiPrezziClick = () => {
    navigate("/piani-e-prezzi");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
    document.body.classList.add("no-scroll");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <header
      className={clsx(
        "LandingHeader",
        isSticky && "LandingHeader--sticky",
        isMenuOpen && "LandingHeader--hidden"
      )}
    >
      <div className="container">
        <div className="LandingHeader__content">
          <div className="flex items-center">
            <div onClick={navigateToHome}>
              <Logo />
            </div>
            <nav className="LandingHeader__navbar">
              <HomeDropdown />
              <PerChiDropdown />
              <NavHashItem to="/piani-e-prezzi" onClick={handlePianiPrezziClick}>Piani e Prezzi</NavHashItem>
              <div onClick={handleChiSiamoClick} style={{ cursor: 'pointer' }} className="LandingHeader__navbar-item">Chi Siamo</div>
              <DropMenudown />
            </nav>
          </div>

          <div className="LandingHeader__right">
            <HamburgerMenu onClick={handleMenuOpen}>
              <LandingMenu onClose={handleMenuClose} />
            </HamburgerMenu>
            <Button
              size="sm"
              variant="primary"
              onClick={() => navigate("/signIn")}
              className="LandingHeader__sign-up-mobile"
            >
              Accedi
            </Button>
            <Button
              size="sm"
              variant="tertiary"
              onClick={() => navigate("/signIn")}
            >
              Accedi
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() => navigate("/signUp")}
            >
              Registrati Gratis
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
