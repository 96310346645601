import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaPlay } from 'react-icons/fa';
import './VideoContainer.scss';

const VideoContainer = ({ videoRef }) => {
  const localVideoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (videoRef) {
      videoRef.current = localVideoRef.current;
    }

    const loadYouTubeAPI = () => {
      return new Promise((resolve) => {
        if (window.YT) {
          resolve();
          return;
        }

        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        
        window.onYouTubeIframeAPIReady = () => {
          resolve();
        };

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      });
    };

    const initializePlayer = async () => {
      try {
        await loadYouTubeAPI();
        
        if (!window.YT || !localVideoRef.current) return;

        const newPlayer = new window.YT.Player(localVideoRef.current, {
          videoId: '0EbfTKC1HPI',
          playerVars: {
            autoplay: 0,
            controls: 1,
            rel: 0,
            modestbranding: 1,
            showinfo: 0,
            fs: 1,
            playsinline: 1,
            enablejsapi: 1,
            origin: window.location.origin
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange,
            'onError': (e) => {
              console.error('YouTube Player Error:', e);
              setIsPlaying(false);
            }
          }
        });
        
        setPlayer(newPlayer);
      } catch (error) {
        console.error('Error initializing YouTube player:', error);
        setIsPlaying(false);
      }
    };

    const onPlayerReady = (event) => {
      setIsPlayerReady(true);
      const qualities = event.target.getAvailableQualityLevels();
      const hdQuality = qualities.includes('hd1080') ? 'hd1080' : 
                       qualities.includes('hd720') ? 'hd720' : 
                       qualities[0];
      event.target.setPlaybackQuality(hdQuality);
    };

    const onPlayerStateChange = (event) => {
      // YT.PlayerState.PLAYING === 1
      if (event.data === 1) {
        setIsPlaying(true);
      } else if (event.data === 0 || event.data === 2) {
        // YT.PlayerState.ENDED === 0 or YT.PlayerState.PAUSED === 2
        setIsPlaying(false);
      }
    };

    initializePlayer();

    return () => {
      if (player?.destroy) {
        player.destroy();
      }
    };
  }, [videoRef]);

  const handlePlayClick = async () => {
    if (!player || !isPlayerReady) return;

    try {
      setIsPlaying(true); // Anticipiamo il cambio di stato
      await player.playVideo();
    } catch (error) {
      console.error('Error playing video:', error);
      setIsPlaying(false);
    }
  };

  return (
    <div className="VideoContainer">
      <div className="video-wrapper">
        <div ref={localVideoRef}></div>
        {!isPlaying && (
          <motion.div 
            className="play-button-overlay"
            onClick={handlePlayClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="play-button">
              <FaPlay className="play-icon" />
            </div>
            <span className="play-text">
              {isMobile ? "Tocca per guardare" : "Guarda il tutorial"}
            </span>
          </motion.div>
        )}
      </div>
    </div>
  );
};

VideoContainer.propTypes = {
  videoRef: PropTypes.object
};

export default VideoContainer;