import { useRef, useState, useEffect } from "react";
import Question from "../Question/Question";
import useGoBack from "../hook/useGoBack";
import useSaveExam from "@hooks/useSaveExam";
import { ArrowUp, Repeat, ArrowRight } from "lucide-react";
import "./examReportAnswers.scss";

const ExamReportAnswers = ({ filteredQuestions, colors, state, averageScore, interrogationQA }) => {
  const [openQuestion, setOpenQuestion] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { saveExam } = useSaveExam(state.documentID, state.messages);
  const { goToReview } = useGoBack();
  const scrollAreaRef = useRef(null);
  console.log(state.qa.filter(qa => qa.feedback?.isHelpful !== false && !qa.isFlashcard))
  const scrollToTop = () => {
    scrollAreaRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSaveExam = async () => {
    await saveExam("completed", averageScore);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollAreaRef.current) {
        const scrollPosition = scrollAreaRef.current.scrollTop;

        setShowScrollButton(scrollPosition > 50);
      }
    };

    const scrollArea = scrollAreaRef.current;
    scrollArea.addEventListener("scroll", handleScroll);

    return () => {
      scrollArea.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateId = (item) => {
    if(interrogationQA.length === 0) {
      return item.questionIndex;
    }  else {
      return item.index;
    }
  };

  /* const calculateQuestion = (item) => {
    if(interrogationQA.length === 0) {
      return item.messages[0]?.text || "Domanda non disponibile";
    }  else {
      return item.question;
    }
  }; */
  
  const calculateUserAnswer = (item) => {
    if(interrogationQA.length === 0) {
      return item.messages[1]?.text || "Risposta non disponibile";
    }  else {
      return item.messages[1]?.text;
    }
  };

  const calculateQuestionFeedback = (item) => {
    if(interrogationQA.length === 0) {  
      return item.messages[2]?.text.feedback || "Feedback non disponibile";
    }  else {
      return null;
    }
  };

  const calculateQuestionScore = (item) => {
    if(interrogationQA.length === 0) {
      return item.messages[3]?.score ? item.messages[3]?.score : item.messages[2]?.score || 'skipped';
    }  else {
      return item.score;
    }
  };

  return (
    <div className="ExamReportAnswers" id="exam-report-answers">
      <div className="ExamReportAnswers__scroll-area" ref={scrollAreaRef}>
        <ul className="ExamReportAnswers__question-list">
          {filteredQuestions.length > 0 ? (
            filteredQuestions.map((item) => {
              const id = calculateId(item);
              const question = state.qa.filter(qa => qa.feedback?.isHelpful !== false && !qa.isFlashcard)[id].question;
              const userAnswer = calculateUserAnswer(item);
              const feedback = calculateQuestionFeedback(item);
              const questionScore = calculateQuestionScore(item);
              const status =
                questionScore === "skipped"
                  ? "skipped"
                  : questionScore >= 7
                  ? "correct"
                  : questionScore > 5
                  ? "partial"
                  : "incorrect";

              return (
                <li key={id}>
                  <Question
                    colors={colors}
                    index={calculateId(item)}
                    userAnswer={userAnswer}
                    feedback={feedback}
                    question={question}
                    status={status}
                    openQuestion={openQuestion}
                    setOpenQuestion={setOpenQuestion}
                    averageScore={averageScore}
                  />
                </li>
              );
            })
          ) : (
            <p className="text-[1.2rem] text-gray-600">Nessuna domanda disponibile per il filtro selezionato.</p>
          )}
        </ul>
      </div>

      <div className={`ExamReportAnswers__scroll-button ${showScrollButton ? '' : 'ExamReportAnswers__scroll-button--hidden'}`}>
        <button onClick={scrollToTop}>
          <ArrowUp />
          <span>Torna su</span>
        </button>
      </div>

      <div className={`ExamReportAnswers__action-buttons ${interrogationQA.length > 0 && 'ExamReportAnswers__action-buttons--interrogation'}`}>
        {interrogationQA.length === 0 && (  
          <div onClick={goToReview}>
            <button>
              <Repeat />
              <span>Ripeti</span>
            </button>
          </div>
        )}

        <div onClick={handleSaveExam}>
          <button className="button button--next-exam">
            <span>Prossimo esame</span>
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExamReportAnswers;
