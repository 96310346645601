import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useGetFlashcards from './hook/useGetFlashcards'
import { Flashcard } from './flashcard/flashcard'
import MaterialsFlashcardsNotFound from './materialsFlashcardsNotFound/materialsFlashcardsNotFound'
import Spinner from '@ui/spinner/spinner'
import './materialFlashcards.scss';
import { HiDownload } from 'react-icons/hi';

const MaterialFlashcards = ({ idSelectedDocument, isLegacyFormat = true }) => {
  const { isLoading, flashcards } = useGetFlashcards(idSelectedDocument, isLegacyFormat)

  // Sort flashcards in ascending order and filter out invalid ones
  const sortedFlashcards = [...flashcards]
    .filter(card => card && (
      // Supporta sia il nuovo formato che quello vecchio
      (card.question && card.answer) || 
      (card.card_question && card.card_answer)
    ))
    .sort((a, b) => {
      const questionA = a.card_question || a.question;
      const questionB = b.card_question || b.question;
      return questionA.localeCompare(questionB);
    });
  
  // Filter out discarded questions from user
  const updatedQA = sortedFlashcards.filter(qa => qa && qa.feedback?.isHelpful !== false); 
  
  const handleDownload = () => {
    // Prepara i dati delle flashcard in formato CSV
    const csvContent = updatedQA.map(card => {
      const question = card.card_question || card.question;
      const answer = card.card_answer || card.answer;
      // Escape delle virgole e newline per il CSV
      const escapedQuestion = `"${question.replace(/"/g, '""')}"`;
      const escapedAnswer = `"${answer.replace(/"/g, '""')}"`;
      return `${escapedQuestion},${escapedAnswer}`;
    }).join('\n');

    const header = 'Domanda,Risposta\n';
    const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'flashcards.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  /* useEffect(() => {
    if (sortedFlashcards.length > 0) {
      setStepsEnabled(true);
    }
  }, [sortedFlashcards, setStepsEnabled]); */
  
  // show spinner if is loading
  if (isLoading) {
    return (
      <div className="MaterialsFlashcards">
        <div className="MaterialsFlashcards__loading">
          <Spinner size="sm" />
          <p>Caricamento flashcard...</p>
        </div>
      </div>
    );
  }
  
  // return select document if id selected document is null
  if (!idSelectedDocument)
    return <MaterialsFlashcardsNotFound message='Seleziona un documento' />

  // return empty flashcards if flashcards is empty
  if (!isLoading && updatedQA.length === 0)
    return (
      <MaterialsFlashcardsNotFound message={
        isLegacyFormat 
          ? 'Nessuna domanda di ripetizione trovata nel documento selezionato'
          : 'Nessuna flashcard trovata nel documento selezionato'
      } />
    )

  return (
    <div className='MaterialsFlashcards'>
      {/* Stats Section */}
      <div className="MaterialsFlashcards__stats">
        <div className="MaterialsFlashcards__stats-header">
          <div 
            className="MaterialsFlashcards__stats-icon"
            onClick={handleDownload}
            title="Scarica flashcard"
          >
            <HiDownload size={24} />
          </div>
          <div className="MaterialsFlashcards__stats-info">
            <div className="MaterialsFlashcards__stats-details">
              <div className="MaterialsFlashcards__stats-item">
                {updatedQA.length} flashcard disponibili
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='MaterialsFlashcards__container row gy-xs-1 g-md-1 g-lg-3'>
        {updatedQA.map((flashcard, index) => (
          <Flashcard 
            key={index} 
            question={flashcard.card_question || flashcard.question} 
            answer={flashcard.card_answer || flashcard.answer}
            tags={flashcard.tags}
          />
        ))}
      </div>
    </div>
  )
}

// MaterialFlashcards propTypes
MaterialFlashcards.propTypes = {
  idSelectedDocument: PropTypes.string,
  isLegacyFormat: PropTypes.bool
}

export default MaterialFlashcards