import React from "react";
import Logo from "@ui/logo/logo";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Socials from "./socials/socials";
import AnimatedBackground from "@components/AnimatedBackground";

/* styles */
import "./footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determina se mostrare l'AnimatedBackground
  const shouldShowAnimatedBackground = location.pathname === '/' || 
                                     location.pathname === '/chi-siamo' || 
                                     location.pathname === '/pricing' ||
                                     location.pathname === '/prezzi' ||
                                     location.pathname.includes('pric') ||
                                     location.pathname.includes('prezz') ||
                                     location.pathname.includes('faculty');

  const getSectionOffset = (sectionId) => {
    // Offset personalizzati per sezione
    const offsets = {
      'functionality': 150,      // Funzionalità richiede più spazio sopra
      'beta-testimonial-slider': 80,  // Recensioni va bene con offset standard
      'video-section': 20,      // Tutorial richiede più spazio
      'price': 10,             // Piani e prezzi richiede più spazio
      'faq': 5,               // FAQ richiede più spazio
      'studierai-stats': 30    // Statistiche richiede più spazio
    };
    return offsets[sectionId] || 80; // Default a 80 se non specificato
  };

  const scrollToSection = (element, sectionId) => {
    const headerOffset = getSectionOffset(sectionId);
    const padding = window.innerHeight * 0.12; // Ridotto a 12% per un centraggio più preciso
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    // Calcola l'altezza della sezione target
    const sectionHeight = element.offsetHeight;
    // Calcola l'altezza disponibile della viewport (meno header e padding)
    const availableHeight = window.innerHeight - headerOffset - (padding * 2);

    // Se la sezione è più alta della viewport disponibile, scorri in modo che sia visibile dall'inizio
    if (sectionHeight > availableHeight) {
      window.scrollTo({
        top: offsetPosition - padding,
        behavior: "smooth"
      });
    } else {
      // Se la sezione è più piccola della viewport, centrala verticalmente
      const centerOffset = (availableHeight - sectionHeight) / 2;
      window.scrollTo({
        top: offsetPosition - centerOffset - (padding / 2), // Aggiunto offset extra per centraggio
        behavior: "smooth"
      });
    }
  };

  const handleChiSiamoClick = (event) => {
    event.preventDefault();
    navigate("/chi-siamo");
    const delay = window.innerWidth <= 768 ? 500 : 100;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, delay);
  };

  const handleHomeClick = (event, sectionId) => {
    event.preventDefault();
    if (location.pathname !== '/') {
      navigate('/');
      // Aumentato il timeout per dispositivi mobili
      const delay = window.innerWidth <= 768 ? 500 : 100;
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          scrollToSection(element, sectionId);
        }
      }, delay);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        scrollToSection(element, sectionId);
      }
    }
  };

  const handleUniversityClick = (event) => {
    event.preventDefault();
    navigate("/faculty");
    const delay = window.innerWidth <= 768 ? 500 : 100;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, delay);
  };

  const handleSchoolsClick = (event) => {
    event.preventDefault();
    navigate("/faculty/scuole");
    const delay = window.innerWidth <= 768 ? 500 : 100;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, delay);
  };

  const handlePartnerClick = (event) => {
    event.preventDefault();
    navigate("/faculty/partner");
    const delay = window.innerWidth <= 768 ? 500 : 100;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, delay);
  };

  const handlePricingClick = (event, sectionId) => {
    event.preventDefault();
    if (location.pathname !== '/piani-e-prezzi') {
      navigate('/piani-e-prezzi');
      const delay = window.innerWidth <= 768 ? 500 : 100;
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          scrollToSection(element, sectionId);
        }
      }, delay);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        scrollToSection(element, sectionId);
      }
    }
  };

  const footerSections = {
    home: {
      title: "Home",
      links: [
        { 
          label: "Funzionalità", 
          to: "/#functionality",
          onClick: (e) => handleHomeClick(e, 'functionality')
        },
        { 
          label: "Tutorial", 
          to: "/#video-section",
          onClick: (e) => handleHomeClick(e, 'video-section')
        },
        { 
          label: "FAQ", 
          to: "/#faq",
          onClick: (e) => handleHomeClick(e, 'faq')
        },
        { 
          label: "Statistiche", 
          to: "/#studierai-stats",
          onClick: (e) => handleHomeClick(e, 'studierai-stats')
        }
      ]
    },
    abbonamenti: {
      title: "Abbonamenti",
      links: [
        { 
          label: "Piani e Prezzi", 
          to: "/piani-e-prezzi#price",
          onClick: (e) => handlePricingClick(e, 'price')
        },
        { 
          label: "Recensioni", 
          to: "/piani-e-prezzi#beta-testimonial-slider",
          onClick: (e) => handlePricingClick(e, 'beta-testimonial-slider')
        },
        { 
          label: "FAQ Prezzi", 
          to: "/piani-e-prezzi#pricing-faq",
          onClick: (e) => handlePricingClick(e, 'pricing-faq')
        }
      ]
    },
    perChi: {
      title: "Studenti",
      links: [
        { 
          label: "Università", 
          to: "/faculty",
          onClick: handleUniversityClick 
        },
        { 
          label: "Scuole", 
          to: "/faculty/scuole",
          onClick: handleSchoolsClick 
        },
        { 
          label: "Diventa Partner", 
          to: "/faculty/partner",
          onClick: handlePartnerClick 
        }
      ]
    },
    startup: {
      title: "Startup",
      links: [
        { 
          label: "Chi Siamo", 
          to: "/chi-siamo",
          onClick: handleChiSiamoClick 
        },
        { label: "Contattaci", to: "/assistenza" },
        { 
          label: "Privacy Policy", 
          to: "https://www.iubenda.com/privacy-policy/73904869",
          external: true 
        },
        { 
          label: "Cookie Policy", 
          to: "https://www.iubenda.com/privacy-policy/73904869/cookie-policy",
          external: true 
        }
      ]
    }
  };

  const renderFooterSection = (section, key) => (
    <div key={key} className="Footer__section">
      <h3 className="Footer__section-title">{section.title}</h3>
      <ul className="Footer__section-links">
        {section.links.map((link, index) => (
          <li key={index}>
            {link.external ? (
              <a
                href={link.to}
                className="Footer__section-link"
                title={link.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.label}
              </a>
            ) : link.onClick ? (
              <a
                href={link.to}
                className="Footer__section-link"
                onClick={link.onClick}
              >
                {link.label}
              </a>
            ) : (
              <Link 
                to={link.to} 
                className="Footer__section-link"
              >
                {link.label}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="Footer">
      {shouldShowAnimatedBackground && <AnimatedBackground />}
      <div className="container">
        <div className="Footer__content">
          <div className="Footer__main">
            <div className="Footer__brand">
              <div className="Footer__logo-wrapper">
                <Logo />
              </div>
              <p className="Footer__description">
                StudierAI - Assistente AI per la ripetizione e il ripasso universitario e liceale
              </p>
              <div className="Footer__socials-desktop">
                <Socials />
              </div>
            </div>
            <div className="Footer__sections">
              {Object.entries(footerSections).map(([key, section]) => 
                renderFooterSection(section, key)
              )}
            </div>
          </div>
          <div className="Footer__divider" />
          <div className="Footer__bottom">
            <div className="Footer__socials-mobile">
              <Socials />
            </div>
            <div className="Footer__copyright">
              <p>© 2023 StudierAI S.r.l. Startup Innovativa</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
